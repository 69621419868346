import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { ApiUrlService } from './api-url.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api-url.service";
var EquipmentService = /** @class */ (function () {
    function EquipmentService(http, apiUrl) {
        this.http = http;
        this.apiUrl = apiUrl;
        this.loadEquipments = new ReplaySubject(0);
        this.firstLoad = false;
    }
    EquipmentService.prototype.initEquipmentsAndRules = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, equipments, rules;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.firstLoad)
                            return [2 /*return*/];
                        this.firstLoad = true;
                        return [4 /*yield*/, this.http.get(this.apiUrl.baseUrl() + 'equipments-rules').toPromise()];
                    case 1:
                        _a = _b.sent(), equipments = _a.equipments, rules = _a.rules;
                        this.establishmentEquipments = equipments.establishments;
                        this.typologyEquipments = equipments.typologies;
                        this.establishmentRules = rules.establishments;
                        this.typologyRules = rules.typologies;
                        this.loadEquipments.next({
                            establishmentEquipments: this.establishmentEquipments,
                            typologyEquipments: this.typologyEquipments,
                            establishmentRules: this.establishmentRules,
                            typologyRules: this.typologyRules
                        });
                        this.loadEquipments.complete();
                        return [2 /*return*/];
                }
            });
        });
    };
    EquipmentService.ngInjectableDef = i0.defineInjectable({ factory: function EquipmentService_Factory() { return new EquipmentService(i0.inject(i1.HttpClient), i0.inject(i2.ApiUrlService)); }, token: EquipmentService, providedIn: "root" });
    return EquipmentService;
}());
export { EquipmentService };
