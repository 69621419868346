import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/home/home/home.service';
import { ApiUrlService } from 'src/app/shared/services/api-url.service';
import { SiteService } from 'src/app/shared/services/site.service';

@Component({
  selector: 'app-home-1',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss']
})
export class Home1Component implements OnInit {
  public homeStyle: any = {};

  constructor(public home: HomeService, private site: SiteService, private apiUrl: ApiUrlService) {
    if (this.site.settings.background) {
      this.homeStyle['background-image'] = 'url(' + this.apiUrl.getSettingsImageLink(this.site.settings.background) + ')';
    }
  }

  ngOnInit() {
  }
}
