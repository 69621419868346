<div class="row pb-3" *ngIf="details">
    <div [ngClass]="{'col-12 col-sm-6 col-md-3': !recap, 'col-12': recap}" *ngFor="let rule of rules">
        <div class="our-rooms-icons">
            <div class="our-rooms-icon">
                <div class="equipments-item" [ngClass]="getClassForEquipment(rule)">
                    <app-equipment-icon [icon]="rule.icon"></app-equipment-icon>
                    <span class="not-allowed" *ngIf="!ruleEnabled(rule.slug)"><i class="fal fa-ban"></i></span>
                </div>
                <div class="equipments-title">
                    <div class="our-rooms-icon-txt1">{{ getRuleText(rule) }}</div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="!details" class="equipments-list">
    <div *ngFor="let rule of rules" [ngClass]="getClassForEquipment(rule)">
        <div class="equipments-item" [ngbTooltip]="getRuleText(rule)">
            <div>
                <app-equipment-icon [icon]="rule.icon"></app-equipment-icon>
                <span class="not-allowed" *ngIf="!ruleEnabled(rule.slug)"><i class="fal fa-ban"></i></span>
            </div>
        </div>
    </div>
</div>