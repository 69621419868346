import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {
  constructor() {

  }

  hasError(control: AbstractControl, submitted: boolean) {
    return !(control.valid || (!submitted && (control.pristine || control.untouched)));
  }
}
