<div id="content">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <app-profile-navigation-1></app-profile-navigation-1>
            </div>
            <div class="col-md-9">
                <div class="card">
                    <div class="card-header">
                        {{ 'user.PASSWORD' | translate }}
                    </div>
                    <div class="card-body">
                        <app-alert></app-alert>
                        <form class="form-horizontal" [formGroup]="this.passwordForm" (ngSubmit)="this.changePassword()">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label" for="oldPassword">{{ 'user.CURRENT_PASSWORD' | translate }}</label>
                                <div class="col-sm-9 col-md-6">
                                    <input type="password" class="form-control" id="oldPassword" [placeholder]="'user.CURRENT_PASSWORD_TEXT' | translate" formControlName="oldPassword" [class.is-invalid]="!(this.passwordForm.controls.oldPassword.valid || (this.passwordForm.controls.oldPassword.pristine && !submitted)) || (profileService.passwordError && this.passwordForm.controls.oldPassword.pristine)">
                                    <small [hidden]="(this.passwordForm.controls.oldPassword.valid && !this.passwordForm.controls.oldPassword.pristine) || (this.passwordForm.controls.oldPassword.valid && ! profileService.passwordError) || (this.passwordForm.controls.oldPassword.pristine && !profileService.passwordError && !submitted)">
                                        <span *ngIf="(profileService.passwordError && this.passwordForm.controls.oldPassword.pristine)">{{ 'user.INVALID_PASSWORD' | translate }}</span>
                                        <span *ngIf="!(profileService.passwordError && this.passwordForm.controls.oldPassword.pristine)">{{ 'user.CURRENT_PASSWORD_REQUIRED' | translate }}</span>
                                    </small>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label" for="newPassword">{{ 'user.PASSWORD' | translate }}</label>
                                <div class="col-sm-9 col-md-6">
                                    <input type="password" class="form-control" id="newPassword" [placeholder]="'user.NEW_PASSWORD_TEXT' | translate" formControlName="newPassword" [class.is-invalid]="!(this.passwordForm.controls.newPassword.valid || (this.passwordForm.controls.newPassword.pristine && !submitted))">
                                    <small [hidden]="this.passwordForm.controls.newPassword.valid || (this.passwordForm.controls.newPassword.pristine && !submitted)">
                                        {{ 'user.NEW_PASSWORD_REQUIRED' | translate }}
                                    </small>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label" for="newPassword2">{{ 'user.PASSWORD_CONFIRMATION' | translate }}</label>
                                <div class="col-sm-9 col-md-6">
                                    <input type="password" class="form-control" id="newPassword2" [placeholder]="'user.PASSWORD_CONFIRMATION_TEXT' | translate" formControlName="newPassword2" [class.is-invalid]="!((this.passwordForm.controls.newPassword2.value == this.passwordForm.controls.newPassword.value) || (this.passwordForm.controls.newPassword2.pristine && !submitted))">
                                    <small [hidden]="(this.passwordForm.controls.newPassword2.value == this.passwordForm.controls.newPassword.value) || (this.passwordForm.controls.newPassword2.pristine && !submitted)">
                                        {{ 'user.PASSWORD_CONFIRMATION_REQUIRED' | translate }}
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col-sm-9">
                                    <button class="btn btn-primary" type="submit" [disabled]="profileService.loading"><i class="far fa-spin fa-circle-notch" *ngIf="profileService.loading"></i> {{ 'user.CONFIRM_PASSWORD_CHANGE' | translate }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<template1-footer></template1-footer>