/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./base-layout.component";
import * as i2 from "../../services/site.service";
var styles_BaseLayoutComponent = [];
var RenderType_BaseLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BaseLayoutComponent, data: {} });
export { RenderType_BaseLayoutComponent as RenderType_BaseLayoutComponent };
function View_BaseLayoutComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_BaseLayoutComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { content: 0 }), (_l()(), i0.ɵand(16777216, [[1, 3], ["content", 2]], null, 0, null, View_BaseLayoutComponent_1))], null, null); }
export function View_BaseLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "base-layout", [], null, null, null, View_BaseLayoutComponent_0, RenderType_BaseLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i1.BaseLayoutComponent, [i0.ComponentFactoryResolver, i2.SiteService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BaseLayoutComponentNgFactory = i0.ɵccf("base-layout", i1.BaseLayoutComponent, View_BaseLayoutComponent_Host_0, {}, {}, []);
export { BaseLayoutComponentNgFactory as BaseLayoutComponentNgFactory };
