<div id="content">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title1">{{ 'titles.SEARCH' | translate }}</div>
                <div class="title2">
                    {{ 'search.ESTABLISHMENT_RESULTS' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div class="booking2-wrapper">
        <app-search-1></app-search-1>
    </div>
    
    <div *ngIf="searchService.searchLoading">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center loading">
                    <div><i class="far fa-circle-notch fa-spin"></i></div>
                    <div class="loading-text">
                        {{ 'common.LOADING' | translate }}...
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!searchService.searchLoading">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div *ngIf="results !== null && results.length === 0" class="text-center">
                        {{ 'search.NO_ESTABLISHMENT_FOUND' | translate }}
                    </div>
                    <div class="room-wide-wrapper" *ngFor="let result of results">
                        <div class="room-wide-inner">
                            <div class="room-wide clearfix">
                                <figure class="establishment-image">
                                    <img [src]="result.photo + '?thumbnail=true'" alt="" class="img-fluid">
                                </figure>
                                <div class="caption">
                                    <div class="room-wide-columns clearfix">
                                        <div class="room-wide-left">
                                            <div class="txt2">{{ result.name }}</div>
                                        </div>
                                        <div class="room-wide-right">
                                            <div class="room-wide-price">
                                                <div class="txt3">{{ result.minimumPrice | customCurrency }}</div>
                                                <div class="txt4">{{ searchService.nbGuests }} {{ ((searchService.nbGuests > 1) ? 'reservations.TRAVELLERS' : 'reservations.TRAVELLER') | translate }} - {{ searchService.nbNights }} {{ ((searchService.nbNights > 1) ? 'reservations.NIGHTS' : 'reservations.NIGHT') | translate }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="txt5">
                                            {{ result.description | truncate:[400] }}
                                    </div>
                                    <div class="d-flex">
                                        <div style="flex-grow: 3;">
                                            <app-equipments type="establishment" [selectedEquipments]="result.equipments" [details]="false"></app-equipments>
                                        </div>
                                        <div class="flex-grow-1">
                                            <button class="btn btn-primary w-100" [routerLink]="'/establishments/details/' + result.id">{{ 'book.BOOK' | translate }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="results !== null && results.length > 0">

                        <ngb-pagination [collectionSize]="searchService.totalItems" [(page)]="searchService.currentPage" (pageChange)="searchService.search()" [pageSize]="searchService.nbPerPage" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                            <ng-template ngbPaginationFirst><i class="far fa-angle-double-left"></i></ng-template>
                            <ng-template ngbPaginationPrevious><i class="far fa-angle-left"></i></ng-template>
                            <ng-template ngbPaginationNext><i class="far fa-angle-right"></i></ng-template>
                            <ng-template ngbPaginationLast><i class="far fa-angle-double-right"></i></ng-template>
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<template1-footer></template1-footer>