import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationService } from 'src/app/user/reservation/reservation.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../services/alert.service';
import { AlertType } from '../../models/alert-type.model';
import { LanguageService } from '../../services/language.service';
var CancellationModalComponent = /** @class */ (function () {
    function CancellationModalComponent(activeModal, reservationService, alert, translate, language) {
        this.activeModal = activeModal;
        this.reservationService = reservationService;
        this.alert = alert;
        this.translate = translate;
        this.language = language;
        this.policies = {};
        this.loading = false;
        this.cancellationLoading = false;
    }
    CancellationModalComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.loading = true;
                this.reservationService.getReservationPolicy(this.reservationCode)
                    .then(function (policies) {
                    _this.policies = policies;
                })
                    .finally(function () {
                    _this.loading = false;
                });
                return [2 /*return*/];
            });
        });
    };
    CancellationModalComponent.prototype.cancelReservation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.cancellationLoading = true;
                this.reservationService.cancelReservation(this.reservationCode)
                    .then(function () {
                    var establishmentName = _this.language.getTranslationFromMap(_this.reservationService.reservation.establishment.name);
                    _this.alert.set(AlertType.SUCCESS, _this.translate.instant('book.RESERVATION_CANCELLED_GUEST', {
                        establishmentName: establishmentName
                    }));
                    _this.activeModal.close();
                })
                    .catch(function () { return _this.alert.set(AlertType.DANGER, _this.translate.instant('book.ERROR_CANCELLATION')); })
                    .finally(function () {
                    _this.cancellationLoading = false;
                });
                return [2 /*return*/];
            });
        });
    };
    return CancellationModalComponent;
}());
export { CancellationModalComponent };
