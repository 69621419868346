import * as i0 from "@angular/core";
var FormValidationService = /** @class */ (function () {
    function FormValidationService() {
    }
    FormValidationService.prototype.hasError = function (control, submitted) {
        return !(control.valid || (!submitted && (control.pristine || control.untouched)));
    };
    FormValidationService.ngInjectableDef = i0.defineInjectable({ factory: function FormValidationService_Factory() { return new FormValidationService(); }, token: FormValidationService, providedIn: "root" });
    return FormValidationService;
}());
export { FormValidationService };
