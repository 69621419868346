import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { NavbarService } from './navbar.service';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  private _currentRoute: any = null;

  constructor(private router: Router, private route: ActivatedRoute, private navbar: NavbarService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    )
      .subscribe(data => {
        this._currentRoute = {
          name: data.name || ''
        };
        this.navbar.navbarOpen = false;
      });
  }

  get currentRoute(): any {
    return this._currentRoute;
  }
}
