import { Component } from '@angular/core';
import { ProfileService } from 'src/app/user/profile/profile.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-password-1',
  templateUrl: 'password.component.html',
  styleUrls: ['password.component.scss']
})
export class Password1Component {
  passwordForm: FormGroup;

  constructor(public profileService: ProfileService) {
    this.profileService.initPasswordForm();
    this.passwordForm = this.profileService.passwordForm;
  }

  get submitted() {
    return this.profileService.passwordFormSubmitted;
  }

  changePassword() {
    this.profileService.changePassword();
  }
}
