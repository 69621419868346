import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { MainLayout1Component } from '../templates/template1/layout/main-layout.component';
import { Home1Component } from '../templates/template1/home/home.component';
import { EstablishmentsList1Component } from '../templates/template1/establishments/establishments-list/establishments-list.component';
import { EstablishmentDetails1Component } from '../templates/template1/establishments/establishment-details/establishment-details.component';
import { Login1Component } from '../templates/template1/auth/login/login.component';
import { Register1Component } from '../templates/template1/auth/register/register.component';
import { ReservationsBook1Component } from '../templates/template1/reservations/reservations-book/reservations-book.component';
import { Profile1Component } from '../templates/template1/user/profile/profile.component';
import { Reservations1Component } from '../templates/template1/user/reservations/reservations.component';
import { UserReservation1Component } from '../templates/template1/user/reservation/reservation.component';
import { Messages1Component } from '../templates/template1/user/messages/messages.component';
import { Password1Component } from '../templates/template1/user/password/password.component';
import { ApiUrlService } from './api-url.service';
import { ResetPassword1Component } from '../templates/template1/auth/reset-password/reset-password.component';
import { Pages1Component } from '../templates/template1/pages/pages/pages.component';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  public currentTemplate: number = 1;

  public components: any = {
    MainLayout1Component,
    Home1Component,
    EstablishmentsList1Component,
    EstablishmentDetails1Component,
    Login1Component,
    Register1Component,
    ReservationsBook1Component,
    Profile1Component,
    Reservations1Component,
    UserReservation1Component,
    Messages1Component,
    Password1Component,
    Pages1Component,
    ResetPassword1Component
  };

  constructor(private apiUrl: ApiUrlService) {
  }

  getComponent(componentName: string) {
    return this.components[componentName];
  }

  applySiteSettings(settings: any) {
    if (settings.template) {
      // Set the current template
      this.currentTemplate = parseInt(settings.template);
    }
    if (settings.mainColor) {
      // Set the main color
      document.documentElement.style.setProperty('--main-color', settings.mainColor);
    }
    if (settings.mainDarkColor) {
      // Set the main dark color
      document.documentElement.style.setProperty('--main-dark-color', settings.mainDarkColor);
    }
    if (settings.mainDarkerColor) {
      // Set the main darker color
      document.documentElement.style.setProperty('--main-darker-color', settings.mainDarkerColor);
    }
    // if (settings.favicon) {
    //   // Set the favicon
    //   document.getElementById('favicon').setAttribute('href', this.apiUrl.siteBaseUrl(true) + 'images/' + settings.favicon);
    // }
  }
}
