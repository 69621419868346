import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { SearchService } from 'src/app/shared/services/search.service';
import { SiteService } from 'src/app/shared/services/site.service';
var Search1Component = /** @class */ (function () {
    function Search1Component(router, site, searchService, activatedRoute) {
        this.router = router;
        this.site = site;
        this.searchService = searchService;
        this.activatedRoute = activatedRoute;
        this.selectOptions = {
            minimumResultsForSearch: 10
        };
        this.roomName = 'room';
        this.rooms = [1, 2, 3, 4, 5, 6];
        this.dateRange = {
            start: null,
            end: null
        };
    }
    Search1Component.prototype.ngOnInit = function () {
        var _this = this;
        if (this.site.settings.accommodationType) {
            this.roomName = this.site.settings.accommodationType;
        }
        this.activatedRoute.queryParams.subscribe(function (params) {
            var _a, _b;
            var triggerSearch = false;
            if (params.startDate
                && params.endDate) {
                try {
                    var startDate = {
                        year: null,
                        month: null,
                        day: null
                    };
                    _a = params.startDate.split('-'), startDate.year = _a[0], startDate.month = _a[1], startDate.day = _a[2];
                    _this.searchService.datePickerOptions.startDate = startDate;
                    _this.startDateChanged(_this.searchService.datePickerOptions.startDate);
                    var endDate = {
                        year: null,
                        month: null,
                        day: null
                    };
                    _b = params.endDate.split('-'), endDate.year = _b[0], endDate.month = _b[1], endDate.day = _b[2];
                    _this.searchService.datePickerOptions.endDate = endDate;
                    _this.endDateChanged(_this.searchService.datePickerOptions.endDate);
                    triggerSearch = true;
                }
                catch (e) {
                    console.log(e);
                }
            }
            if (params.adultCount) {
                _this.searchService.criteria.nbAdults = params.adultCount;
                triggerSearch = true;
            }
            if (params.childCount) {
                _this.searchService.criteria.nbChildren = params.childCount;
                triggerSearch = true;
            }
            if (params.infantCount) {
                _this.searchService.criteria.nbInfants = params.infantCount;
                triggerSearch = true;
            }
            if (params.accommodationUnitCount) {
                _this.searchService.criteria.nbRooms = params.accommodationUnitCount;
                triggerSearch = true;
            }
            if (triggerSearch) {
                _this.search();
            }
        });
    };
    Search1Component.prototype.startDateChanged = function (date) {
        this.searchService.criteria.arrivalDate = moment([date.year, date.month - 1, date.day]);
        var minEnd = moment(this.searchService.criteria.arrivalDate).add(1, 'day');
        if (this.searchService.criteria.arrivalDate.isAfter(this.searchService.criteria.departureDate)) {
            var newEnd = moment(this.searchService.criteria.arrivalDate).add(1, 'day');
            this.searchService.criteria.departureDate = newEnd;
            this.searchService.datePickerOptions.endDate = { year: newEnd.year(), month: newEnd.month() + 1, day: newEnd.date() };
        }
        this.searchService.datePickerOptions.minEndDate = { year: minEnd.year(), month: minEnd.month() + 1, day: minEnd.date() };
    };
    Search1Component.prototype.endDateChanged = function (date) {
        this.searchService.criteria.departureDate = moment([date.year, date.month - 1, date.day]);
    };
    Search1Component.prototype.openDatePicker = function () {
        // Open the datepicker
        document.getElementById('inputDateRangePicker').click();
        // Select the end input
        document.getElementsByName('daterangepicker_end')[0].focus();
    };
    Search1Component.prototype.search = function () {
        this.searchService.currentPage = 1;
        this.searchService.saveCriteria();
        this.searchService.search();
        this.router.navigate(['/search']);
    };
    return Search1Component;
}());
export { Search1Component };
