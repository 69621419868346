import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AlertType } from 'src/app/shared/models/alert-type.model';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../../../auth/login/login.service';

@Component({
  selector: 'app-login-1',
  templateUrl: 'login.component.html'
})
export class Login1Component implements OnInit {
  loginForm: FormGroup;

  submitted = false;

  loading = false;

  constructor(private loginService: LoginService,
    private fb: FormBuilder,
    private router: Router,
    private alert: AlertService,
    private translate: TranslateService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  ngOnInit() {

  }

  signin() {
    this.submitted = true;
    this.alert.clear();

    if (!this.loginForm.valid) return;

    this.loading = true;

    const credentials = {
      email: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value
    };
    this.submitted = true;
    this.loginService.signin(credentials)
      .then(() => {

      })
      .catch(() => {
        this.alert.set(AlertType.DANGER, this.translate.instant('user.INVALID_CREDENTIALS'));
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
