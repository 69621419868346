<div id="content">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <app-profile-navigation-1></app-profile-navigation-1>
            </div>
            <div class="col-md-9">
                <div class="card">
                    <div class="card-header">
                        {{ 'user.ABOUT_YOU' | translate }}
                    </div>
                    <div class="card-body">
                        <app-alert></app-alert>
                        <form class="form-horizontal" [formGroup]="this.profileForm" (ngSubmit)="this.saveProfile()">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label" for="firstName">{{ 'user.PROFILE_PICTURE' | translate }}</label>
                                <div class="col-sm-9 d-flex align-items-center">
                                    <div>
                                        <img [src]="profileService.pictureProfile" alt="" class="profile-picture">
                                    </div>
                                    <div class="col">
                                        <button class="btn btn-primary btn-file"
                                        [disabled]="profileService.currentPictureUploading?.pending">
                                            <i class="far fa-camera"></i> {{ 'user.CHANGE_PHOTO' | translate }}
                                            <input
                                                class="form-control-file"
                                                id="photo"
                                                #logoInput
                                                type="file"
                                                accept="image/png,image/jpg,image/jpeg"
                                                (change)="profileService.processFile(logoInput)">
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label" for="firstName">{{ 'user.FIRST_NAME' | translate }}</label>
                                <div class="col-sm-9 col-md-6">
                                    <input class="form-control" id="firstName" [placeholder]="'user.YOUR_FIRST_NAME' | translate" formControlName="firstName" [class.is-invalid]="validation.hasError(this.profileForm.controls.firstName, submitted)">
                                    <small [hidden]="!validation.hasError(this.profileForm.controls.firstName, submitted)">
                                        {{ 'user.FIRST_NAME_REQUIRED' | translate }}
                                    </small>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label" for="lastName">Nom</label>
                                <div class="col-sm-9 col-md-6">
                                    <input class="form-control" id="lastName" [placeholder]="'user.YOUR_LAST_NAME' | translate" formControlName="lastName" [class.is-invalid]="validation.hasError(this.profileForm.controls.lastName, submitted)">
                                    <small [hidden]="!validation.hasError(this.profileForm.controls.lastName, submitted)">
                                        {{ 'user.LAST_NAME_REQUIRED' | translate }}
                                    </small>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label" for="email">{{ 'user.EMAIL_ADDRESS' | translate }}</label>
                                <div class="col-sm-9 col-md-6">
                                    <input class="form-control" id="email" [placeholder]="'user.YOUR_EMAIL_ADDRESS' | translate" formControlName="email" [class.is-invalid]="validation.hasError(this.profileForm.controls.email, submitted) || profileService.emailAlreadyUsedError" (ngModelChange)="profileService.emailAlreadyUsedError = false">
                                    <small [hidden]="!(validation.hasError(this.profileForm.controls.email, submitted))">
                                        {{ 'user.EMAIL_INVALID' | translate }}
                                    </small>
                                    <small [hidden]="!profileService.emailAlreadyUsedError">
                                        {{ 'user.EMAIL_ADDRESS_TAKEN' | translate }}
                                    </small>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label" for="phone">{{ 'user.PHONE_NUMBER' | translate }}</label>
                                <div class="col-sm-9 col-md-6">
                                    <input type="tel" class="form-control" formControlName="phone" id="phone" name="phone"
                                    [placeholder]="'user.YOUR_PHONE_NUMBER' | translate" [class.is-invalid]="validation.hasError(this.profileForm.controls.phone, submitted) || profileService.hasPhoneError"
                                    ng2TelInput
                                    [ng2TelInputOptions]=""
                                    (ng2TelOutput)="profileService.getNumber($event)"
                                    (hasError)="profileService.onPhoneChanged($event)"
                                    required>
                                    <small [hidden]="!validation.hasError(this.profileForm.controls.phone, submitted) && !profileService.hasPhoneError">
                                      {{ 'user.PHONE_NUMBER_INVALID' | translate }}
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col-sm-9">
                                    <button class="btn btn-primary" type="submit" [disabled]="profileService.loading"><i class="far fa-spin fa-circle-notch" *ngIf="profileService.loading"></i> {{ 'common.SAVE' | translate }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<template1-footer></template1-footer>