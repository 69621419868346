import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ListingsApiService } from 'src/app/establishments/establishments-api.service';
import { Criteria } from '../models/criteria.model';
import { ApiUrlService } from './api-url.service';
import { LanguageService } from './language.service';
import { SessionStoreService } from './session-store.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public criteria: Criteria;

  public typologiesWithPrices: BehaviorSubject<any> = new BehaviorSubject(null);

  public searchLoading = false;

  public searchResults: BehaviorSubject<any[]> = new BehaviorSubject(null);

  public currentPage: number = 1;

  public nbPerPage: number = 10;

  public totalItems: number = 1;

  public totalPages: number = 1;

  public nbGuests: number = 0;

  public nbNights: number = 0;

  public datePickerOptions: any = {
    startDate: null,
    endDate: null,
    minStartDate: null,
    minEndDate: null
  };

  constructor(private http: HttpClient,
    private apiUrl: ApiUrlService,
    private translate: LanguageService,
    private router: Router,
    private listingsApi: ListingsApiService,
    private session: SessionStoreService) {
    this.loadCriteria();
  }

  loadCriteria() {
    this.criteria = this.session.getItem('search-criteria');
    if (!this.criteria) {
      this.initCriteria();
    }

    const today = moment();
    const start = moment(this.criteria.arrivalDate);
    const end = moment(this.criteria.departureDate);
    this.datePickerOptions.startDate = { year: start.year(), month: start.month() + 1, day: start.date() };
    this.datePickerOptions.endDate = { year: end.year(), month: end.month() + 1, day: end.date() };

    this.datePickerOptions.minStartDate = { year: today.year(), month: today.month() + 1, day: today.date() };

    const minEnd = moment(start).add(1, 'day');
    this.datePickerOptions.minEndDate = { year: minEnd.year(), month: minEnd.month() + 1, day: minEnd.date() };

    this.nbGuests = +this.criteria.nbAdults + +this.criteria.nbChildren;
    this.nbNights = moment(this.criteria.departureDate).diff(this.criteria.arrivalDate, 'days');
  }

  initCriteria() {
    this.criteria = {
      arrivalDate: moment().startOf('day'),
      departureDate: moment().startOf('day').add(1, 'day'),
      nbAdults: 2,
      nbChildren: 0,
      nbInfants: 0,
      nbRooms: 1
    };
  }

  selectedDate(value: any) {
    this.criteria.arrivalDate = value.start;
    this.criteria.departureDate = value.end;
  }

  saveCriteria() {
    this.session.clear();
    const criteria: Criteria = { ...this.criteria };
    criteria.arrivalDate = moment(this.criteria.arrivalDate).startOf('day').format('YYYY-MM-DD');
    criteria.departureDate = moment(this.criteria.departureDate).startOf('day').format('YYYY-MM-DD');

    this.session.setItem('search-criteria', criteria);
  }

  search() {
    // Load criteria from storage
    this.loadCriteria();

    const criteria: any = { ...this.criteria };
    criteria.page = this.currentPage;
    criteria.nbPerPage = this.nbPerPage;

    this.searchLoading = true;
    return this.http.post<any>(this.apiUrl.siteBaseUrl() + 'search', criteria)
      .toPromise()
      .then(result => {
        result.results.forEach(async item => {
          item.photo = this.listingsApi.getLinkPhoto(item.photo);
          item.equipments = (await this.listingsApi.separateEquipmentsAndRules(item.equipments)).equipments;
        });
        this.nbGuests = result.nbGuests;
        this.nbNights = result.nbNights;
        this.searchResults.next(result.results);

        // Update pagination
        this.totalPages = result.pages.totalPages;
        this.totalItems = result.pages.totalItems;

        this.searchLoading = false;

        return true;
      });
  }

  /**
     * Get the establishment prices for the current criteria
     * @param establishmentId
     */
  getEstablishmentPrices(establishmentId: number) {
    return this.http.post(this.apiUrl.siteBaseUrl() + 'search/' + establishmentId, this.criteria)
      .toPromise().then((data: any[]) => {
        this.typologiesWithPrices.next(data);
        return true;
      });
  }
}
