import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { RouteService } from 'src/app/shared/services/route.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { ListingsApiService } from '../establishments-api.service';
import { SessionStoreService } from '../../shared/services/session-store.service';
import { AuthService } from '../../shared/services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../establishments-api.service";
import * as i2 from "../../shared/services/session-store.service";
import * as i3 from "../../shared/services/auth.service";
import * as i4 from "../../shared/services/route.service";
import * as i5 from "@angular/router";
import * as i6 from "../../shared/services/language.service";
var EstablishmentDetailsService = /** @class */ (function () {
    function EstablishmentDetailsService(listingsApi, sessionStorage, auth, routeService, router, language) {
        this.listingsApi = listingsApi;
        this.sessionStorage = sessionStorage;
        this.auth = auth;
        this.routeService = routeService;
        this.router = router;
        this.language = language;
        this.establishment = new BehaviorSubject({});
        this.typologies = new BehaviorSubject([]);
        this.selectedEquipments = new BehaviorSubject([]);
        this.selectedRules = new BehaviorSubject([]);
        this.photos = new BehaviorSubject([]);
        this.typologiesWithPrice = null;
        this.reservationInformation = {};
        this.loading = true;
        this.initEstablishmentDetails();
    }
    EstablishmentDetailsService.prototype.initEstablishmentDetails = function () {
        this.reservedTypologies = this.sessionStorage.getItem('reservations') || [];
        this.criteria = this.sessionStorage.getItem('search-criteria');
    };
    EstablishmentDetailsService.prototype.getEstablishment = function (establishmentId) {
        var _this = this;
        return this.listingsApi.getEstablishment(establishmentId)
            .toPromise()
            .then(function (establishment) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var equipmentsRules;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.establishment.next(establishment);
                        return [4 /*yield*/, this.listingsApi.separateEquipmentsAndRules(this.establishment.value.equipments)];
                    case 1:
                        equipmentsRules = _a.sent();
                        this.selectedEquipments.next(equipmentsRules.equipments);
                        this.selectedRules.next(equipmentsRules.rules);
                        return [2 /*return*/, true];
                }
            });
        }); });
    };
    EstablishmentDetailsService.prototype.initPhotos = function (idEstablishment, idTypology) {
        var _this = this;
        return this.listingsApi.getPhotos(idEstablishment, idTypology)
            .toPromise()
            .then(function (photos) {
            _this.photos.next(photos);
            return true;
        });
    };
    EstablishmentDetailsService.prototype.getTypologies = function (idEstablishment) {
        var _this = this;
        return this.listingsApi.getTypologies(idEstablishment)
            .toPromise()
            .then(function (typologies) {
            _this.typologies.next(typologies);
            return true;
        });
    };
    EstablishmentDetailsService.prototype.getImageUrl = function (path) {
        return this.listingsApi.getLinkPhoto(path);
    };
    EstablishmentDetailsService.prototype.isReserved = function (typo) {
        this.reservedTypologies.findIndex(function (item) { return item.id === typo.id; }) !== -1;
    };
    EstablishmentDetailsService.prototype.toggleTypology = function (typo, quantity) {
        var index = this.reservedTypologies.findIndex(function (item) { return item.id === typo.id; });
        if (index !== -1) {
            this.reservedTypologies.splice(index, 1);
        }
        else {
            this.reservedTypologies.push({
                id: typo.id, typology: typo, quantity: quantity, typologyWithPrice: this.typologiesWithPrice[typo.id]
            });
        }
        this.saveReservations();
    };
    EstablishmentDetailsService.prototype.getTotalPrices = function (priceList, quantity) {
        if (!priceList)
            return;
        var totalPrice = 0;
        for (var _i = 0, _a = this.reservedTypologies; _i < _a.length; _i++) {
            var typo = _a[_i];
            totalPrice += priceList[typo.id].minPrice * quantity[typo.id];
        }
        return totalPrice;
    };
    EstablishmentDetailsService.prototype.getNumberOfRooms = function (typo) {
        return typo.rooms.filter(function (item) { return !item.isCommonSpace; }).length;
    };
    EstablishmentDetailsService.prototype.getNumberOfCommunSpace = function (typo) {
        return typo.rooms.filter(function (item) { return item.isCommonSpace; }).length;
    };
    EstablishmentDetailsService.prototype.saveReservations = function () {
        sessionStorage.setItem('reservations', JSON.stringify(this.reservedTypologies));
    };
    EstablishmentDetailsService.prototype.transformReservations = function () {
        this.reservationInformation.establishment = this.reservedTypologies[0].typology.EstablishmentId;
        this.reservationInformation.criteria = this.criteria;
        var bookReservation = [];
        for (var _i = 0, _a = this.reservedTypologies; _i < _a.length; _i++) {
            var reservations = _a[_i];
            for (var i = 0; i < reservations.quantity; i++) {
                var book = {};
                var repartition = {};
                book.id = reservations.typology.id;
                book.name = reservations.typology.name;
                book.rateId = 1; // TODO: change the rate
                repartition.nbAdults = 0;
                repartition.nbChildren = 0;
                repartition.nbInfants = 0;
                book.repartition = repartition;
                book.capacity = reservations.typology.capacity;
                bookReservation.push(book);
            }
        }
        this.reservationInformation.bookedReservation = bookReservation;
        sessionStorage.setItem('reservationInformation', JSON.stringify(this.reservationInformation));
    };
    EstablishmentDetailsService.prototype.book = function () {
        this.transformReservations();
        if (this.auth.user) {
            this.router.navigateByUrl('/reservations/book');
        }
        else {
            this.auth.redirectAfterLogin = '/reservations/book';
            this.router.navigateByUrl('/sessions/signin');
        }
    };
    EstablishmentDetailsService.ngInjectableDef = i0.defineInjectable({ factory: function EstablishmentDetailsService_Factory() { return new EstablishmentDetailsService(i0.inject(i1.ListingsApiService), i0.inject(i2.SessionStoreService), i0.inject(i3.AuthService), i0.inject(i4.RouteService), i0.inject(i5.Router), i0.inject(i6.LanguageService)); }, token: EstablishmentDetailsService, providedIn: "root" });
    return EstablishmentDetailsService;
}());
export { EstablishmentDetailsService };
