
<div class="mb-3">
  <label style="margin-bottom: 0;">{{ 'book.card.AMOUNT' | translate }}</label>
  <div class="amount">{{ amount | customCurrency }}</div>
</div>
<form id="stripe-payment-form">
  <div id="stripe-payment-element"></div>
  <br>
  <div>{{ stripeErrorMessage }}</div>
  <button class="pay-button btn btn-primary btn-block" (click)="pay($event)"><i [ngClass]="{'fas fa-check': !paying, 'far fa-spin fa-circle-notch': paying}"></i> {{ 'book.PAY' | translate }}</button>
  <!-- Display error message to your customers here -->
</form>