import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from 'src/app/shared/services/navbar.service';
import { PageService } from 'src/app/shared/services/page.service';
import { RouteService } from 'src/app/shared/services/route.service';
import { ApiUrlService } from '../../../../services/api-url.service';
import { AuthService } from '../../../../services/auth.service';
import { SiteService } from '../../../../services/site.service';

@Component({
  selector: 'template1-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss']
})
export class Navbar1Component {
  public currentRoute: string;

  public navbarOpen: boolean = false;


  constructor(
    private router: Router,
    public routeService: RouteService,
    public auth: AuthService,
    public siteService: SiteService,
    private apiUrlService: ApiUrlService,
    public pageService: PageService,
    public navbarService: NavbarService
  ) {
    this.currentRoute = this.router.url;
  }

  logout() {
    this.auth.signout();
  }

  getLogo() {
    return this.apiUrlService.siteBaseUrl(true) + 'images/' + this.siteService.settings.logo;
  }

  toggleNavbar() {
    this.navbarService.navbarOpen = !this.navbarService.navbarOpen;
  }
}
