import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseLayoutComponent } from './layout/base-layout.component';
import { MainLayout1Component } from '../templates/template1/layout/main-layout.component';
import { TemplateComponent } from './template/template.component';
import { Home1Component } from '../templates/template1/home/home.component';
import { EstablishmentsList1Component } from '../templates/template1/establishments/establishments-list/establishments-list.component';
import { EstablishmentDetails1Component } from '../templates/template1/establishments/establishment-details/establishment-details.component';
import { Login1Component } from '../templates/template1/auth/login/login.component';
import { Register1Component } from '../templates/template1/auth/register/register.component';
import { ReservationsBook1Component } from '../templates/template1/reservations/reservations-book/reservations-book.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { Profile1Component } from '../templates/template1/user/profile/profile.component';
import { AlertComponent } from './alert/alert.component';
import { Reservations1Component } from '../templates/template1/user/reservations/reservations.component';
import { UserReservation1Component } from '../templates/template1/user/reservation/reservation.component';
import { RoomCompositionComponent } from './room-composition/room-composition.component';
import { RoomBedTypesComponent } from './room-bed-types/room-bed-types.component';
import { Messages1Component } from '../templates/template1/user/messages/messages.component';
import { ReservationArrivalDepartureComponent } from './reservation-arrival-departure/reservation-arrival-departure.component';
import { Password1Component } from '../templates/template1/user/password/password.component';
import { PaymentCardComponent } from './payment/payment-card/payment-card.component';
import { StripeComponent } from './payment/stripe/stripe.component';
import { CustomCurrencyPipe } from './pipes/currency.pipe';
import { ResetPassword1Component } from '../templates/template1/auth/reset-password/reset-password.component';
import { CancellationModalComponent } from './cancellation-modal/cancellation-modal.component';
import { TranslateMapPipe } from './pipes/translate-map.pipe';
import { Pages1Component } from '../templates/template1/pages/pages/pages.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { CustomLoaderComponent } from './custom-loader/custom-loader.component';
import { EquipmentIconComponent } from './equipment-icon/equipment-icon.component';

const components = [
  TemplateComponent,
  BaseLayoutComponent,
  AlertComponent,
  TruncatePipe,
  CustomCurrencyPipe,
  TranslateMapPipe,
  SafeHtmlPipe,
  RoomCompositionComponent,
  RoomBedTypesComponent,
  ReservationArrivalDepartureComponent,
  PaymentCardComponent,
  StripeComponent,
  CancellationModalComponent,
  CustomLoaderComponent,
  EquipmentIconComponent,
];

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    MomentModule,
    Nl2BrPipeModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: components,
  exports: components,
  entryComponents: [
    MainLayout1Component,
    Home1Component,
    EstablishmentsList1Component,
    EstablishmentDetails1Component,
    Login1Component,
    Register1Component,
    ReservationsBook1Component,
    Profile1Component,
    Reservations1Component,
    UserReservation1Component,
    Messages1Component,
    Password1Component,
    Pages1Component,
    ResetPassword1Component
  ]
})
export class SharedComponentsModule { }
