import {
  Component, EventEmitter, Input, Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SiteService } from 'src/app/shared/services/site.service';
import { CreditCardValidator } from 'angular-cc-library';


@Component({
  selector: 'app-payment-card',
  templateUrl: 'payment-card.component.html',
  styleUrls: ['payment-card.component.scss']
})
export class PaymentCardComponent {
  paymentForm: FormGroup;

  submitted: boolean = false;

  @Input()
  amount: number = 0;

  @Input()
  loading: boolean = false;

  @Output()
  public paymentSubmitted = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    public site: SiteService
  ) {
    this.paymentForm = this.fb.group({
      name: ['', []],
      number: ['', [<any>CreditCardValidator.validateCCNumber]],
      cvc: ['', [<any>Validators.required, <any>Validators.minLength(3), <any>Validators.maxLength(4)]],
      expiration_date: ['', [<any>CreditCardValidator.validateExpDate]]
    });
  }

  formatExpirationDate(event) {
    let expirationDate = this.paymentForm.controls.expiration_date.value;
    expirationDate = expirationDate.replace(/^(\d\d)(\d)$/g, '$1 / $2');
    expirationDate = expirationDate.replace(/[^\d\/ ]/g, '');

    if (event.keyCode === 8) {
      // Backspace key
      expirationDate = expirationDate.replace(/^(\d)(\d) \/$/g, '$1');
    }

    this.paymentForm.patchValue({ expiration_date: expirationDate });
  }

  submitPaymentForm() {
    this.submitted = true;
    if (!this.paymentForm.valid) {
      return;
    }

    const form = {
      number: this.paymentForm.controls.number.value,
      name: this.paymentForm.controls.name.value,
      cvc: this.paymentForm.controls.cvc.value,
      expiration_date: this.paymentForm.controls.expiration_date.value
    };

    this.paymentSubmitted.emit(form);
  }
}
