/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../../../components/pipes/safe-html.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../../components/pipes/translate-map.pipe";
import * as i6 from "../../../../services/language.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/common";
import * as i9 from "./footer.component";
import * as i10 from "../../../../services/site.service";
import * as i11 from "../../../../services/api-url.service";
import * as i12 from "@angular/common/http";
import * as i13 from "@angular/router";
var styles_Footer1Component = [i0.styles];
var RenderType_Footer1Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Footer1Component, data: {} });
export { RenderType_Footer1Component as RenderType_Footer1Component };
function View_Footer1Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "dropdown-item"], ["ngbDropdownItem", ""]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setLang(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, [[4, 4]], 0, i2.NgbDropdownItem, [i1.ElementRef], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).disabled; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.langService.availableLangNames[_v.context.$implicit]; _ck(_v, 2, 0, currVal_1); }); }
export function View_Footer1Component_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.SafeHtmlPipe, [i4.DomSanitizer]), i1.ɵpid(0, i5.TranslateMapPipe, [i6.LanguageService]), (_l()(), i1.ɵeld(2, 0, null, null, 31, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 30, "div", [["class", "bot3-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "container footer-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "page-content"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(6, 1), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 18, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 17, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 16, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 13, "div", [["class", "d-inline-block"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(14, 737280, null, 3, i2.NgbDropdown, [i1.ChangeDetectorRef, i2.NgbDropdownConfig, i8.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2], null, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _menuElement: 0 }), i1.ɵqud(335544320, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(18, 0, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "btn btn-outline-secondary dropdown-toggle"], ["id", "dropdownLang"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, null, 0, i2.NgbDropdownToggle, [i2.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i2.NgbDropdownAnchor, null, [i2.NgbDropdownToggle]), (_l()(), i1.ɵted(21, null, ["", ""])), (_l()(), i1.ɵeld(22, 0, [[2, 0]], null, 4, "div", [["aria-labelledby", "dropdownLang"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 23).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 23).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 23).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 16384, [[1, 4]], 1, i2.NgbDropdownMenu, [i2.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Footer1Component_1)), i1.ɵdid(26, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(27, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 5, "div", [["class", "bot3 clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 4, "div", [["class", "copyrights"]], null, null, null, null, null)), (_l()(), i1.ɵted(30, null, ["", " \u00A9 Anytime by "])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "a", [["href", "https://www.livingparisian.com/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Living Parisian"])), (_l()(), i1.ɵted(-1, null, [". "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 14, 0); var currVal_8 = _co.langService.availableLangs; _ck(_v, 26, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 1), _co.siteService.settings.footerContent)))); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("footer.LANG")); _ck(_v, 11, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 14).isOpen(); _ck(_v, 13, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 19).dropdown.isOpen(); _ck(_v, 18, 0, currVal_3); var currVal_4 = _co.langService.availableLangNames[_co.langService.currentLang]; _ck(_v, 21, 0, currVal_4); var currVal_5 = true; var currVal_6 = i1.ɵnov(_v, 23).dropdown.isOpen(); var currVal_7 = i1.ɵnov(_v, 23).placement; _ck(_v, 22, 0, currVal_5, currVal_6, currVal_7); var currVal_9 = _co.currentYear; _ck(_v, 30, 0, currVal_9); }); }
export function View_Footer1Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "template1-footer", [], null, null, null, View_Footer1Component_0, RenderType_Footer1Component)), i1.ɵdid(1, 49152, null, 0, i9.Footer1Component, [i10.SiteService, i11.ApiUrlService, i12.HttpClient, i6.LanguageService, i13.Router], null, null)], null, null); }
var Footer1ComponentNgFactory = i1.ɵccf("template1-footer", i9.Footer1Component, View_Footer1Component_Host_0, {}, {}, []);
export { Footer1ComponentNgFactory as Footer1ComponentNgFactory };
