import {
  Component, Input, Output, EventEmitter
} from '@angular/core';

@Component({
  selector: 'number-selector-1',
  templateUrl: 'number-selector.component.html',
  styleUrls: ['./number-selector.component.scss']
})
export class NumberSelector1Component {
  inputValue : number;

  @Input() minValue?: number;

  @Input() maxValue?: number;

  @Output()
  valueChange = new EventEmitter<number>(true);

  @Input()
  get value() {
    return this.inputValue;
  }

  set value(val) {
    this.inputValue = val;
  }

  sub() {
    if (this.minValue !== undefined && this.value <= this.minValue) {
      return;
    }
    this.value--;
    this.valueChange.emit(this.inputValue);
  }

  add() {
    if (this.maxValue !== undefined && this.value >= this.maxValue) {
      return;
    }
    this.value++;
    this.valueChange.emit(this.inputValue);
  }

  constructor(
  ) {
  }

  ngOnInit() {
    if (this.minValue !== undefined && this.value <= this.minValue) {
      this.value = this.minValue;
    }
    if (this.maxValue !== undefined && this.value >= this.maxValue) {
      this.value = this.maxValue;
    }
  }
}
