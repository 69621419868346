import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbCarouselModule, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Daterangepicker } from 'ng2-daterangepicker';
import { Select2Module } from 'ng2-select2';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { MomentModule } from 'ngx-moment';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { HomeService } from 'src/app/home/home/home.service';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { environment } from '../../../../environments/environment';
import { MomentDateFormatter } from '../../common/moment-date.formatter';
import { CancellationModalComponent } from '../../components/cancellation-modal/cancellation-modal.component';
import { SharedComponentsModule } from '../../components/shared-components.module';
import { Login1Component } from './auth/login/login.component';
import { Register1Component } from './auth/register/register.component';
import { ResetPassword1Component } from './auth/reset-password/reset-password.component';
import { AppEquipmentsComponent } from './establishments/equipments/app-equipments.component';
import { EstablishmentDetails1Component } from './establishments/establishment-details/establishment-details.component';
import { EstablishmentsList1Component } from './establishments/establishments-list/establishments-list.component';
import { AppRulesComponent } from './establishments/rules/app-rules.component';
import { TypologyDetailsComponent } from './establishments/typology-details/typology-details.component';
import { Home1Component } from './home/home.component';
import { Footer1Component } from './layout/footer/footer.component';
import { MainLayout1Component } from './layout/main-layout.component';
import { Navbar1Component } from './layout/navbar/navbar.component';
import { NumberSelector1Component } from './layout/number-selector/number-selector.component';
import { Search1Component } from './layout/search/search.component';
import { Pages1Component } from './pages/pages/pages.component';
import { ReservationsBook1Component } from './reservations/reservations-book/reservations-book.component';
import { Messages1Component } from './user/messages/messages.component';
import { Password1Component } from './user/password/password.component';
import { ProfileNavigation1Component } from './user/profile-navigation/profile-navigation.component';
import { Profile1Component } from './user/profile/profile.component';
import { UserReservation1Component } from './user/reservation/reservation.component';
import { Reservations1Component } from './user/reservations/reservations.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    Select2Module,
    MomentModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_MAPS_API_KEY
    }),
    NgbModule,
    Daterangepicker,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbCarouselModule,
    SharedComponentsModule,
    PerfectScrollbarModule,
    TranslateModule,
    Nl2BrPipeModule,
    Ng2TelInputModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    CreditCardDirectivesModule
  ],
  declarations: [
    MainLayout1Component,
    Home1Component,
    Navbar1Component,
    Footer1Component,
    NumberSelector1Component,
    Search1Component,
    EstablishmentsList1Component,
    EstablishmentDetails1Component,
    AppEquipmentsComponent,
    AppRulesComponent,
    TypologyDetailsComponent,
    Login1Component,
    Register1Component,
    ReservationsBook1Component,
    Profile1Component,
    Reservations1Component,
    UserReservation1Component,
    Messages1Component,
    ProfileNavigation1Component,
    Password1Component,
    ResetPassword1Component,
    Pages1Component
  ],
  providers: [
    HomeService,
    {
      provide: NgbDateParserFormatter,
      useClass: MomentDateFormatter,
      deps: [TranslateService]
    }
  ],
  entryComponents: [
    TypologyDetailsComponent,
    CancellationModalComponent
  ]
})
export class Template1Module { }
