/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./number-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./number-selector.component";
var styles_NumberSelector1Component = [i0.styles];
var RenderType_NumberSelector1Component = i1.ɵcrt({ encapsulation: 0, styles: styles_NumberSelector1Component, data: {} });
export { RenderType_NumberSelector1Component as RenderType_NumberSelector1Component };
export function View_NumberSelector1Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "number-selector"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "number-selector-minus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sub() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "far fa-minus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "number-selector-val"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "number-selector-plus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "far fa-plus"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 4, 0, currVal_0); }); }
export function View_NumberSelector1Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "number-selector-1", [], null, null, null, View_NumberSelector1Component_0, RenderType_NumberSelector1Component)), i1.ɵdid(1, 114688, null, 0, i2.NumberSelector1Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NumberSelector1ComponentNgFactory = i1.ɵccf("number-selector-1", i2.NumberSelector1Component, View_NumberSelector1Component_Host_0, { minValue: "minValue", maxValue: "maxValue", value: "value" }, { valueChange: "valueChange" }, []);
export { NumberSelector1ComponentNgFactory as NumberSelector1ComponentNgFactory };
