<div class="card theme-1">
  <div class="card-body room-wide m-0">
    <span style="float: right;">
      <i class="fas fa-times d-flex"
        (click)="modal.dismiss('')"
        style="font-size: 20px; cursor: pointer"></i>
    </span>
    <div class="caption w-100 mb-3">
      <div class="room-wide-columns clearfix">
        <div class="room-wide-left w-100 pr-0">
          <div class="txt2">
            <div class="mb-2">{{ typo.name | translateMap }}</div>
            <div class="mb-1" style="font-size: 20px!important;">
              <div class="d-inline-block mr-4">
                <i class="fas fa-user mr-2"></i> {{ typo.capacity }}
              </div>
              <div class="d-inline-block mr-4" *ngIf="establishmentDetailsService.getNumberOfRooms(typo) > 0">
                <i class="fas fa-bed mr-2"></i> {{ establishmentDetailsService.getNumberOfRooms(typo) }}
              </div>
              <div class="d-inline-block mr-4" *ngIf="establishmentDetailsService.getNumberOfCommunSpace(typo) > 0">
                <i class="fas fa-couch mr-2"></i> {{ establishmentDetailsService.getNumberOfCommunSpace(typo) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ngb-carousel *ngIf="typo.photos && typo.photos.length > 0" class="shadow-lg" (slide)="displayTags($event)">
          <ng-template ngbSlide *ngFor="let photo of typo.photos; let i = index" [id]="'typology-photo-' + i">
            <div class="embed-responsive embed-responsive-16by9">
              <div class="embed-responsive-item" [ngStyle]="{ 'background-image': 'url('+getImageUrl(photo.path)+')' }"></div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
      <div class="col-12 mt-2">
        <div *ngIf="typo.photos && typo.photos.length > 0 && currentPhoto && currentPhoto.phototags && currentPhoto.phototags.length > 0">
          <span *ngFor="let tag of currentPhoto.phototags" class="d-inline-block mr-1">
            <span class="badge badge-warning text-white p-2" style="border-radius: 10px; background-color: var(--main-color)!important;"><i class="far fa-table-tennis pr-1"></i> {{ 'tags.' + (tag | uppercase) | translate }}</span>
          </span>
        </div>
      </div>
    </div>

    <hr class="pt-2">

    <div class="caption w-100 pt-0">
      <div class="room-wide-columns clearfix">
        <div class="room-wide-left w-100 pr-0">
          <div class="txt5 pt-0" [innerHTML]="typo.description | translateMap | nl2br"></div>
        </div>
      </div>
    </div>

    <div class="pt-3 text-uppercase pb-2" *ngIf="typo.equipments.length > 0">
      {{ 'listings.EQUIPMENTS' | translate }}
    </div>
    <app-equipments [selectedEquipments]="typo.equipments" [type]="'typology'" details="true"></app-equipments>

    <div class="pt-3 text-uppercase pb-2" *ngIf="typo.rooms.length > 0">
      {{ 'listings.typologies.ROOMS' | translate }}
    </div>
    <div class="row">
      <div class="col-4" *ngFor="let room of getRoom(); let i = index;">
        <div class="card shadow p-2 mt-2 mb-2">
          <i class="fas fa-bed d-flex justify-content-center pt-2 mb-3" style="font-size: 24px"></i>
          <h6 class="d-flex justify-content-start">{{ 'listings.typologies.BEDROOM' | translate }}  {{i+1}}</h6>
          <app-room-bed-types [composition]="room.composition"></app-room-bed-types>
        </div>
      </div>
      <div class="col-4" *ngFor="let room of getCommonSpace(); let i = index; let l = count">
        <div class="card shadow p-2 mt-2 mb-2">
          <i class="fas fa-couch d-flex justify-content-center pt-2 mb-3" style="font-size: 24px"></i>
          <h6 class="d-flex justify-content-start">{{ 'listings.typologies.COMMON_SPACE' | translate }} {{ l > 1 ? i+1 : ''}}</h6>
          <app-room-bed-types [composition]="room.composition"></app-room-bed-types>
        </div>
      </div>
    </div>
  </div>
</div>

