import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Select2OptionData } from 'ng2-select2';
import { SearchService } from 'src/app/shared/services/search.service';
import { SiteService } from 'src/app/shared/services/site.service';

@Component({
  selector: 'app-search-1',
  templateUrl: 'search.component.html',
  styleUrls: ['./search.component.scss']
})

export class Search1Component implements OnInit {
  exampleData: Select2OptionData[];

  selectOptions: Select2Options = {
    minimumResultsForSearch: 10
  };

  roomName: string = 'room';

  rooms = [1, 2, 3, 4, 5, 6];

  dateRange = {
    start: null,
    end: null
  };

  constructor(
    private router: Router,
    private site: SiteService,
    public searchService: SearchService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    if (this.site.settings.accommodationType) {
      this.roomName = this.site.settings.accommodationType;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      let triggerSearch = false;
      if (params.startDate
        && params.endDate
      ) {
        try {
          let startDate = {
            year: null, 
            month: null, 
            day: null
          };
          [startDate.year, startDate.month, startDate.day] = params.startDate.split('-');
          this.searchService.datePickerOptions.startDate = startDate;
          this.startDateChanged(this.searchService.datePickerOptions.startDate);
            
          let endDate = {
            year: null, 
            month: null, 
            day: null
          };
          [endDate.year, endDate.month, endDate.day] = params.endDate.split('-');
          this.searchService.datePickerOptions.endDate = endDate;
          this.endDateChanged(this.searchService.datePickerOptions.endDate);

          triggerSearch = true;
        } catch (e) {
          console.log(e);
        }
      }
      if (params.adultCount) {
        this.searchService.criteria.nbAdults = params.adultCount;
        triggerSearch = true;
      }
      if (params.childCount) {
        this.searchService.criteria.nbChildren = params.childCount;
        triggerSearch = true;
      }
      if (params.infantCount) {
        this.searchService.criteria.nbInfants = params.infantCount;
        triggerSearch = true;
      }
      if (params.accommodationUnitCount) {
        this.searchService.criteria.nbRooms = params.accommodationUnitCount;
        triggerSearch = true;
      }


      if (triggerSearch) {
        this.search();
      }
    });
  }

  startDateChanged(date) {
    this.searchService.criteria.arrivalDate = moment([date.year, date.month - 1, date.day]);
    const minEnd = moment(this.searchService.criteria.arrivalDate).add(1, 'day');

    if (this.searchService.criteria.arrivalDate.isAfter(this.searchService.criteria.departureDate)) {
      const newEnd = moment(this.searchService.criteria.arrivalDate).add(1, 'day');
      this.searchService.criteria.departureDate = newEnd;

      this.searchService.datePickerOptions.endDate = { year: newEnd.year(), month: newEnd.month() + 1, day: newEnd.date() };
    }

    this.searchService.datePickerOptions.minEndDate = { year: minEnd.year(), month: minEnd.month() + 1, day: minEnd.date() };
  }

  endDateChanged(date) {
    this.searchService.criteria.departureDate = moment([date.year, date.month - 1, date.day]);
  }

  openDatePicker() {
    // Open the datepicker
    document.getElementById('inputDateRangePicker').click();
    // Select the end input
    document.getElementsByName('daterangepicker_end')[0].focus();
  }

  search() {
    this.searchService.currentPage = 1;
    this.searchService.saveCriteria();
    this.searchService.search();
    this.router.navigate(['/search']);
  }
}
