import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { tap, map, catchError } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';

import { LocalStoreService } from './local-store.service';
import { LoginToken } from '../models/login-token.model';
import { ApiUrlService } from './api-url.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticated = false;

  token = null;

  user = null;

  public redirectAfterLogin: string = null;

  constructor(
    private store: LocalStoreService,
    private router: Router,
    private http: HttpClient,
    private apiUrlService: ApiUrlService
  ) {
    this.loadFromStorage();
    this.authenticated = this.checkAuth();
  }

  loadFromStorage() {
    this.token = this.store.getItem('token');
    this.user = this.store.getItem('user');
  }

  get pictureProfileUrl(): string {
    return this.apiUrlService.siteBaseUrl(true) + 'guest/picture/' + this.user.photoPath;
  }

  saveProfile() {
    this.store.setItem('user', this.user);
  }

  loadProfile() {
    if (!this.authenticated) return;
    return this.http.get<any>(this.apiUrlService.siteBaseUrl() + 'auth/guest/profile').toPromise()
      .then((data) => {
        this.user.id = data.id;
        this.user.firstName = data.firstName;
        this.user.lastName = data.lastName;
        this.user.phone = data.phone;
        this.user.email = data.email;
        this.user.photoPath = data.photoPath;
        this.user.site = data.site;

        this.saveProfile();
        return true;
      });
  }

  checkAuth() {
    return this.token !== null && this.user !== null;
  }

  signin(credentials) {
    return this.http.post(this.apiUrlService.siteBaseUrl() + 'auth/guest', credentials).toPromise()
      .then((data: LoginToken) => {
        this.setCredentials(data);
        this.user = data.user;
        this.authenticated = true;
        return this.loadProfile();
      });
  }

  setCredentials(credentials: LoginToken) {
    this.token = credentials.token;
    this.user = credentials.user;
    this.store.setItem('token', this.token);
    this.store.setItem('user', this.user);
  }

  refreshToken() {
    return this.http.post(this.apiUrlService.siteBaseUrl() + 'auth/guest/refresh', {})
      .pipe(map((data: LoginToken) => {
        this.setCredentials(data);
        return data;
      }));
  }

  signout() {
    this.authenticated = false;
    this.store.setItem('token', null);
    this.store.setItem('user', null);
    this.loadFromStorage();
    this.router.navigateByUrl('/');
  }

  setUserLang(lang: string) {
    if (!this.authenticated) return;
    if (this.user.lang === lang) return;
    return this.http.post(this.apiUrlService.siteBaseUrl() + 'guest/lang', { lang })
      .toPromise()
      .then(() => this.user.lang = lang);
  }
}
