/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stripe.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/currency.pipe";
import * as i3 from "../../../services/site.service";
import * as i4 from "../../../services/language.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "./stripe.component";
import * as i9 from "../../../services/api-url.service";
import * as i10 from "../../../../reservations/reservations-book/reservations-book.service";
import * as i11 from "../../../services/alert.service";
import * as i12 from "../../../../reservations/reservations-api.service";
var styles_StripeComponent = [i0.styles];
var RenderType_StripeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StripeComponent, data: {} });
export { RenderType_StripeComponent as RenderType_StripeComponent };
export function View_StripeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CustomCurrencyPipe, [i3.SiteService, i4.LanguageService]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "label", [["style", "margin-bottom: 0;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "amount"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 14, "form", [["id", "stripe-payment-form"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i6.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(10, 4210688, null, 0, i6.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i6.ControlContainer, null, [i6.NgForm]), i1.ɵdid(12, 16384, null, 0, i6.NgControlStatusGroup, [[4, i6.ControlContainer]], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["id", "stripe-payment-element"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 5, "button", [["class", "pay-button btn btn-primary btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pay($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "i", [], null, null, null, null, null)), i1.ɵdid(19, 278528, null, 0, i7.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(20, { "fas fa-check": 0, "far fa-spin fa-circle-notch": 1 }), (_l()(), i1.ɵted(21, null, [" ", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_10 = _ck(_v, 20, 0, !_co.paying, _co.paying); _ck(_v, 19, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("book.card.AMOUNT")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), _co.amount)); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 12).ngClassValid; var currVal_7 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.stripeErrorMessage; _ck(_v, 16, 0, currVal_9); var currVal_11 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform("book.PAY")); _ck(_v, 21, 0, currVal_11); }); }
export function View_StripeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-stripe", [], null, null, null, View_StripeComponent_0, RenderType_StripeComponent)), i1.ɵdid(1, 114688, null, 0, i8.StripeComponent, [i9.ApiUrlService, i10.ReservationsBookService, i11.AlertService, i12.ReservationsApiService, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StripeComponentNgFactory = i1.ɵccf("app-stripe", i8.StripeComponent, View_StripeComponent_Host_0, { amount: "amount", publicKey: "publicKey" }, {}, []);
export { StripeComponentNgFactory as StripeComponentNgFactory };
