import { Component, Input, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Typology } from '../../../../models/typology.model';
import { EstablishmentDetailsService } from '../../../../../establishments/establishment-details/establishment-details.service';
import { Photo } from '../../../../models/photo';

@Component({
  selector: 'app-typology-details',
  templateUrl: './typology-details.component.html',
  styleUrls: ['./typology-details.component.scss'],
  providers: [NgbCarouselConfig]
})
export class TypologyDetailsComponent implements OnInit {
  @Input() public typo: Typology;

  @Input() public modal;

  currentPhoto: Photo;

  constructor(config: NgbCarouselConfig, public establishmentDetailsService: EstablishmentDetailsService) {
    // customize default values of carousels used by this component tree
    config.interval = 3000;
    config.wrap = true;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  ngOnInit() {
    this.currentPhoto = this.typo.photos[0];
  }

  getImageUrl(path) {
    return this.establishmentDetailsService.getImageUrl(path);
  }

  displayTags(event) {
    const strIndex = event.current.split('-')[2];
    const index = +strIndex;
    this.currentPhoto = this.typo.photos[index];
  }

  getRoom() {
    return this.typo.rooms.filter(item => !item.isCommonSpace && this.hasAtLeastOneBed(item));
  }

  getCommonSpace() {
    return this.typo.rooms.filter(item => item.isCommonSpace && this.hasAtLeastOneBed(item));
  }

  hasAtLeastOneBed(room) {
    let beds = [
      'simple',
      'double',
      'queen-size',
      'sofa-bed',
      'king-size',
      'small-double',
      'sofa',
      'bunk-bed',
      'child-bed',
      'hammock',
      'waterbed'
    ];
    for (let bed of beds) {
      if (room.composition[bed]
          && room.composition[bed] > 0
      ) {
        return true;
      }
    }
    return false;
  }
}
