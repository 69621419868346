import * as tslib_1 from "tslib";
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { AlertType } from 'src/app/shared/models/alert-type.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ListingsApiService } from '../../establishments/establishments-api.service';
import { AuthService } from '../../shared/services/auth.service';
import { SessionStoreService } from '../../shared/services/session-store.service';
import { ReservationsApiService } from '../reservations-api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../establishments/establishments-api.service";
import * as i2 from "../../shared/services/session-store.service";
import * as i3 from "../../shared/services/auth.service";
import * as i4 from "@angular/router";
import * as i5 from "../reservations-api.service";
import * as i6 from "@angular/forms";
import * as i7 from "../../shared/services/alert.service";
import * as i8 from "@ngx-translate/core";
var ReservationsBookService = /** @class */ (function () {
    function ReservationsBookService(listingsApi, sessionStorage, auth, router, reservationApi, fb, alert, translate) {
        this.listingsApi = listingsApi;
        this.sessionStorage = sessionStorage;
        this.auth = auth;
        this.router = router;
        this.reservationApi = reservationApi;
        this.fb = fb;
        this.alert = alert;
        this.translate = translate;
        this.establishment = new BehaviorSubject({});
        this.selectedEquipments = new BehaviorSubject([]);
        this.selectedRules = new BehaviorSubject([]);
        this.photos = new BehaviorSubject([]);
        this.arrivalDate = {};
        this.departureDate = {};
        this.detailsPrices = {};
        this.stateList = ['summary', 'information_modification', 'payment'];
        this._indexState = 0;
        this.animatedIndexState = new BehaviorSubject(0);
        this.submitted = false;
        this.loading = false;
        this.paymentLoading = false;
        this.initReservationBooking();
    }
    Object.defineProperty(ReservationsBookService.prototype, "indexState", {
        get: function () {
            return this._indexState;
        },
        set: function (indexState) {
            var _this = this;
            this._indexState = indexState;
            this.animatedIndexState.next(null);
            setTimeout(function () {
                _this.animatedIndexState.next(indexState);
            }, 200);
        },
        enumerable: true,
        configurable: true
    });
    ReservationsBookService.prototype.initReservationBooking = function () {
        this.reservedTypologies = this.sessionStorage.getItem('reservations');
        this.bookedReservation = this.sessionStorage.getItem('reservationInformation');
        if (!this.bookedReservation) {
            this.router.navigateByUrl('/');
            return;
        }
        this.setInitialRepartition();
        this.updatePriceTravelersChangement();
        this.search = this.bookedReservation.criteria;
        this.dayNight = moment(this.search.departureDate).diff(this.search.arrivalDate, 'days');
        this.arrivalDate.month = moment(this.search.arrivalDate).format('MMM');
        this.arrivalDate.day = moment(this.search.arrivalDate).format('D');
        this.arrivalDate.dayText = moment(this.search.arrivalDate).format('dddd');
        this.arrivalDate.completeDateText = moment(this.search.arrivalDate).format('D MMM YYYY');
        this.departureDate.month = moment(this.search.departureDate).format('MMM');
        this.departureDate.day = moment(this.search.departureDate).format('D');
        this.departureDate.dayText = moment(this.search.departureDate).format('dddd');
        this.departureDate.completeDateText = moment(this.search.departureDate).format('D MMM YYYY');
    };
    ReservationsBookService.prototype.getEstablishment = function (establishmentId) {
        var _this = this;
        this.reservationApi.getEstablishment(establishmentId)
            .subscribe(function (establishment) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var equipmentsRules;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.establishment.next(establishment);
                        return [4 /*yield*/, this.listingsApi.separateEquipmentsAndRules(this.establishment.value.equipments)];
                    case 1:
                        equipmentsRules = _a.sent();
                        this.selectedEquipments.next(equipmentsRules.equipments);
                        this.selectedRules.next(equipmentsRules.rules);
                        return [2 /*return*/];
                }
            });
        }); });
        this.reservationApi.getPhotos(establishmentId, -1).subscribe(function (photos) {
            _this.photos.next(photos);
        });
    };
    ReservationsBookService.prototype.getImageUrl = function (path) {
        return this.reservationApi.getLinkPhoto(path);
    };
    ReservationsBookService.prototype.calculTaxesEtGlobale = function () {
        var _this = this;
        this.detailsPrices = {};
        this.bookedReservation.bookedReservation.forEach(function (item) {
            var prices = item.price.priceDetails;
            prices.forEach(function (price) {
                if (!_this.detailsPrices[price.name]) {
                    _this.detailsPrices[price.name] = 0;
                }
                _this.detailsPrices[price.name] += price.amount;
            });
        });
    };
    ReservationsBookService.prototype.changeStep = function (index) {
        this.indexState = index;
    };
    ReservationsBookService.prototype.arrayOne = function (n) {
        var strValue = n + '';
        return Array(parseInt(strValue, 10));
    };
    ReservationsBookService.prototype.getTotalRoom = function () {
        var nb = 0;
        for (var _i = 0, _a = this.reservedTypologies; _i < _a.length; _i++) {
            var typo = _a[_i];
            nb += typo.quantity;
        }
        return nb;
    };
    ReservationsBookService.prototype.updatePriceTravelersChangement = function () {
        var _this = this;
        this.reservationApi.getPrices(this.bookedReservation).toPromise().then(function (res) {
            for (var i = 0; i < _this.bookedReservation.bookedReservation.length; i++) {
                _this.bookedReservation.bookedReservation[i].price = res[i];
            }
            _this.calculTaxesEtGlobale();
        });
    };
    ReservationsBookService.prototype.getCurrentCapacity = function (typo, category) {
        var numberToRetired = typo.repartition[(category === 1) ? 'nbAdults' : 'nbChildren'];
        return typo.capacity - numberToRetired;
    };
    ReservationsBookService.prototype.getTravelersTotal = function () {
        var total = 0;
        for (var _i = 0, _a = this.bookedReservation.bookedReservation; _i < _a.length; _i++) {
            var rep = _a[_i];
            total += (typeof rep.repartition.nbAdults === 'string') ? parseInt(rep.repartition.nbAdults, 10) : rep.repartition.nbAdults;
            total += (typeof rep.repartition.nbChildren === 'string') ? parseInt(rep.repartition.nbChildren, 10) : rep.repartition.nbChildren;
        }
        return total;
    };
    ReservationsBookService.prototype.controlNbTravelers = function () {
        this.updatePriceTravelersChangement();
    };
    ReservationsBookService.prototype.setInitialRepartition = function () {
        var nbAdults = this.bookedReservation.criteria.nbAdults;
        var nbChildren = this.bookedReservation.criteria.nbChildren;
        var nbInfants = this.bookedReservation.criteria.nbInfants;
        if (nbAdults < this.bookedReservation.bookedReservation.length) {
            nbAdults = this.bookedReservation.bookedReservation.length;
        }
        // Assign guest to rooms
        var peopleToAssign = nbAdults + nbChildren + nbInfants;
        while (peopleToAssign > 0) {
            for (var _i = 0, _a = this.bookedReservation.bookedReservation; _i < _a.length; _i++) {
                var room = _a[_i];
                if (nbAdults > 0 && (room.repartition.nbAdults + room.repartition.nbChildren < room.capacity)) {
                    room.repartition.nbAdults++;
                    nbAdults--;
                }
                if (nbChildren > 0 && (room.repartition.nbAdults + room.repartition.nbChildren < room.capacity)) {
                    room.repartition.nbChildren++;
                    nbChildren--;
                }
                if (nbInfants > 0) {
                    room.repartition.nbInfants++;
                    nbInfants--;
                }
                var newPeopleToAssign = nbAdults + nbChildren + nbInfants;
                if (newPeopleToAssign === peopleToAssign) {
                    // There is no more space
                    peopleToAssign = 0;
                    break;
                }
                else {
                    peopleToAssign = newPeopleToAssign;
                }
            }
        }
    };
    ReservationsBookService.prototype.submitPayment = function (paymentForm) {
        var _this = this;
        this.paymentLoading = true;
        this.reservationApi.submitReservation('paymentCardData', paymentForm, this.bookedReservation)
            .then(function (rep) {
            if (!rep.success) {
                throw new Error();
            }
            _this.router.navigateByUrl('/user/reservations/' + rep.reservationCode);
        })
            .catch(function (err) {
            _this.alert.set(AlertType.DANGER, _this.translate.instant('book.ERROR'));
            console.error(err);
        })
            .finally(function () {
            _this.paymentLoading = false;
        });
    };
    ReservationsBookService.ngInjectableDef = i0.defineInjectable({ factory: function ReservationsBookService_Factory() { return new ReservationsBookService(i0.inject(i1.ListingsApiService), i0.inject(i2.SessionStoreService), i0.inject(i3.AuthService), i0.inject(i4.Router), i0.inject(i5.ReservationsApiService), i0.inject(i6.FormBuilder), i0.inject(i7.AlertService), i0.inject(i8.TranslateService)); }, token: ReservationsBookService, providedIn: "root" });
    return ReservationsBookService;
}());
export { ReservationsBookService };
