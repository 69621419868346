import { Component, Input } from '@angular/core';
import { ReservationService } from 'src/app/user/reservation/reservation.service';

@Component({
  selector: '[app-reservation-arrival-departure]',
  templateUrl: 'reservation-arrival-departure.component.html',
  styleUrls: ['reservation-arrival-departure.component.scss']
})
export class ReservationArrivalDepartureComponent {
  @Input() reservation: any;

  constructor(public reservationService: ReservationService) {

  }
}
