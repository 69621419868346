import * as tslib_1 from "tslib";
import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import { ComponentFactoryResolver, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceLocator } from './service-locator';
import { ApiUrlService } from './shared/services/api-url.service';
import { ConfigService } from './shared/services/config.service';
import { SiteService } from './shared/services/site.service';
import { TemplateService } from './shared/services/template.service';
import { PageService } from './shared/services/page.service';
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
export var services = {
    ComponentFactoryResolver: {
        provide: ComponentFactoryResolver,
        deps: []
    },
    TemplateService: {
        provide: TemplateService,
        deps: [ComponentFactoryResolver]
    }
};
export function initializeApp(appConfig, siteService, pageService, templateService) {
    var _this = this;
    return function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, appConfig.load()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, siteService.loadEnvironment()];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, siteService.loadSite()];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, pageService.loadPages()];
                case 4:
                    _a.sent();
                    templateService.applySiteSettings(siteService.settings);
                    return [2 /*return*/, true];
            }
        });
    }); };
}
export function HttpLoaderFactory(http, apiUrl) {
    // return new TranslateHttpLoader(http);
    return new TranslateHttpLoader(http, apiUrl.baseUrl() + 'lang/', '');
}
var ɵ0 = function (translate) {
    switch (translate.currentLang) {
        case 'fr':
            return 'fr-FR';
        case 'en':
            return 'en-US';
    }
};
var AppModule = /** @class */ (function () {
    function AppModule() {
        ServiceLocator.injector = Injector.create(Object.keys(services).map(function (key) { return ({
            provide: services[key].provide,
            useClass: services[key].provide,
            deps: services[key].deps
        }); }));
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
