import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormValidationService } from 'src/app/shared/services/form-validation.service';
import { error } from 'util';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AlertType } from 'src/app/shared/models/alert-type.model';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RegisterService } from '../../../../../auth/register/register.service';

@Component({
  selector: 'app-register-1',
  templateUrl: 'register.component.html'
})
export class Register1Component implements OnInit {
  registerForm: FormGroup;

  submitted = false;

  loading = false;

  public phoneNumber: string;

  public hasPhoneError: boolean = false;

  constructor(private registerService: RegisterService,
    private fb: FormBuilder,
    private router: Router,
    public validation: FormValidationService,
    private alert: AlertService,
    private translate: TranslateService,
    private lang: LanguageService) {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
    });
  }

  getNumber(ev) {
    this.phoneNumber = ev;
  }

  ngOnInit() {
  }

  onPhoneChanged(phoneError) {
    this.hasPhoneError = !phoneError;
  }

  register() {
    this.submitted = true;
    if (!this.registerForm.valid) return;

    this.alert.clear();

    this.loading = true;

    const newUser = {
      firstName: this.registerForm.controls.firstName.value,
      lastName: this.registerForm.controls.lastName.value,
      email: this.registerForm.controls.email.value,
      password: this.registerForm.controls.password.value,
      phone: this.phoneNumber || this.registerForm.controls.phone.value,
      lang: this.lang.currentLang
    };
    this.submitted = true;
    this.registerService.register(newUser)
      .then(() => {

      })
      .catch(err => {
        if (err.error && err.error.message) {
          if (err.error.message == 'The email address is already used.') {
            this.alert.add(AlertType.DANGER, this.translate.instant('user.EMAIL_ADDRESS_TAKEN'));
          } else {
            this.alert.add(AlertType.DANGER, this.translate.instant('common.ERROR_OCCURRED'));
          }
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
