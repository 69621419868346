import { Component } from '@angular/core';
import { ReservationService } from 'src/app/user/reservation/reservation.service';
import { ListingsApiService } from 'src/app/establishments/establishments-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from 'src/app/shared/services/site.service';

@Component({
  selector: 'app-user-messages-1',
  templateUrl: 'messages.component.html',
  styleUrls: ['messages.component.scss']
})
export class Messages1Component {
  form: any = {
    message: ''
  };

  public sending: boolean = false;

  constructor(public reservationService: ReservationService,
    public listingsApiService: ListingsApiService,
    private route: ActivatedRoute,
    private router: Router,
    public site: SiteService) {

  }

  ngOnInit() {
    this.scrollBottom();
  }

  scrollBottom() {
    setTimeout(() => {
      document.getElementById('conversation')
        .scrollTo(0, document.getElementById('conversation').scrollHeight);
      setTimeout(() => {
        document.getElementById('conversation')
          .scrollTo(0, document.getElementById('conversation').scrollHeight);
      }, 20);
    }, 0);
  }

  autoExpand(e: any) {
    const element = typeof e === 'object' ? e.target : document.getElementById(e);
    let scrollHeight = element.scrollHeight + 2;
    if (scrollHeight > 110) {
      scrollHeight = 110;
    }
    element.style.height = scrollHeight + 'px';
  }

  get reservation() {
    return this.reservationService.reservation;
  }

  get messages() {
    return this.reservationService.messages;
  }

  sendMessage() {
    if (this.form.message.length === 0) return;
    this.sending = true;
    return this.reservationService.sendMessage(this.reservation.code, this.form.message.trim())
      .then(() => {
        this.form.message = '';
        this.scrollBottom();
      })
      .finally(() => {
        this.sending = false;
      });
  }
}
