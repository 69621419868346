<div id="content">
    <div class="container">
        <div class="title1">{{ 'titles.RESERVATIONS' | translate }}</div>
        <div class="title2">{{ 'reservations.VIEW_YOUR_RESERVATIONS' | translate }}</div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 reservation-menu">
                <div class="reservation-menu-item" [class.selected]="!showPastReservations" (click)="showPastReservations = false;">
                    <div>{{ 'reservations.INCOMING_RESERVATIONS' | translate }}</div>
                    <div class="menu-underline"></div>
                </div>
                <div class="reservation-menu-item" [class.selected]="showPastReservations" (click)="showPastReservations = true;">
                    <div>{{ 'reservations.PAST_RESERVATIONS' | translate }}</div>
                    <div class="menu-underline"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div *ngIf="displayedReservations === null" class="text-center">
                    {{ 'common.LOADING' | translate }}
                </div>
                <div *ngIf="displayedReservations !== null && displayedReservations.length === 0" class="text-center">
                    {{ 'book.NO_RESERVATION' | translate }}
                </div>
                <div class="room-wide-wrapper mb-4" *ngFor="let reservation of displayedReservations">
                    <div class="reservation-wrapper clearfix" [class.cancelled]="reservation.cancelled">
                        <div class="reservation-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <img [src]="listingsApiService.getLinkPhoto(reservation.establishment.photo)" class="img-fluid">
                                </div>
                                <div class="col-md-auto mt-2">
                                    <h3><span *ngIf="reservation.cancelled" class="badge badge-danger mr-4" style="font-size: 1.5rem;">{{ 'book.CANCELLED' | translate }}</span>{{ reservation.establishment.name | translateMap }}</h3>
                                    <p><i class="fas fa-map-marker-alt"></i> {{ reservation.establishment.address }}</p>
                                    <h3>{{ reservation.totalPrice | customCurrency }}</h3>
                                </div>
                                <div class="col-md-auto" style=" margin-left: auto;">
                                    <div class="row">
                                        <div class="col">
                                            <span>Arrivée</span>
                                            <div class="date-box">
                                                <span class="small">{{ reservation.arrivalDate | amDateFormat: 'dddd' }}</span><br>
                                                <span class="day">{{ reservation.arrivalDate | amDateFormat: 'DD' }}</span><br>
                                                <span class="small">{{ reservation.arrivalDate | amDateFormat: 'MMMM YYYY' }}</span>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <span>Départ</span>
                                            <div class="date-box">
                                                <span class="small">{{ reservation.departureDate | amDateFormat: 'dddd' }}</span><br>
                                                <span class="day">{{ reservation.departureDate | amDateFormat: 'DD' }}</span><br>
                                                <span class="small">{{ reservation.departureDate | amDateFormat: 'MMMM YYYY' }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="reservation-footer">
                            <div class="row justify-content-end">
                                <div class="col-auto">
                                    <a class="nocolor btn btn-primary mr-2" [routerLink]="'/user/reservations/' + reservation.code + '/messages'">{{ 'messages.CONTACT_ESTABLISHMENT' | translate }}</a>
                                    <a class="nocolor btn btn-primary" [routerLink]="'/user/reservations/' + reservation.code">{{ 'reservations.SHOW_RESERVATION' | translate }} <i class="far fa-arrow-right pl-2"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<template1-footer></template1-footer>