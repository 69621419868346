import { EventEmitter } from '@angular/core';
var NumberSelector1Component = /** @class */ (function () {
    function NumberSelector1Component() {
        this.valueChange = new EventEmitter(true);
    }
    Object.defineProperty(NumberSelector1Component.prototype, "value", {
        get: function () {
            return this.inputValue;
        },
        set: function (val) {
            this.inputValue = val;
        },
        enumerable: true,
        configurable: true
    });
    NumberSelector1Component.prototype.sub = function () {
        if (this.minValue !== undefined && this.value <= this.minValue) {
            return;
        }
        this.value--;
        this.valueChange.emit(this.inputValue);
    };
    NumberSelector1Component.prototype.add = function () {
        if (this.maxValue !== undefined && this.value >= this.maxValue) {
            return;
        }
        this.value++;
        this.valueChange.emit(this.inputValue);
    };
    NumberSelector1Component.prototype.ngOnInit = function () {
        if (this.minValue !== undefined && this.value <= this.minValue) {
            this.value = this.minValue;
        }
        if (this.maxValue !== undefined && this.value >= this.maxValue) {
            this.value = this.maxValue;
        }
    };
    return NumberSelector1Component;
}());
export { NumberSelector1Component };
