import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { NavbarService } from './navbar.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./navbar.service";
var RouteService = /** @class */ (function () {
    function RouteService(router, route, navbar) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.navbar = navbar;
        this._currentRoute = null;
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function () { return _this.route; }), map(function (route) {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        }), filter(function (route) { return route.outlet === 'primary'; }), mergeMap(function (route) { return route.data; }))
            .subscribe(function (data) {
            _this._currentRoute = {
                name: data.name || ''
            };
            _this.navbar.navbarOpen = false;
        });
    }
    Object.defineProperty(RouteService.prototype, "currentRoute", {
        get: function () {
            return this._currentRoute;
        },
        enumerable: true,
        configurable: true
    });
    RouteService.ngInjectableDef = i0.defineInjectable({ factory: function RouteService_Factory() { return new RouteService(i0.inject(i1.Router), i0.inject(i1.ActivatedRoute), i0.inject(i2.NavbarService)); }, token: RouteService, providedIn: "root" });
    return RouteService;
}());
export { RouteService };
