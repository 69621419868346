import {
  Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, OnInit
} from '@angular/core';
import { ServiceLocator } from 'src/app/service-locator';
import { MainLayout1Component } from '../../templates/template1/layout/main-layout.component';
import { TemplateService } from '../../services/template.service';

@Component({
  selector: 'template-component',
  template: '<ng-template #content></ng-template>'
})
export class TemplateComponent implements OnInit {
  @ViewChild('content', { read: ViewContainerRef }) content: ViewContainerRef;

  public componentName: string;

  private templateService: TemplateService;

  constructor(private componentFactory: ComponentFactoryResolver) {
    this.templateService = ServiceLocator.injector.get(TemplateService);
  }

  public setComponentName(componentName) {
    this.componentName = componentName;
  }

  ngOnInit() {
    this.loadComponents();
  }

  loadComponents() {
    // Get the component name
    const component = this.componentName + this.templateService.currentTemplate + 'Component';
    // Load the component
    const componentFactory = this.componentFactory.resolveComponentFactory(this.templateService.getComponent(component));
    this.content.createComponent(componentFactory);
  }
}
