<footer>

    <!-- <div class="bot1-wrapper">
        <div class="container">
            <div class="bot1 clearfix">
                <div class="logo2_wrapper">
                    <h1 class="logo2" *ngIf="siteService.settings && !siteService.settings.logo">
                        {{siteService.site.name}}</h1>
                    <a routerLink="/">
                        <img [src]="getLogo()" alt="" class="img-fluid" style="max-height: 80px; height: 80px;"
                            *ngIf="siteService.settings && siteService.settings.logo">
                    </a>
                </div>
            </div>
        </div>
    </div> -->
    <div class="bot3-wrapper">
        <div class="container footer-container">
            <div class="page-content" [innerHTML]="(siteService.settings.footerContent | translateMap) | safeHtml">
            </div>
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col">
                    {{ 'footer.LANG' | translate }}
                    <div ngbDropdown class="d-inline-block">
                        <button class="btn btn-outline-secondary" id="dropdownLang"
                            ngbDropdownToggle>{{ langService.availableLangNames[langService.currentLang] }}</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownLang">
                            <button ngbDropdownItem *ngFor="let lang of langService.availableLangs"
                                (click)="setLang(lang)">{{ langService.availableLangNames[lang] }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="bot3 clearfix">
                <div class="copyrights">{{ currentYear }} &copy; Anytime by <a href="https://www.livingparisian.com/"
                        target="_blank">Living Parisian</a>.
                </div>

            </div>
        </div>
    </div>

</footer>