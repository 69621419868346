import { Routes } from '@angular/router';
import { BaseLayoutComponent } from './shared/components/layout/base-layout.component';
import { AuthGuard } from './shared/services/auth.guard';
var ɵ0 = { name: 'home' };
var routes = [
    {
        path: '',
        component: BaseLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './home/home.module#HomeModule',
                data: ɵ0
            },
            {
                path: '',
                loadChildren: './establishments/establishments.module#EstablishmentsModule'
            },
            {
                path: '',
                loadChildren: './auth/auth.module#AuthModule'
            },
            {
                path: '',
                loadChildren: './reservations/reservations.module#ReservationsModule'
            },
            {
                path: '',
                loadChildren: './pages/pages.module#PagesModule'
            },
            {
                path: '',
                loadChildren: './user/user.module#UserModule',
                canActivate: [AuthGuard],
            }
        ]
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
