import {
  AfterViewInit, Component, ElementRef, OnInit, ViewChild
} from '@angular/core';
import { interval, timer } from 'rxjs';
import {
  animate, style, transition, trigger
} from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import index from '@angular/cli/lib/cli';
import { SiteService } from 'src/app/shared/services/site.service';
import { EstablishmentDetailsService } from '../../../../../establishments/establishment-details/establishment-details.service';
import { Establishment } from '../../../../models/establishment.model';
import { Photo } from '../../../../models/photo';
import { Typology } from '../../../../models/typology.model';
import { SearchService } from '../../../../services/search.service';
import { TypologyDetailsComponent } from '../typology-details/typology-details.component';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-establishments-details-1',
  templateUrl: 'establishment-details.component.html',
  styleUrls: ['establishment-details.component.scss'],
  animations: [
    trigger('items', [
      transition(':leave', [
        style({
          transform: 'scale(1)',
          opacity: 1,
          height: '*'
        }),
        animate('1s ease-in-out',
          style({
            transform: 'scale(0.5)',
            opacity: 0,
            height: '0px',
            marginLeft: '0px',
            marginRight: '0px',
            marginBottom: '0px',
            marginTop: '-23px'
          }))
      ]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s',
          style({
            opacity: 1
          }))
      ])
    ]),
    trigger('animatePhoto', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('.4s', style({ opacity: 0 }))
      ]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.4s', style({ opacity: 1 }))
      ]),
    ])
  ]
})
export class EstablishmentDetails1Component implements OnInit {
  establishment: Establishment;

  photos: Photo[];

  typologies: Typology[];

  public typologiesWithPrice: any = null;

  selectedEquipments: string[];

  selectedRules: string[];

  quantityTypo: any = {};

  timer;

  subscribeTimer;

  constructor(public establishmentDetailsService: EstablishmentDetailsService,
    public searchService: SearchService,
    private modalService: NgbModal,
    private router: Router,
    private auth: AuthService,
    public site: SiteService) { }

  ngOnInit() {
    this.searchService.loadCriteria();
    this.establishmentDetailsService.initEstablishmentDetails();

    this.establishmentDetailsService.establishment.subscribe(establishment => {
      this.establishment = establishment;
    });

    this.establishmentDetailsService.selectedEquipments.subscribe(equipments => {
      this.selectedEquipments = equipments;
    });

    this.establishmentDetailsService.selectedRules.subscribe(rules => {
      this.selectedRules = rules;
    });

    this.establishmentDetailsService.photos.subscribe(photos => {
      this.photos = photos;
    });

    this.establishmentDetailsService.typologies.subscribe(typologies => {
      this.typologies = typologies;
      for (const typo of this.typologies) {
        const index = this.establishmentDetailsService.reservedTypologies.findIndex(item => item.id === typo.id);
        if (index !== -1) {
          this.quantityTypo[typo.id] = this.establishmentDetailsService.reservedTypologies[index].quantity;
        } else {
          this.quantityTypo[typo.id] = 1;
        }
      }
    });

    this.searchService.typologiesWithPrices.subscribe(typos => {
      this.typologiesWithPrice = typos;
      this.establishmentDetailsService.typologiesWithPrice = typos;
    });

    this.searchService.loadCriteria();
    this.establishmentDetailsService.initEstablishmentDetails();

    if (this.photos.length > 1) {
      this.startPhotoTimer();
    }
  }

  startPhotoTimer() {
    // Scroll photos
    this.timer = interval(5000);
    this.subscribeTimer = this.timer.subscribe(val => {
      const photo = this.photos[0];
      this.photos.splice(0, 1);
      setTimeout(() => {
        this.photos.push(photo);
      }, 500);
    });
  }

  clickOnImage(index) {
    if (this.photos.length <= 1) return;
    this.subscribeTimer.unsubscribe();
    const photos2: Photo[] = this.photos.splice(0, index);
    setTimeout(() => {
      this.photos = [...this.photos, ...photos2];
    }, 500);

    this.startPhotoTimer();
  }

  getImageUrl(path) {
    return this.establishmentDetailsService.getImageUrl(path);
  }

  scrollTo(idElem: string) {
    $('html, body').animate({ scrollTop: $(idElem).offset().top }, 500); // Go
  }

  arrayOne(n: number) {
    return Array(n);
  }

  saveReservations() {
    this.establishmentDetailsService.saveReservations();
  }

  changeQuantity(idChange: number) {
    const index = this.establishmentDetailsService.reservedTypologies.findIndex(item => item.id === idChange);
    if (index !== -1) {
      this.establishmentDetailsService.reservedTypologies[index].quantity = this.quantityTypo[idChange];
      this.saveReservations();
    }
  }

  openVerticallyCentered(typo) {
    const modal = this.modalService.open(TypologyDetailsComponent, { centered: true, size: 'lg' });
    modal.componentInstance.typo = typo;
    modal.componentInstance.modal = modal;
  }
}
