<div id="content">
  <div class="container" *ngIf="establishment">
    <div class="room-wide-wrapper">
      <div class="room-wide-inner">
        <div class="row room-wide clearfix">
          <div class="col-12 mb-4 bg-light rounded">
            <div class="row">
              <div class="col-12 pt-3 pr-3 pl-3 pb-1">
                <div class="d-inline-block mr-3" *ngFor="let elem of bookService.stateList; let i = index">
                  <h6 [ngClass]="{'text-black-50': i > bookService.indexState}"><i class="fas fa-angle-right mr-2 align-middle" style="font-size: 2.5rem" *ngIf="i > 0"></i>{{'book.step.' + (elem | uppercase) | translate}}</h6>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6" [@fader]="bookService.animatedIndexState.value" [ngSwitch]="bookService.animatedIndexState.value">
            <div *ngSwitchCase="0">
                <h4>{{ 'book.YOUR_RESERVATION_AT' | translate:{establishment: establishment.name} }}</h4>
                <div class="row">
                  <div class="col-2">
                    <div class="text-center p-2 rounded mt-2" style="background-color: rgb(242, 242, 242); box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);">
                      <h6>{{bookService.arrivalDate.month | uppercase}}</h6>
                      <h6>{{bookService.arrivalDate.day}}</h6>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="mt-3 font-weight-bold">
                      {{ 'reservations.ARRIVAL_DATE' | translate }} {{ bookService.arrivalDate.dayText }}
                    </div>
                    <!-- <div class="mt-1">
                      After 16H
                    </div> -->
                  </div>
                  <div class="col-2">
                    <div class="text-center p-2 rounded mt-2" style="background-color: rgb(242, 242, 242); box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);">
                      <h6>{{bookService.departureDate.month | uppercase}}</h6>
                      <h6>{{bookService.departureDate.day}}</h6>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="mt-3 font-weight-bold">
                      {{ 'reservations.DEPARTURE_DATE' | translate }} {{ bookService.departureDate.dayText }}
                    </div>
                    <!-- <div class="mt-1">
                      Before 11H
                    </div> -->
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-12">
                    <h4>{{ 'listings.RULES' | translate }}</h4>
                  </div>
                  <div class="col-6">
                    <h6>{{ 'book.ESTABLISHMENT_RULES' | translate }}</h6>
                  </div>
                  <div class="col-6">
                    <h6>{{ 'book.ROOMS_RULES' | translate }}</h6>
                  </div>
                  <div class="col-6">
                    <app-rules [type]="'establishment'" [selectedRules]="selectedRules" [details]="true" [recap]="true"></app-rules>
                  </div>
                  <div class="col-6">
                    <div *ngFor="let typo of bookService.reservedTypologies">
                      <p *ngIf="bookService.reservedTypologies.length > 1">{{ typo.typology.name | translateMap }}</p>
                      <app-rules [type]="'typology'" [selectedRules]="typo.typology.equipments" [details]="true" [recap]="true"></app-rules>
                    </div>
                  </div>
                </div>
                <div class="mt-auto">
                  <button class="btn btn-primary float-right text-white"
                          (click)="bookService.changeStep(bookService.indexState + 1)"
                          style="background-color: var(--main-color)"><i class="fas fa-angle-right mr-2"></i>{{ 'common.NEXT' | translate }}</button>
                </div>
            </div>
            <div *ngSwitchCase="1">
                <h4>{{ 'reservations.GUESTS' | translate }}</h4>
                <div class="row">
                  <div class="col-12">
                    <hr>
                  </div>
                </div>
    
                <perfect-scrollbar style="max-height: 500px; padding-right: 20px; margin-bottom: 15px" [config]="config">
                  <div *ngFor="let typo of bookService.bookedReservation.bookedReservation; let i = index; let name = typo">
                    <div class="row">
                      <div class="col-12">
                        <h6>{{typo.name | translateMap}}</h6>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12 mb-2">
                        <u>{{ 'reservations.ACCOMODATION' | translate | titlecase }} {{i + 1}}</u>  ({{ ((typo.capacity > 1) ? 'reservations.PEOPLE_MAX' : 'reservations.PERSON_MAX') | translate:{ nbGuests: typo.capacity } }})
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-md-4">
                            <label for="room-{{i+1}}-adult">{{ 'reservations.ADULTS' | translate | titlecase }}</label>
                            <select name="room-{{i+1}}-adult" id="room-{{i+1}}-adult" class="form-control"
                                    [(ngModel)]="typo.repartition.nbAdults"
                                    (ngModelChange)="bookService.controlNbTravelers()">
                              <option *ngFor="let nb of arrayOne(bookService.getCurrentCapacity(typo, 0)); let val = index" [ngValue]="val+1">{{ val+1 }}</option>
                            </select>
                          </div>
                          <div class="col-md-4">
                            <label for="room-{{i+1}}-child">{{ 'reservations.CHILDREN' | translate | titlecase }}</label>
                            <select name="room-{{i+1}}-child" id="room-{{i+1}}-child" class="form-control"
                                    [(ngModel)]="typo.repartition.nbChildren"
                                    (ngModelChange)="bookService.controlNbTravelers()">
                              <option *ngFor="let nb of arrayOne(bookService.getCurrentCapacity(typo, 1)+1); let val = index" [ngValue]="val">{{ val }}</option>
                            </select>
                          </div>
                          <div class="col-md-4">
                            <label for="room-{{i+1}}-infant">{{ 'reservations.INFANTS' | translate | titlecase }}</label>
                            <select name="room-{{i+1}}-infant" id="room-{{i+1}}-infant" class="form-control"
                                    [(ngModel)]="typo.repartition.nbInfants">
                              <option *ngFor="let nb of arrayOne(6); let val = index" [ngValue]="val">{{ val }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <hr>
                      </div>
                    </div>
                  </div>
                </perfect-scrollbar>
    
                <div class="mt-auto">
                  <button class="btn btn-primary float-right text-white" style="background-color: var(--main-color)" (click)="bookService.changeStep(bookService.indexState + 1)"><i class="fas fa-angle-right mr-2"></i>{{ 'common.NEXT' | translate }}</button>
                  <button class="btn btn-dark float-right text-white mr-2" (click)="bookService.changeStep(bookService.indexState - 1)"><i class="fas fa-angle-left mr-2"></i>{{ 'common.BACK' | translate }}</button>
                </div>
            </div>
            <div *ngSwitchCase="2">
                <h4>{{ 'book.PAYMENT' | translate }}</h4>
                <div class="row">
                  <div class="col-12">
                    <hr>
                  </div>
                </div>
    
                <div class="row">
                  <div class="col-12">
                    <app-alert></app-alert>
                  </div>
                </div>
    
                <div class="d-block my-3">

                  <div class="custom-control custom-radio" *ngIf="paymentMethodIsAvailable('paymentCardData')">
                    <input class="custom-control-input" id="payment-card-data-radio" name="paymentMethod" type="radio" value="paymentCardData" [(ngModel)]="selectedPaymentMethod" required>
                    <label class="custom-control-label" for="payment-card-data-radio">{{ 'book.PAYMENT_CARD' | translate }}</label>
                    <i class="fab fa-cc-visa ml-2" style="font-size: 26px"></i>
                    <i class="fab fa-cc-mastercard ml-2" style="font-size: 26px"></i>
                  </div>

                  
                  <div class="custom-control custom-radio" *ngIf="paymentMethodIsAvailable('stripe')">
                    <input class="custom-control-input" id="stripe-radio" name="paymentMethod" type="radio" value="stripe" [(ngModel)]="selectedPaymentMethod" required>
                    <label class="custom-control-label" for="stripe-radio">{{ 'book.STRIPE' | translate }}</label>
                  </div>
                  <!-- <div class="custom-control custom-radio">
                    <input id="debit" name="paymentMethod" type="radio" class="custom-control-input" required>
                    <label class="custom-control-label" for="debit">Stripe</label>
                    <i class="fab fa-cc-stripe ml-2" style="font-size: 26px"></i>
                  </div>
                  <div class="custom-control custom-radio">
                    <input id="paypal" name="paymentMethod" type="radio" class="custom-control-input" required>
                    <label class="custom-control-label" for="paypal">Paypal</label>
                    <i class="fab fa-cc-paypal ml-2" style="font-size: 26px"></i>
                  </div> -->
                </div>
    
                <div class="row justify-content-center">
                  <div class="col-md-12" *ngIf="selectedPaymentMethod == 'paymentCardData'">
                    <div style="background-color: #f7f7f7; border-radius: 8px; padding: 1em;">
                      <app-payment-card [loading]="bookService.paymentLoading" (paymentSubmitted)="bookService.submitPayment($event)" [amount]="bookService.detailsPrices.total_price"></app-payment-card>
                    </div>
                  </div>

                  <div class="col-md-12" *ngIf="selectedPaymentMethod == 'stripe'">
                    <div style="background-color: #f7f7f7; border-radius: 8px; padding: 1em;">
                      <app-stripe [publicKey]="establishment.paymentMethodConfigurations.stripe.parsedData.publicKey" [amount]="bookService.detailsPrices.total_price"></app-stripe>
                    </div>
                  </div>
                </div>
    
                <hr class="mb-4">
    
                <div class="mt-auto">
                  <!-- <button type="submit" class="btn btn-primary float-right text-white" style="background-color: var(--main-color)">{{ 'book.PAY' | translate }}</button> -->
                  <button class="btn btn-dark text-white mr-2" (click)="bookService.changeStep(bookService.indexState - 1)"><i class="fas fa-angle-left mr-2"></i>{{ 'common.BACK' | translate }}</button>
                </div>
          </div>
          </div>

          <div class="col-sm-6 border-left" *ngIf="establishment">
            <div class="row">
              <div class="col-7">
                <h4>{{establishment.name}}</h4>
                <h6 [title]="establishment.description" *ngIf="establishment.description">{{(establishment.description.length>150)? (establishment.description | translateMap | slice:0:150)+'..':(establishment.description)}}</h6>
              </div>
              <div class="col-5">
                <div class="embed-responsive embed-responsive-16by9" *ngIf="photos && photos.length > 0">
                  <img [src]="getImageUrl(photos[0].path)" class="embed-responsive-item">
                </div>
              </div>
              <div class="col-12" style="margin-top: 2px;">
                <hr>
              </div>
              <div class="col-12 mt-2">
                <h5 class="font-weight-normal"><i class="fas fa-user-friends mr-2 font-weight-normal"></i> {{ bookService.getTravelersTotal() }} {{ ((bookService.getTravelersTotal() > 1) ? 'reservations.TRAVELLERS' : 'reservations.TRAVELLER') | translate | lowercase }}</h5>
              </div>
              <div class="col-12">
                <h5 class="font-weight-normal">
                  <i class="fas fa-calendar-day font-weight-normal" style="margin-right: 14.5px"></i>
                  {{bookService.arrivalDate.completeDateText}}
                  <i class="fas fa-arrow-right font-weight-normal"></i>
                  {{bookService.departureDate.completeDateText}}
                </h5>
              </div>
              <div class="col-12">
                <hr>
              </div>
              <div class="col-12">
                <h5>{{'book.price.PRICE_DETAILS' | translate}}</h5>
              </div>
              <div class="col-12" *ngFor="let item of bookService.detailsPrices | keyvalue ">
                <div class="row" *ngIf="item.key !== 'final_price' && item.key !== 'total_price'">
                  <div class="col-8">
                    <h6 class="font-weight-normal">{{'book.price.' + (item.key | uppercase) | translate}}</h6>
                  </div>
                  <div class="col-4 text-right">
                    <h6 class="font-weight-normal">{{item.value | customCurrency}}</h6>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-8">
                    <h6>{{'book.price.TOTAL' | translate}}</h6>
                  </div>
                  <div class="col-4 text-right">
                    <h6>{{bookService.detailsPrices.final_price | customCurrency}}</h6>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <hr>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-8">
                    <h6>{{'book.price.TO_PAY' | translate}}</h6>
                  </div>
                  <div class="col-4 text-right">
                    <h6>{{bookService.detailsPrices.total_price | customCurrency}}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<template1-footer></template1-footer>