/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./equipment-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./equipment-icon.component";
var styles_EquipmentIconComponent = [i0.styles];
var RenderType_EquipmentIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EquipmentIconComponent, data: {} });
export { RenderType_EquipmentIconComponent as RenderType_EquipmentIconComponent };
function View_EquipmentIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_EquipmentIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "equipment-icon"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("/assets/icons/custom/" + _co.iconFile); _ck(_v, 0, 0, currVal_0); }); }
export function View_EquipmentIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquipmentIconComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquipmentIconComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.icon && !_co.icon.startsWith("custom_")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.icon && _co.icon.startsWith("custom_")); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_EquipmentIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-equipment-icon", [], null, null, null, View_EquipmentIconComponent_0, RenderType_EquipmentIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.EquipmentIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EquipmentIconComponentNgFactory = i1.ɵccf("app-equipment-icon", i3.EquipmentIconComponent, View_EquipmentIconComponent_Host_0, { icon: "icon" }, {}, []);
export { EquipmentIconComponentNgFactory as EquipmentIconComponentNgFactory };
