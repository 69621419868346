<div class="container">
    <div class="booking-inner clearfix">
        <form action="javascript:;" class="form1 clearfix">
            <div class="col1 c1 h-100">
                <div class="input1_wrapper">
                    <label>{{ 'reservations.ARRIVAL_DATE' | translate }}</label>
                    <div class="input1_inner">
                        <input class="form-control" [placeholder]="'reservations.ARRIVAL' | translate" name="dp1"
                            navigation="arrows" outsideDays="visible" [showWeekNumbers]="false" (click)="d1.toggle()" [ngModel]="searchService.datePickerOptions.startDate" (ngModelChange)="searchService.datePickerOptions.startDate = $event; startDateChanged($event);"
                            ngbDatepicker #d1="ngbDatepicker" [minDate]="searchService.datePickerOptions.minStartDate">
                    </div>
                </div>
            </div>
            <div class="col1 c2 h-100">
                <div class="input1_wrapper">
                    <label>{{ 'reservations.DEPARTURE_DATE' | translate }}</label>
                    <div class="input1_inner">
                        <input class="form-control" [placeholder]="'reservations.DEPARTURE' | translate" name="dp2"
                            navigation="arrows" outsideDays="visible" [showWeekNumbers]="false" (click)="d2.toggle()" [ngModel]="searchService.datePickerOptions.endDate" (ngModelChange)="searchService.datePickerOptions.endDate = $event;endDateChanged($event);"
                            ngbDatepicker #d2="ngbDatepicker" [minDate]="searchService.datePickerOptions.minEndDate">
                    </div>
                </div>
            </div>
            <div class="col4 c4 h-100" [ngbPopover]="popGuests" [autoClose]="'outside'" popoverClass="guests-popover">
                <div class="text_wrapper">
                    <label></label>
                    <div class="guests-item">
                        {{ searchService.criteria.nbAdults }} {{ (searchService.criteria.nbAdults > 1 ? 'reservations.ADULTS' : 'reservations.ADULT') | translate }} - {{ searchService.criteria.nbChildren }} {{ (searchService.criteria.nbChildren > 1 ? 'reservations.CHILDREN' : 'reservations.CHILD') | translate }}
                    </div>
                </div>
            </div>
            <div class="col2 c5">
                <div class="select1_wrapper">
                    <label>Rooms</label>
                    <div class="select1_inner">
                        <select class="select" style="width: 100%" name="nbRooms"
                            [(ngModel)]="searchService.criteria.nbRooms">
                            <option *ngFor="let room of rooms" [value]="room">{{ room }} {{ 'administration.accommodationTypes.' + (roomName | uppercase) + (room > 1 ? '_PLURAL' : '') | translate | lowercase }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col3 c6">
                <button type="submit" class="btn-form1-submit" (click)="search()">{{ 'common.SEARCH' | translate }}</button>
            </div>
        </form>
    </div>
</div>


<ng-template #popGuests>
    <div class="guests-selector">
        <div class="mb-2">{{ 'reservations.ADULTS' | translate | titlecase }}</div>
        <number-selector-1 minValue="1" [(value)]="searchService.criteria.nbAdults"></number-selector-1>
        <div class="mt-2 mb-2">{{ 'reservations.CHILDREN' | translate | titlecase }}</div>
        <number-selector-1 minValue="0" [(value)]="searchService.criteria.nbChildren"></number-selector-1>
        <div class="mb-2 mt-2">{{ 'reservations.INFANTS' | translate | titlecase }}</div>
        <number-selector-1 minValue="0" [(value)]="searchService.criteria.nbInfants"></number-selector-1>
    </div>
</ng-template>