import * as tslib_1 from "tslib";
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
var MomentDateFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(MomentDateFormatter, _super);
    function MomentDateFormatter(translate) {
        var _this = _super.call(this) || this;
        _this.translate = translate;
        _this.DT_FORMAT = 'DD/MM/YYYY';
        _this.translate.get('common.MOMENT_DATE').subscribe(function (format) {
            _this.DT_FORMAT = format;
        });
        return _this;
    }
    MomentDateFormatter.prototype.parse = function (value) {
        if (value) {
            value = value.trim();
            var mdt = moment(value, this.DT_FORMAT);
            return { year: mdt.year(), month: mdt.month() + 1, day: mdt.date() };
        }
        return null;
    };
    MomentDateFormatter.prototype.format = function (date) {
        if (!date)
            return '';
        var mdt = moment([date.year, date.month - 1, date.day]);
        if (!mdt.isValid())
            return '';
        return mdt.format(this.DT_FORMAT);
    };
    return MomentDateFormatter;
}(NgbDateParserFormatter));
export { MomentDateFormatter };
