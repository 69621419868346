import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {
  private ls: Storage;

  constructor(@Inject(WINDOW) private window: Window,) {
    this.ls = window.localStorage;
  }

  public setItem(key, value) {
    value = JSON.stringify(value);
    this.ls.setItem(key, value);
    return true;
  }

  public getItem(key) {
    const value = this.ls.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      // console.log(e)
      return null;
    }
  }

  public clear() {
    this.ls.clear();
  }
}
