import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from './api-url.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api-url.service";
var PageService = /** @class */ (function () {
    function PageService(http, apiUrl) {
        this.http = http;
        this.apiUrl = apiUrl;
        this.pages = [];
    }
    PageService.prototype.loadPages = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.http.get(this.apiUrl.siteBaseUrl() + 'pages')
                                .toPromise()];
                    case 1:
                        _a.pages = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PageService.prototype.getPageFromSlug = function (slug) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(this.apiUrl.siteBaseUrl() + 'pages/slug/' + slug)
                        .toPromise()];
            });
        });
    };
    PageService.ngInjectableDef = i0.defineInjectable({ factory: function PageService_Factory() { return new PageService(i0.inject(i1.HttpClient), i0.inject(i2.ApiUrlService)); }, token: PageService, providedIn: "root" });
    return PageService;
}());
export { PageService };
