import { ReservationService } from 'src/app/user/reservation/reservation.service';
import { ListingsApiService } from 'src/app/establishments/establishments-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from 'src/app/shared/services/site.service';
var Messages1Component = /** @class */ (function () {
    function Messages1Component(reservationService, listingsApiService, route, router, site) {
        this.reservationService = reservationService;
        this.listingsApiService = listingsApiService;
        this.route = route;
        this.router = router;
        this.site = site;
        this.form = {
            message: ''
        };
        this.sending = false;
    }
    Messages1Component.prototype.ngOnInit = function () {
        this.scrollBottom();
    };
    Messages1Component.prototype.scrollBottom = function () {
        setTimeout(function () {
            document.getElementById('conversation')
                .scrollTo(0, document.getElementById('conversation').scrollHeight);
            setTimeout(function () {
                document.getElementById('conversation')
                    .scrollTo(0, document.getElementById('conversation').scrollHeight);
            }, 20);
        }, 0);
    };
    Messages1Component.prototype.autoExpand = function (e) {
        var element = typeof e === 'object' ? e.target : document.getElementById(e);
        var scrollHeight = element.scrollHeight + 2;
        if (scrollHeight > 110) {
            scrollHeight = 110;
        }
        element.style.height = scrollHeight + 'px';
    };
    Object.defineProperty(Messages1Component.prototype, "reservation", {
        get: function () {
            return this.reservationService.reservation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Messages1Component.prototype, "messages", {
        get: function () {
            return this.reservationService.messages;
        },
        enumerable: true,
        configurable: true
    });
    Messages1Component.prototype.sendMessage = function () {
        var _this = this;
        if (this.form.message.length === 0)
            return;
        this.sending = true;
        return this.reservationService.sendMessage(this.reservation.code, this.form.message.trim())
            .then(function () {
            _this.form.message = '';
            _this.scrollBottom();
        })
            .finally(function () {
            _this.sending = false;
        });
    };
    return Messages1Component;
}());
export { Messages1Component };
