<div class="col mb-2">
    <div class="d-flex align-items-center">
        <div class="date-box">
            <span class="small">{{ reservation.arrivalDate | amDateFormat: 'dddd' }}</span><br>
            <span class="day">{{ reservation.arrivalDate | amDateFormat: 'DD' }}</span><br>
            <span class="small">{{ reservation.arrivalDate | amDateFormat: 'MMMM YYYY' }}</span>
        </div>
        <div class="ml-4">
            <strong>{{ 'reservations.ARRIVAL' | translate }}</strong><br>
            <div *ngIf="reservation.globalTypologyTime">
                {{ reservationService.getTimePeriodPhrase(reservation.globalTypologyTime.minArrivalTime, reservation.globalTypologyTime.maxArrivalTime) }}
            </div>
            <div *ngIf="!reservation.globalTypologyTime">
                {{ 'reservations.MULTIPLE_ARRIVAL_TIME' | translate }}
            </div>
        </div>
    </div>
</div>
<div class="col mb-2">
    <div class="d-flex align-items-center">
        <div class="date-box">
            <span class="small">{{ reservation.departureDate | amDateFormat: 'dddd' }}</span><br>
            <span class="day">{{ reservation.departureDate | amDateFormat: 'DD' }}</span><br>
            <span class="small">{{ reservation.departureDate | amDateFormat: 'MMMM YYYY' }}</span>
        </div>
        <div class="ml-4">
            <strong>{{ 'reservations.DEPARTURE' | translate }}</strong><br>
            <div *ngIf="reservation.globalTypologyTime">
                {{ reservationService.getTimePeriodPhrase(reservation.globalTypologyTime.minDepartureTime, reservation.globalTypologyTime.maxDepartureTime) }}
            </div>
            <div *ngIf="!reservation.globalTypologyTime">
                {{ 'reservations.MULTIPLE_DEPARTURE_TIME' | translate }}
            </div>
        </div>
    </div>
</div>