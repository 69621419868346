import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import * as i0 from "@angular/core";
import * as i1 from "@ng-toolkit/universal";
import * as i2 from "@angular/common/http";
var SiteService = /** @class */ (function () {
    function SiteService(window, http) {
        this.window = window;
        this.http = http;
        this.environmentCode = '';
    }
    Object.defineProperty(SiteService.prototype, "currency", {
        get: function () {
            return this.site.settings.currency;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SiteService.prototype, "settings", {
        get: function () {
            return this.site.settings;
        },
        enumerable: true,
        configurable: true
    });
    SiteService.prototype.loadEnvironment = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var environmentCode;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!ConfigService.settings.environment) return [3 /*break*/, 1];
                        this.environmentCode = ConfigService.settings.environment;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.http.post(ConfigService.settings.apiBaseUrl + 'environments/reference', {
                            domain: this.window.location.hostname
                        }).toPromise()];
                    case 2:
                        environmentCode = (_a.sent()).environmentCode;
                        this.environmentCode = environmentCode;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SiteService.prototype.loadSite = function () {
        var _this = this;
        return this.http.post(ConfigService.settings.apiBaseUrl + 'sites/reference', {
            domain: ConfigService.settings.domain || this.window.location.hostname,
        })
            .toPromise()
            .then(function (site) {
            return _this.http.get(ConfigService.settings.apiBaseUrl + 'sites/' + site.id + '/settings/public')
                .toPromise();
        })
            .then(function (site) {
            _this.site = site;
            return true;
        })
            .catch(function (err) { return console.error(err); });
    };
    SiteService.ngInjectableDef = i0.defineInjectable({ factory: function SiteService_Factory() { return new SiteService(i0.inject(i1.WINDOW), i0.inject(i2.HttpClient)); }, token: SiteService, providedIn: "root" });
    return SiteService;
}());
export { SiteService };
