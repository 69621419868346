import { Component } from '@angular/core';
import { ProfileService } from 'src/app/user/profile/profile.service';
import { FormGroup } from '@angular/forms';
import { FormValidationService } from 'src/app/shared/services/form-validation.service';

@Component({
  selector: 'app-user-profile-1',
  templateUrl: 'profile.component.html',
  styleUrls: ['profile.component.scss']
})
export class Profile1Component {
  profileForm: FormGroup;

  submitted: boolean = false;

  constructor(
    public profileService: ProfileService,
    public validation: FormValidationService
  ) {
    this.profileService.initProfileForm();
    this.profileForm = this.profileService.profileForm;
  }

  saveProfile() {
    this.submitted = true;
    this.profileService.saveProfile();
  }
}
