<div id="content">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="login-register-form">
        <h1 class="mb-4 mt-4">{{ 'user.SIGN_IN' | translate }}</h1>
        <app-alert></app-alert>
        <form [formGroup]="loginForm" (ngSubmit)="signin()">
          <div class="form-group">
            <label for="email">{{ 'user.EMAIL_ADDRESS' | translate }}</label>
            <input type="email" class="form-control" formControlName="email" id="email" name="email"
            [placeholder]="'user.YOUR_EMAIL_ADDRESS' | translate"
            [class.is-invalid]="!(loginForm.controls.email.valid || ((loginForm.controls.email.pristine || loginForm.controls.email.untouched) && !submitted))"
            required>
            <small [hidden]="loginForm.controls.email.valid || ((loginForm.controls.email.pristine || loginForm.controls.email.untouched) && !submitted)">
              <span [hidden]="loginForm.controls.email.value.length > 0">{{ 'user.EMAIL_REQUIRED' | translate }}</span>
              <span [hidden]="loginForm.controls.email.value.length === 0">{{ 'user.EMAIL_INVALID' | translate }}</span>
            </small>
          </div>
          <div class="form-group">
            <label for="password">{{ 'user.PASSWORD' | translate }}</label>
            <input type="password" class="form-control" id="password" name="password" formControlName="password"
            [placeholder]="'user.YOUR_PASSWORD' | translate" required
            [class.is-invalid]="!(loginForm.controls.password.valid || ((loginForm.controls.password.pristine || loginForm.controls.password.untouched) && !submitted))">
            <small [hidden]="loginForm.controls.password.valid || ((loginForm.controls.password.pristine || loginForm.controls.password.untouched) && !submitted)">
              {{ 'user.PASSWORD_REQUIRED' | translate }}
            </small>
          </div>
          <div class="form-group">
            <button class="btn btn-primary text-white text-right" type="submit" [disabled]="loading">
              <i class="fa fa-spin fa-circle-notch mr-2" [hidden]="!loading"></i>
              {{ 'user.SIGN_IN' | translate }}
            </button>
          </div>
        </form>
        <hr>
        <div class="text-center">
          {{ 'user.NO_ACCOUNT' | translate }} <a [routerLink]="'/sessions/signup'">{{ 'user.SIGN_UP' | translate }}</a> - <a [routerLink]="'/sessions/reset-password'">{{ 'user.FORGOT_PASSWORD' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<template1-footer></template1-footer>