<div id="content">
  <div class="container">

    <div class="title1">{{ establishment.name }}</div>

    <div class="title2"></div>

    <div class="slider2-wrapper">
      <!--<div class="slider-for2" *ngFor="let photo of photos">-->
      <div class="slider-for2" *ngIf="photos && photos.length > 0">
        <div class="slider-item embed-responsive embed-responsive-4by3">
          <div class="embed-responsive-item" *ngFor="let currentPhoto of [photos[0]]" @animatePhoto [ngStyle]="{'background-image': 'url(' + getImageUrl(photos[0].path) + ')'}" style="background-size: cover; background-position: center">
          </div>
        </div>
      </div>
      <!-- Photo slider -->
      <div class="slider-nav2" *ngIf="photos && photos.length > 0">
        <div @items class="slider-item" *ngFor="let photo of photos; let i = index" style="cursor: pointer" (click)="clickOnImage(i)">
          <div class="slider-item-inner slick-active embed-responsive embed-responsive-4by3 slider-embed-nav"
               [ngClass]="{'active-slider' : photo == photos[0]}" *ngIf="i < 4">
            <img [src]="getImageUrl(photo.path) + '?thumbnail=true'" alt="" class="img-fluid embed-responsive-item">
          </div>
        </div>
      </div>
    </div>

    <div>
      <!-- Photo tags -->
      <div *ngIf="photos && photos.length > 0 && photos[0].phototags && photos[0].phototags.length > 0">
        <h5 *ngFor="let tag of photos[0].phototags" class="d-inline-block mr-2 mt-2">
          <span class="badge badge-warning text-white mt-2 p-2" style="border-radius: 10px; background-color: var(--main-color)!important;"><i class="far fa-table-tennis pr-1"></i> {{'tags.' + (tag | uppercase) | translate}}</span>
        </h5>
      </div>
    </div>

    <div class="details2-wrapper">
      <div class="details-wrapper clearfix pt-2" style="padding-right: 0!important; min-height: 300px">
        <div class="txt2">{{ establishment.name }}</div>
        <div class="txt3 mb-5">
          <p [innerHTML]="establishment.description | nl2br | safeHtml">
          </p>
          <div class="pt-3 text-uppercase pb-2">
            {{ 'listings.EQUIPMENTS' | translate }}
          </div>
          <app-equipments [selectedEquipments]="selectedEquipments" [type]="'establishment'" details="true"></app-equipments>
          <div class="pt-3 text-uppercase pb-2">
            {{ 'listings.RULES' | translate }}
          </div>
          <app-rules [selectedRules]="selectedRules" [type]="'establishment'" details="true"></app-rules>
        </div>
      </div>

      <ng-container>
        <div id="tabs2-2" *ngFor="let typo of typologies">
          <div [id]="typo.id" class="room-wide-wrapper">
            <div class="room-wide-inner">
              <div class="room-wide clearfix">
                <figure>
                  <img [src]="getImageUrl(typo.photos[0].path) + '?thumbnail=true'" alt="" class="mb-1"
                        *ngIf="typo.photos && typo.photos.length > 0">
                  <div class="mt-2 mb-2">
                    <div class="d-inline-block mr-2">
                      <i class="fas fa-user"></i> {{' x' + typo.capacity}}
                    </div>
                    <div class="d-inline-block mr-2" *ngIf="establishmentDetailsService.getNumberOfRooms(typo) > 0">
                      <i class="fas fa-bed"></i> {{' x' + establishmentDetailsService.getNumberOfRooms(typo)}}
                    </div>
                    <div class="d-inline-block mr-2" *ngIf="establishmentDetailsService.getNumberOfCommunSpace(typo) > 0">
                      <i class="fas fa-couch"></i> {{' x' + establishmentDetailsService.getNumberOfCommunSpace(typo)}}
                    </div>
                  </div>
                  <app-equipments [selectedEquipments]="typo.equipments" [type]="'typology'" [nowrap]="true"></app-equipments>
                </figure>
                <div class="caption">
                  <div class="d-flex align-items-stretch">
                    <div class="flex-grow-1">
                      <div class="txt2">{{typo.name | translateMap}}</div>
                    </div>
                    <div>
                      <div class="room-wide-price" *ngIf="typologiesWithPrice && typologiesWithPrice[typo.id]">
                        <div class="txt3">{{ (typologiesWithPrice[typo.id].minPrice * quantityTypo[typo.id]) | customCurrency }}</div>
                        <div class="txt4" *ngIf="searchService.criteria">{{ typologiesWithPrice[typo.id].minPriceNbGuests }} {{ ((typologiesWithPrice[typo.id].minPriceNbGuests > 1) ? 'reservations.TRAVELLERS' : 'reservations.TRAVELLER') | translate }} - {{ searchService.nbNights }} {{ ((searchService.nbNights > 1) ? 'reservations.NIGHTS' : 'reservations.NIGHT') | translate }}</div>
                      </div>
                      <div class="room-wide-price" *ngIf="!typologiesWithPrice || !typologiesWithPrice[typo.id]">
                        <div class="txt3">{{ 'book.NOT_AVAILABLE' | translate }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="txt5" [innerHTML]="typo.description | translateMap | nl2br | slice:0:400"></div>
                  <div class="room-wide-columns clearfix" [ngClass]="{'border-bottom': typologiesWithPrice && typologiesWithPrice[typo.id]}">
                    <div class="room-wide-left pb-2">
                      <div class="txt6 card-detail">
                        <a (click)="openVerticallyCentered(typo)" style="cursor: pointer; text-decoration: none;">
                          {{ 'reservations.MORE_DETAILS' | translate }}
                          <i class="fa fa-caret-right" aria-hidden="true" ></i>
                        </a>
                      </div>
                    </div>
                    <div class="room-wide-right">
                    </div>
                  </div>
                  <div class="room-wide-columns clearfix d-flex align-items-center pt-2" *ngIf="typologiesWithPrice && typologiesWithPrice[typo.id]">
                    <ng-container *ngIf="searchService.criteria">
                      <div class="number-accomodations-selection d-flex align-items-center">
                        <span class="text-right w-100 mr-3">{{ 'reservations.NUMBER_OF_ACCOMODATIONS' | translate }}</span>
                        <select class="form-control float-right" [(ngModel)]="quantityTypo[typo.id]" (ngModelChange)="changeQuantity(typo.id)" style="min-width: 70px;">
                          <option *ngFor="let typs of arrayOne(typologiesWithPrice[typo.id].availability); let i = index"
                                  [value]="i+1">{{i+1}}</option>
                        </select>
                      </div>
                      <div>
                        <div class="txt9" [ngClass]="{'added': establishmentDetailsService.isReserved(typo)}">
                          <button class="btn btn-primary w-100" (click)="establishmentDetailsService.toggleTypology(typo, quantityTypo[typo.id]); (establishmentDetailsService.reservedTypologies.length > 0) ? scrollTo('#recap') : ''">
                            {{ ((establishmentDetailsService.isReserved(typo)) ? 'reservations.ADDED' : 'reservations.ADD_TO_RESERVATION') | translate }}
                          </button>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!searchService.criteria">
                        <div class="room-wide-left d-flex align-items-center">
                        </div>
                        <div class="room-wide-right">
                          <div class="txt9">
                            <button class="btn btn-primary w-100" routerLink="/search">
                              Rechercher des dates
                            </button>
                          </div>
                        </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div id="google_map" *ngIf="establishment">
        <agm-map [latitude]="establishment.latitude" [longitude]="establishment.longitude" [zoom]="15" [scrollwheel]="null" style="height: 100%;">
          <agm-marker [latitude]="establishment.latitude" [longitude]="establishment.longitude"></agm-marker>
        </agm-map>
      </div>

      <div id="recap"></div>
      <div class="share-to-frends-wrapper clearfix" *ngIf="establishmentDetailsService.reservedTypologies && establishmentDetailsService.reservedTypologies.length > 0">
        <div class="room-wide-wrapper">
          <div class="room-wide-inner">
            <div class="room-wide clearfix">
              <div class="caption w-100">
                <div class="room-wide-columns clearfix">
                  <div class="room-wide-left mb-3">
                    <!--TODO: ajouter les données de séjour-->
                    <div class="txt2">{{ 'reservations.RESERVATION_DETAIL' | translate }}</div>
                  </div>

                  <table class="table table-striped">
                    <thead style="background-color: var(--main-color); color: white">
                      <tr>
                        <th scope="col">{{ 'reservations.ACCOMODATION' | translate | titlecase }}</th>
                        <th scope="col">{{ 'reservations.CAPACITY' | translate }}</th>
                        <th scope="col">{{ 'reservations.NUMBER_OF_ACCOMODATIONS' | translate }}</th>
                        <th scope="col">{{ 'reservations.PRICE' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let typoReserved of establishmentDetailsService.reservedTypologies">
                        <td scope="row"
                            style="font-size: 16px; vertical-align: middle;">
                          <a style="cursor: pointer!important;" (click)="scrollTo('#' + typoReserved.typology.id)"><u>{{ typoReserved.typology.name | translateMap }}</u></a>
                        </td>
                        <td style="vertical-align: middle; font-size: 16px">
                          <i class="fas fa-user mr-1" style="font-size: 20px"></i> {{(typoReserved.typology.capacity > 1) ? ' x' + typoReserved.typology.capacity : ''}}
                        </td>
                        <td style="vertical-align: middle">
                          <select class="form-control" [(ngModel)]="quantityTypo[typoReserved.id]" (ngModelChange)="changeQuantity(typoReserved.id)" *ngIf="typologiesWithPrice && typologiesWithPrice[typoReserved.id]" style="width: auto;">
                            <option *ngFor="let typs of arrayOne(typologiesWithPrice[typoReserved.id].availability); let i = index" [value]="i+1">{{ i+1 }}</option>
                          </select>
                        </td>
                        <td style="font-size: 16px; vertical-align: middle" *ngIf="typologiesWithPrice && typologiesWithPrice[typoReserved.id]">
                          <div class="d-flex">
                            <div class="flex-grow-1">{{ typologiesWithPrice[typoReserved.id].minPrice * quantityTypo[typoReserved.id] | customCurrency }}</div>
                            <div>
                              <span class="icon-action" [ngbTooltip]="'reservations.REMOVE_ACCOMODATION' | translate" (click)="establishmentDetailsService.toggleTypology(typoReserved)"><i class="far fa-times"></i></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" colspan="3" style="font-size: 20px; vertical-align: middle">{{ 'reservations.TOTAL' | translate }}</th>
                        <td colspan="1" style="font-size: 16px; vertical-align: middle">
                          {{establishmentDetailsService.getTotalPrices(typologiesWithPrice, quantityTypo) | customCurrency}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="room-wide-columns clearfix d-flex align-items-center justify-content-end pt-2">
                  <div class="room-wide-right">
                    <div class="txt9 added">
                      <button class="btn btn-primary w-100" (click)="establishmentDetailsService.book()">
                        {{ ((auth.user) ? 'book.BOOK' : 'book.SIGN_IN_TO_CONTINUE') | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="comment-block2 clearfix">
        <figure>
          <img src="http://via.placeholder.com/92x92" alt="" class="img-responsive">
        </figure>
        <div class="caption">
          <div class="txt1">Review by <a href="#">Katherine</a></div>
          <div class="txt2">
            It is a long established fact that a reader will be distracted by the readable content of a page
            when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters.
          </div>
        </div>
      </div> -->


      <!-- <div class="title3">ADD REVIEW</div>

      <div id="note"></div>
      <div id="fields">
        <form id="ajax-contact-form" class="form-horizontal clearfix" action="javascript:;">
          <div class="row">
            <div class="col-md-6">

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="inputName2">Name <span>*</span></label>
                    <input type="text" class="form-control" id="inputName2" name="name" value=""
                           placeholder="">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="inputEmail">Email <span>*</span></label>
                    <input type="text" class="form-control" id="inputEmail" name="email" value=""
                           placeholder="">
                  </div>
                </div>
              </div>


            </div>

            <div class="col-md-6">

              <div class="form-group">
                <label for="inputMessage2">Comments <span>*</span></label>
                <textarea class="form-control" rows="5" id="inputMessage2" name="content"
                          placeholder=""></textarea>
              </div>

            </div>

          </div>

          <button type="submit" class="btn-default btn-cf-submit">send your message</button>
        </form>
      </div> -->


    </div>
  </div>
</div>

<template1-footer></template1-footer>