<div class="modal-header">
    <h4 class="modal-title">Annulation de la réservation</h4>
    <button type="button" class="close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="loading" class="text-center">
      <app-custom-loader></app-custom-loader>
    </div>
    <div *ngIf="!loading">
        <app-alert></app-alert>
        <div>
            <p>{{ 'reservations.CANCEL_RESERVATION_REFUND_INFORMATION' | translate: { amount: (policies.totalRefundedAmount | customCurrency)} }}</p>
            <p>
                <button class="btn btn-danger" (click)="cancelReservation()" [disabled]="cancellationLoading"><span class="mr-2" *ngIf="cancellationLoading"><i class="far fa-spin fa-circle-notch"></i></span> {{ 'reservations.CANCEL_RESERVATION' | translate }}</button>
            </p>
        </div>
        <table class="table table-striped">
            <tr>
                <th>{{ 'listings.establishments.ESTABLISHMENT' | translate }}</th>
                <th>{{ 'calendar.CANCELLATION_POLICY' | translate }}</th>
                <th>{{ 'reservations.REFUND_AMOUNT' | translate }}</th>
            </tr>
            <tr *ngFor="let subreservation of policies.subreservations">
                <td>{{ subreservation.name | translateMap }}</td>
                <td>{{ 'policies.descriptions.' + subreservation.policyName.toLocaleUpperCase() | translate }}</td>
                <td>{{ subreservation.refundedAmount | customCurrency }}</td>
            </tr>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close()">
        Fermer
    </button>
</div>