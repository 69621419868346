<div class="home" [ngStyle]="homeStyle">

    <div class="home-text">
        <div class="home-title">
            <span>{{home.siteService.settings.hometext | translateMap}}</span>
        </div>
        <div class="home-description">
            {{home.siteService.settings.homeDescription | translateMap}}
        </div>
    </div>
    <div id="home-inner" class="home-inner">
    </div>
    <div class="booking-wrapper">
        <app-search-1></app-search-1>
    </div>
</div>

<div class="container home-container" *ngIf="(home.siteService.settings.homeContent | translateMap).length > 0">
    <div class="page-content" [innerHTML]="(home.siteService.settings.homeContent | translateMap) | safeHtml"></div>
</div>

<template1-footer></template1-footer>