<div id="content">
    <div class="container">
        <div *ngIf="reservation === null" class="text-center">
            <app-custom-loader></app-custom-loader>
        </div>
        <div class="row" *ngIf="reservation">
            <div class="col-md-4">
                <h2><span *ngIf="reservation.cancelled" class="badge badge-danger mr-4" style="font-size: 1.5rem;">{{ 'book.CANCELLED' | translate }}</span>{{ reservation.establishment.name | translateMap }}</h2>
                <div style="font-size: 1.2em;">
                    <strong>Code de réservation : </strong> {{ reservation.code }}
                </div>
                <div class="row mt-4" app-reservation-arrival-departure [reservation]="reservation">
                </div>
                <div style="font-size: 1.2em;" class="mt-2">
                    <strong>{{ 'listings.fees.TOTAL_PRICE' | translate }}</strong>&nbsp;&nbsp;{{ reservation.nbNights }} {{ (reservation.nbNights > 1 ? 'reservations.NIGHTS' : 'reservations.NIGHT' ) | translate }}, {{ reservation.subreservations.length }} {{ (reservation.subreservations.length > 1 ? 'reservations.ACCOMODATIONS' : 'reservations.ACCOMODATION' ) | translate }}<br>
                    <span style="font-size: 1.5em;">{{ reservation.totalPrice | customCurrency }}</span>
                </div>
                <div>
                    <a class="nocolor btn btn-primary mt-2" [routerLink]="'/user/reservations/' + reservation.code">{{ 'reservations.SHOW_RESERVATION' | translate }}</a>
                </div>
            </div>
            <div class="col-md-8">
                <div class="messages-container">
                    <div class="conversation" id="conversation">
                        <div *ngFor="let message of messages" class="message" [class.outgoing]="message.fromGuest" [class.incoming]="!message.fromGuest">
                            <div class="message-image">
                                <img [src]="message.fromGuest ? reservation.guest.photoPath : '/assets/images/no-avatar.png'">
                            </div>
                            <div class="message-content">
                                <div class="message-block">
                                    <span [innerHTML]="message.message | nl2br"></span>
                                </div>
                                <div class="message-date">
                                    {{ message.date |amDateFormat:('common.MOMENT_DATETIME' | translate) }}
                                </div>
                            </div>
                            <div class="grow"></div>
                        </div>
                    </div>
                    <div class="reply">

                        <div class="textarea-container">
                                <textarea class="form-control" rows="2" [(ngModel)]="form.message" id="message-textarea" (keyup)="autoExpand($event)" (keydown.meta.Enter)="sendMessage()"></textarea>
                            </div>
        
                            <div class="btn-send-container">
                                <button class="btn btn-primary btn-send" (click)="sendMessage()" [disabled]="reservationService.messagesLoading || form.message.trim().length === 0">
                                    <span class="mr-2"><i class="far" [ngClass]="{ 'fa-paper-plane': !sending, 'fa-spin fa-circle-notch': sending }"></i></span> {{ 'common.SEND' | translate }}
                                </button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>
<template1-footer></template1-footer>