import { AuthApiService } from '../auth-api.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth-api.service";
var LoginService = /** @class */ (function () {
    function LoginService(authApiService) {
        this.authApiService = authApiService;
    }
    LoginService.prototype.signin = function (credentials) {
        return this.authApiService.signin(credentials);
    };
    LoginService.prototype.resetPassword = function (email) {
        return this.authApiService.resetPassword(email);
    };
    LoginService.prototype.checkResetToken = function (token) {
        return this.authApiService.checkResetToken(token);
    };
    LoginService.prototype.changePassword = function (token, password) {
        return this.authApiService.changePassword(token, password);
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.AuthApiService)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
