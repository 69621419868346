import * as i0 from "@angular/core";
import * as i1 from "@ng-toolkit/universal";
var LocalStoreService = /** @class */ (function () {
    function LocalStoreService(window) {
        this.window = window;
        this.ls = window.localStorage;
    }
    LocalStoreService.prototype.setItem = function (key, value) {
        value = JSON.stringify(value);
        this.ls.setItem(key, value);
        return true;
    };
    LocalStoreService.prototype.getItem = function (key) {
        var value = this.ls.getItem(key);
        try {
            return JSON.parse(value);
        }
        catch (e) {
            // console.log(e)
            return null;
        }
    };
    LocalStoreService.prototype.clear = function () {
        this.ls.clear();
    };
    LocalStoreService.ngInjectableDef = i0.defineInjectable({ factory: function LocalStoreService_Factory() { return new LocalStoreService(i0.inject(i1.WINDOW)); }, token: LocalStoreService, providedIn: "root" });
    return LocalStoreService;
}());
export { LocalStoreService };
