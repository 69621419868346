var RoomCompositionComponent = /** @class */ (function () {
    function RoomCompositionComponent() {
    }
    Object.defineProperty(RoomCompositionComponent.prototype, "rooms", {
        get: function () {
            return [this.composition.filter(function (room) { return !room.isCommonSpace; }), this.composition.filter(function (room) { return room.isCommonSpace; })];
        },
        enumerable: true,
        configurable: true
    });
    return RoomCompositionComponent;
}());
export { RoomCompositionComponent };
