import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiUrlService } from '../shared/services/api-url.service';
import { AuthService } from '../shared/services/auth.service';

@Injectable()
export class AuthApiService {
  constructor(private http: HttpClient, private apiUrlService: ApiUrlService, private auth: AuthService,
    private router: Router) {
  }

  signin(credentials) {
    return this.auth.signin(credentials)
      .then(() => {
        if (this.auth.redirectAfterLogin) {
          this.router.navigateByUrl(this.auth.redirectAfterLogin);
          this.auth.redirectAfterLogin = null;
        } else {
          this.router.navigateByUrl('/');
        }
      });
  }

  register(registerFormular) {
    return this.http.post(this.apiUrlService.siteBaseUrl() + 'auth/guest/register', registerFormular).toPromise()
      .then(user => {
        if (user) {
          const credentials = { email: registerFormular.email, password: registerFormular.password };
          return this.signin(credentials);
        }

        throw new Error('Register failed');
      });
  }

  resetPassword(email: string) {
    return this.http.post(this.apiUrlService.siteBaseUrl() + 'auth/guest/reset-password', {
      email
    })
      .toPromise();
  }

  checkResetToken(token: string) {
    return this.http.post(this.apiUrlService.siteBaseUrl() + 'auth/guest/check-reset-token', {
      token
    })
      .toPromise();
  }

  changePassword(token: string, password: string) {
    return this.http.post(this.apiUrlService.siteBaseUrl() + 'auth/guest/change-password', {
      token,
      password
    })
      .toPromise();
  }

  getLinkPhoto(path: string) : string {
    return this.apiUrlService.siteBaseUrl() + path;
  }
}
