import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef } from '@angular/core';
import { EquipmentService } from 'src/app/shared/services/equipment.service';
var AppEquipmentsComponent = /** @class */ (function () {
    function AppEquipmentsComponent(equipmentService) {
        this.equipmentService = equipmentService;
        this.nowrap = false;
        this.displayLeftArrow = false;
        this.displayRightArrow = false;
    }
    Object.defineProperty(AppEquipmentsComponent.prototype, "equipmentsListContent", {
        set: function (content) {
            if (content) {
                this.equipmentsListRef = content;
            }
        },
        enumerable: true,
        configurable: true
    });
    AppEquipmentsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.onListScroll();
        });
    };
    AppEquipmentsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.equipmentService.initEquipmentsAndRules();
                return [2 /*return*/, this.equipmentService.loadEquipments
                        .toPromise()
                        .then(function (equipments) {
                        if (_this.type === 'establishment') {
                            _this.equipments = equipments.establishmentEquipments;
                        }
                        else if (_this.type === 'typology') {
                            _this.equipments = equipments.typologyEquipments;
                        }
                        _this.displayedEquipments = _this.selectedEquipments.filter(function (item) { return _this.equipments.findIndex(function (equip) { return equip.slug === item; }) !== -1; });
                    })];
            });
        });
    };
    AppEquipmentsComponent.prototype.getClassForEquipment = function (equip) {
        var elem = this.equipments.find(function (e) { return e.slug === equip; });
        return elem.icon;
    };
    AppEquipmentsComponent.prototype.scrollTo = function (direction) {
        if (!this.equipmentsListRef)
            return;
        var el = this.equipmentsListRef.nativeElement;
        el.scrollBy({
            top: 0,
            left: (direction === 'left' ? -1 : 1) * el.offsetWidth * 0.8,
            behavior: 'smooth',
        });
    };
    AppEquipmentsComponent.prototype.onListScroll = function () {
        if (!this.equipmentsListRef)
            return;
        var el = this.equipmentsListRef.nativeElement;
        this.displayLeftArrow = el.scrollLeft > 0;
        this.displayRightArrow = el.scrollLeft + el.offsetWidth < el.scrollWidth;
    };
    return AppEquipmentsComponent;
}());
export { AppEquipmentsComponent };
