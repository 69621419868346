<div id="content">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="login-register-form" *ngIf="!isPasswordForm">
        <h1 class="mb-4 mt-4">{{ 'user.RESET_PASSWORD' | translate }}</h1>
        <form [formGroup]="loginForm" (ngSubmit)="submit()" *ngIf="!loginFormCompleted">
          <p>
            {{ 'user.RESET_PASSWORD_TEXT' | translate }}
          </p>
          <app-alert></app-alert>
          <div class="form-group">
            <label for="email">{{ 'user.EMAIL_ADDRESS' | translate }}</label>
            <input type="email" class="form-control" formControlName="email" id="email" name="email"
              [placeholder]="'user.YOUR_EMAIL_ADDRESS' | translate"
              [class.is-invalid]="validation.hasError(loginForm.controls.email, submitted) || emailError" required
              (ngModelChange)="mailError = false;">
            <small [hidden]="!validation.hasError(loginForm.controls.email, submitted)">
              <span [hidden]="loginForm.controls.email.value.length > 0">{{ 'user.EMAIL_REQUIRED' | translate }}</span>
              <span [hidden]="loginForm.controls.email.value.length === 0">{{ 'user.EMAIL_INVALID' | translate }}</span>
            </small>
            <small [hidden]="!emailError">
              {{ 'user.USER_NOT_FOUND' | translate }}
            </small>
          </div>
          <div class="form-group">
            <button class="btn btn-primary text-white text-right" type="submit" [disabled]="loading">
              <i class="fa fa-spin fa-circle-notch mr-2" [hidden]="!loading"></i>
              {{ 'user.RESET_PASSWORD' | translate }}
            </button>
          </div>
          <hr>
          <div class="text-center">
            <a [routerLink]="'/sessions/signup'">{{ 'user.SIGN_UP' | translate }}</a> -
            <a [routerLink]="'/sessions/signin'">{{ 'user.SIGN_IN' | translate }}</a>
          </div>
        </form>
        <div *ngIf="loginFormCompleted">
          <p>
            {{ 'user.RESET_PASSWORD_DONE' | translate }}
          </p>
          <p>
            <button class="btn btn-primary text-white" routerLink="/sessions/signin">
              {{ 'user.SIGN_IN' | translate }}
            </button>
          </p>
        </div>
      </div>

      <div class="login-register-form" *ngIf="isPasswordForm">
        <h1 class="mb-4 mt-4">{{ 'user.RESET_PASSWORD' | translate }}</h1>
        <div *ngIf="tokenError">
          <p>
            {{ 'user.RESET_TOKEN_INVALID' | translate }}
          </p>
          <p>
            <button class="btn btn-primary text-white" routerLink="/sessions/reset-password">
              {{ 'user.RESET_PASSWORD' | translate }}
            </button>
          </p>
        </div>
        <div *ngIf="!tokenError">
          <div *ngIf="!passwordFormCompleted">

            <p>
              {{ 'user.RESET_TOKEN_TEXT' | translate }}
            </p>
            <form [formGroup]="resetPasswordForm" (ngSubmit)="changePassword()">
              <div class="form-group">
                <label for="password">{{ 'user.PASSWORD' | translate }}</label>
                <input type="password" class="form-control" id="password" name="password" formControlName="password"
                  [placeholder]="'user.YOUR_PASSWORD' | translate" required
                  [class.is-invalid]="validation.hasError(resetPasswordForm.controls.password, passwordSubmitted)">
                <small [hidden]="!validation.hasError(resetPasswordForm.controls.password, passwordSubmitted)">
                  {{ 'user.PASSWORD_REQUIRED' | translate }}
                </small>
              </div>
              <div class="form-group">
                <label for="password">{{ 'user.CONFIRMATION_PASSWORD' | translate }}</label>
                <input type="password" class="form-control" id="passwordConfirmation" name="passwordConfirmation"
                  formControlName="passwordConfirmation" [placeholder]="'user.CONFIRMATION_PASSWORD' | translate"
                  required
                  [class.is-invalid]="validation.hasError(resetPasswordForm.controls.passwordConfirmation, passwordSubmitted)">
                <small
                  [hidden]="!validation.hasError(resetPasswordForm.controls.passwordConfirmation, passwordSubmitted)">
                  {{ 'user.CONFIRMATION_PASSWORD_ERROR' | translate }}
                </small>
              </div>
              <div class="form-group">
                <button class="btn btn-primary text-white text-right" type="submit" [disabled]="passwordLoading">
                  <i class="fa fa-spin fa-circle-notch mr-2" [hidden]="!passwordLoading"></i>
                  {{ 'user.CHANGE_PASSWORD' | translate }}
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="passwordFormCompleted">
            <p>
              {{ 'user.PASSWORD_CHANGED' | translate }}
            </p>
            <p>
              <button class="btn btn-primary text-white" routerLink="/sessions/signin">
                {{ 'user.SIGN_IN' | translate }}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<template1-footer></template1-footer>
