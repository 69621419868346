import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  trigger, transition, style, animate, query, group
} from '@angular/animations';
import { SiteService } from 'src/app/shared/services/site.service';
import { AuthService } from '../../../../services/auth.service';
import { ReservationsBookService } from '../../../../../reservations/reservations-book/reservations-book.service';
import { Establishment } from '../../../../models/establishment.model';
import { Photo } from '../../../../models/photo';


@Component({
  selector: 'app-reservations-book-1',
  templateUrl: 'reservations-book.component.html',
  styleUrls: ['./reservations-book.component.scss'],
  animations: [
    trigger('fader', [
      transition('* <=> *', [
        query(':enter', [
          style({ opacity: 0 }),
          animate('.2s', style({ opacity: 1 }))
        ], { optional: true }),
        query(':leave', [
          style({ opacity: 1 }),
          animate('.2s', style({ opacity: 0 }))
        ], { optional: true })
      ])
    ])
  ]
})
export class ReservationsBook1Component implements OnInit {

  selectedPaymentMethod = '';

  establishment: Establishment;

  selectedRules: string[];

  photos: Photo[];

  config = {
    suppressScrollX: true,
  };

  constructor(
    public bookService: ReservationsBookService,
    private router: Router,
    private auth: AuthService,
    public site: SiteService) {}

  paymentMethodIsAvailable(paymentMethod: string) {
    return typeof this.establishment.paymentMethodConfigurations[paymentMethod] != 'undefined';
  }


  ngOnInit() {
    this.bookService.initReservationBooking();
    this.bookService.indexState = 0;

    this.bookService.establishment.subscribe(establishment => {
      if (Object.keys(establishment).length === 0) {
        return;
      }
      
      this.establishment = establishment;
      

      // Select the first payment method available
      for (let paymentMethod in establishment.paymentMethodConfigurations) {
        this.selectedPaymentMethod = paymentMethod;
        break;
      }
    });

    this.bookService.animatedIndexState.subscribe(step => {
      
    });


    this.bookService.selectedRules.subscribe(rules => {
      this.selectedRules = rules;
    });

    this.bookService.photos.subscribe(photos => {
      this.photos = photos;
    });
  }

  getImageUrl(path) {
    return this.bookService.getImageUrl(path);
  }

  arrayOne(n: number) {
    const strValue = n + '';
    return Array(parseInt(strValue, 10));
  }
}
