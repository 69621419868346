import { AuthApiService } from '../auth-api.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth-api.service";
var RegisterService = /** @class */ (function () {
    function RegisterService(authApiService) {
        this.authApiService = authApiService;
    }
    RegisterService.prototype.register = function (newUser) {
        return this.authApiService.register(newUser);
    };
    RegisterService.ngInjectableDef = i0.defineInjectable({ factory: function RegisterService_Factory() { return new RegisterService(i0.inject(i1.AuthApiService)); }, token: RegisterService, providedIn: "root" });
    return RegisterService;
}());
export { RegisterService };
