import { Component, OnInit } from '@angular/core';
import { ReservationService } from 'src/app/user/reservation/reservation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ListingsApiService } from 'src/app/establishments/establishments-api.service';
import { SiteService } from 'src/app/shared/services/site.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CancellationModalComponent } from 'src/app/shared/components/cancellation-modal/cancellation-modal.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AlertType } from 'src/app/shared/models/alert-type.model';

@Component({
  selector: 'app-user-reservation-1',
  templateUrl: 'reservation.component.html',
  styleUrls: ['reservation.component.scss']
})
export class UserReservation1Component implements OnInit {
  constructor(public reservationService: ReservationService,
    public listingsApiService: ListingsApiService,
    private route: ActivatedRoute,
    private router: Router,
    public site: SiteService,
    private modalService: NgbModal) {

  }

  ngOnInit() {
  }

  get reservation() {
    return this.reservationService.reservation;
  }

  openCancellationModal() {
    const modalRef = this.modalService.open(CancellationModalComponent, { size: 'lg' });
    modalRef.componentInstance.reservationCode = this.reservationService.reservation.code;
  }
}
