import {
  AfterViewInit,
  Component, ElementRef, Input, ViewChild
} from '@angular/core';
import { EquipmentService } from 'src/app/shared/services/equipment.service';
import { Equipment } from '../../../../models/equipment.model';

@Component({
  selector: 'app-equipments',
  templateUrl: 'app-equiments.component.html',
  styleUrls: ['../establishments.scss'],
})
export class AppEquipmentsComponent implements AfterViewInit {
  equipments: Equipment[];

  @Input() selectedEquipments: string[];

  @Input() type: string;

  @Input() details: boolean;

  @Input() nowrap: boolean = false;

  private equipmentsListRef: ElementRef;

  @ViewChild('equipmentsList') set equipmentsListContent(content: ElementRef) {
    if (content) {
      this.equipmentsListRef = content;
    }
  }

  public displayedEquipments: string[];

  public displayLeftArrow: boolean = false;

  public displayRightArrow: boolean = false;

  constructor(
    private equipmentService: EquipmentService
  ) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.onListScroll();
    });
  }

  async ngOnInit() {
    this.equipmentService.initEquipmentsAndRules();
    return this.equipmentService.loadEquipments
      .toPromise()
      .then(equipments => {
        if (this.type === 'establishment') {
          this.equipments = equipments.establishmentEquipments;
        } else if (this.type === 'typology') {
          this.equipments = equipments.typologyEquipments;
        }
        this.displayedEquipments = this.selectedEquipments.filter(item => this.equipments.findIndex(equip => equip.slug === item) !== -1);
      });
  }

  getClassForEquipment(equip) {
    const elem = this.equipments.find(e => e.slug === equip);
    return elem.icon;
  }

  scrollTo(direction: 'left' | 'right') {
    if (!this.equipmentsListRef) return;
    const el = this.equipmentsListRef.nativeElement;
    el.scrollBy({
      top: 0,
      left: (direction === 'left' ? -1 : 1) * el.offsetWidth * 0.8,
      behavior: 'smooth',
    });
  }

  onListScroll() {
    if (!this.equipmentsListRef) return;
    const el = this.equipmentsListRef.nativeElement;
    this.displayLeftArrow = el.scrollLeft > 0;
    this.displayRightArrow = el.scrollLeft + el.offsetWidth < el.scrollWidth;
  }
}
