import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/auth/login/login.service';
import { FormValidationService } from 'src/app/shared/services/form-validation.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AlertType } from 'src/app/shared/models/alert-type.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password-1',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPassword1Component implements OnInit {
  public submitted: boolean = false;

  public passwordSubmitted: boolean = false;

  public loading: boolean = false;

  public passwordLoading: boolean = false;

  public pageLoading: boolean = false;

  public isPasswordForm: boolean = false;

  public loginForm: FormGroup;

  public resetPasswordForm: FormGroup;

  public loginFormCompleted: boolean = false;

  public passwordFormCompleted: boolean = false;

  public emailError: boolean = false;

  public tokenError: boolean = false;

  public token: string;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public validation: FormValidationService,
    public alert: AlertService,
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      passwordConfirmation: ['', [Validators.required, () => this.checkPasswords()]]
    });
  }

  checkPasswords() {
    if (!this.resetPasswordForm) return null;
    const pass = this.resetPasswordForm.controls.password.value;
    const confirmPass = this.resetPasswordForm.controls.passwordConfirmation.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  ngOnInit() {
    const { token } = this.route.snapshot.params;
    if (token) {
      this.isPasswordForm = true;
      this.pageLoading = true;
      this.checkResetToken(token)
        .then(() => {
          this.token = token;
        })
        .finally(() => {
          this.pageLoading = false;
        });
    }
  }


  checkResetToken(token: string) {
    return this.loginService.checkResetToken(token)
      .then(() => {
      })
      .catch(() => {
        this.tokenError = true;
      })
      .finally(() => {
        this.pageLoading = false;
      });
  }

  submit() {
    this.emailError = false;
    this.submitted = true;
    if (!this.loginForm.valid) {
      return;
    }
    this.loading = true;
    this.loginService.resetPassword(this.loginForm.value.email)
      .then(() => {
        this.loginFormCompleted = true;
      })
      .catch(err => {
        if (err.status === 400) {
          this.emailError = true;
        } else {
          this.alert.set(AlertType.DANGER, this.translate.instant('user.MAIL_ERROR'));
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  changePassword() {
    this.passwordSubmitted = true;
    if (!this.resetPasswordForm.valid) return;
    this.passwordLoading = true;
    this.loginService.changePassword(this.token, this.resetPasswordForm.value.password)
      .then(() => {
        this.passwordFormCompleted = true;
      })
      .finally(() => {
        this.passwordLoading = false;
      });
  }
}
