<div id="content">
  <div class="container">
    <div class="row justify-content-center">
      <div class="login-register-form">
        <form [formGroup]="registerForm" (ngSubmit)="register()">
          <h1>{{ 'user.SIGN_UP' | translate }}</h1>
          <app-alert></app-alert>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="firstName">{{ 'user.FIRST_NAME' | translate }}</label>
                <input type="text" class="form-control" formControlName="firstName" id="firstName" name="firstName"
                [placeholder]="'user.YOUR_FIRST_NAME' | translate"
                [class.is-invalid]="validation.hasError(registerForm.controls.firstName, submitted)"
                required>
                <small [hidden]="!validation.hasError(registerForm.controls.firstName, submitted)">
                  {{ 'user.LAST_NAME_REQUIRED' | translate }}
                </small>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="lastName">{{ 'user.LAST_NAME' | translate }}</label>
                <input type="text" class="form-control" formControlName="lastName" id="lastName" name="lastName"
                [placeholder]="'user.YOUR_LAST_NAME' | translate"
                [class.is-invalid]="validation.hasError(registerForm.controls.lastName, submitted)"
                required>
                <small [hidden]="!validation.hasError(registerForm.controls.lastName, submitted)">
                  {{ 'user.LAST_NAME_REQUIRED' | translate }}
                </small>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="email">{{ 'user.EMAIL_ADDRESS' | translate }}</label>
            <input type="email" class="form-control" formControlName="email" id="email" name="email"
            [placeholder]="'user.YOUR_EMAIL_ADDRESS' | translate"
            [class.is-invalid]="validation.hasError(registerForm.controls.email, submitted)"
            required>
            <small [hidden]="!validation.hasError(registerForm.controls.email, submitted)">
              <span [hidden]="registerForm.controls.email.value.length > 0">{{ 'user.EMAIL_REQUIRED' | translate }}</span>
              <span [hidden]="registerForm.controls.email.value.length === 0">{{ 'user.EMAIL_INVALID' | translate }}</span>
            </small>
          </div>
          <div class="form-group">
            <label for="phone">{{ 'user.PHONE_NUMBER' | translate }}</label>
            <input type="tel" class="form-control" formControlName="phone" id="phone" name="phone"
            [placeholder]="'user.YOUR_PHONE_NUMBER' | translate"
            [class.is-invalid]="validation.hasError(registerForm.controls.phone, submitted) || hasPhoneError"
            ng2TelInput
            [ng2TelInputOptions]=""
            (ng2TelOutput)="getNumber($event)"
            (hasError)="onPhoneChanged($event)"
            required>
            <small [hidden]="!validation.hasError(registerForm.controls.phone, submitted) && !hasPhoneError">
              {{ 'user.PHONE_NUMBER_INVALID' | translate }}
            </small>
          </div>
          <div class="form-group">
            <label for="password">{{ 'user.PASSWORD' | translate }}</label>
            <input type="password" class="form-control" id="password" name="password" formControlName="password" [placeholder]="'user.YOUR_PASSWORD' | translate" required
            [class.is-invalid]="validation.hasError(registerForm.controls.password, submitted)">
            <small [hidden]="!validation.hasError(registerForm.controls.password, submitted)">
              {{ 'user.PASSWORD_REQUIRED' | translate }}
            </small>
          </div>
          <div class="form-group text-right">
            <button class="btn btn-primary text-white" type="submit" [disabled]="loading">
              <i class="fa fa-spin fa-circle-notch mr-2" [hidden]="!loading"></i>
              {{ 'user.SIGN_UP' | translate }}
            </button>
          </div>
          <div class="form-group">
            <hr>
            <div class="text-center">
              {{ 'user.ALREADY_HAVE_AN_ACCOUNT' | translate }} <a routerLink="/sessions/signin">{{ 'user.SIGN_IN' | translate }}</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<template1-footer></template1-footer>