import { Component } from '@angular/core';
import { mainFader } from 'src/app/route-animations';

@Component({
  selector: 'main-layout-1',
  templateUrl: 'main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  animations: [
    mainFader
  ]
})
export class MainLayout1Component {
  prepareRoute(outlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
