import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LocalStoreService } from './local-store.service';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./local-store.service";
import * as i3 from "./auth.service";
var LanguageService = /** @class */ (function () {
    function LanguageService(translate, localStore, auth) {
        this.translate = translate;
        this.localStore = localStore;
        this.auth = auth;
        this._availableLangs = ['fr', 'en'];
        this.localePriority = ['en', 'fr'];
        this.defaultLang = 'en';
        this.availableLangNames = { fr: 'Français', en: 'English' };
    }
    Object.defineProperty(LanguageService.prototype, "currentLang", {
        get: function () {
            return this._currentLang;
        },
        set: function (lang) {
            if (this.availableLangs.indexOf(lang) === -1) {
                return;
            }
            this.localStore.setItem('lang', lang);
            this._currentLang = lang;
            this.translate.use(this.currentLang);
            moment.locale(this.currentLang);
            // Save the language if the user is connected
            if (this.auth.authenticated) {
                this.auth.setUserLang(lang);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LanguageService.prototype, "availableLangs", {
        get: function () {
            return this._availableLangs;
        },
        enumerable: true,
        configurable: true
    });
    LanguageService.prototype.initLanguages = function () {
        this.translate.addLangs(this.availableLangs);
        this.translate.setDefaultLang(this.defaultLang);
        var storedLang = this.localStore.getItem('lang');
        if (storedLang !== null) {
            this.currentLang = storedLang;
        }
        else {
            var browserLang = this.translate.getBrowserLang();
            if (this.availableLangs.indexOf(browserLang) !== -1) {
                this.currentLang = browserLang;
            }
            else {
                this.currentLang = this.defaultLang;
            }
        }
    };
    LanguageService.prototype.getTranslationFromMap = function (translationMap) {
        if (!translationMap)
            return '';
        var lang = this.currentLang;
        if (lang != null && lang.length > 0) {
            if (translationMap[lang] != null && translationMap[lang].length > 0)
                return translationMap[lang];
        }
        for (var _i = 0, _a = this.localePriority; _i < _a.length; _i++) {
            var locale = _a[_i];
            if (translationMap[locale] != null && translationMap[locale].length > 0) {
                return translationMap[locale];
            }
        }
        return '';
    };
    LanguageService.ngInjectableDef = i0.defineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.inject(i1.TranslateService), i0.inject(i2.LocalStoreService), i0.inject(i3.AuthService)); }, token: LanguageService, providedIn: "root" });
    return LanguageService;
}());
export { LanguageService };
