<div id="content">
    <div class="container" *ngIf="page">
        <!-- <div class="container">
            <div class="title1">{{ page.name | translateMap }}</div>
            <div class="title2"></div>
        </div> -->
        <div class="page-content" [innerHTML]="(page.content | translateMap) | safeHtml"></div>
        <div class="clearfix"></div>
    </div>
</div>
<template1-footer></template1-footer>