import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { loadStripe } from '@stripe/stripe-js';
import { ReservationsApiService } from 'src/app/reservations/reservations-api.service';
import { ReservationsBookService } from 'src/app/reservations/reservations-book/reservations-book.service';
import { AlertType } from 'src/app/shared/models/alert-type.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ApiUrlService } from 'src/app/shared/services/api-url.service';
var StripeComponent = /** @class */ (function () {
    function StripeComponent(apiUrlService, bookService, alert, reservationApi, translate) {
        var _this = this;
        this.apiUrlService = apiUrlService;
        this.bookService = bookService;
        this.alert = alert;
        this.reservationApi = reservationApi;
        this.translate = translate;
        this.amount = 0;
        this.loading = true;
        this.paying = false;
        this._stripe = null;
        this._stripeElements = null;
        this._stripePaymentIntentHash = '';
        this.stripeErrorMessage = '';
        this.initStripe = function (stripePaymentIntentClientSecret) {
            console.log(_this.publicKey);
            loadStripe(_this.publicKey).then(function (stripe) {
                _this._stripe = stripe;
                var stripeAppearance = {
                    theme: 'stripe'
                };
                var stripeOptions = { /* options */};
                _this._stripeElements = stripe.elements({
                    locale: _this.translate.currentLang,
                    clientSecret: stripePaymentIntentClientSecret,
                    appearance: stripeAppearance
                });
                var paymentElement = _this._stripeElements.create('payment', stripeOptions);
                paymentElement.mount('#stripe-payment-element');
                paymentElement.on('ready', function () {
                    // document.getElementById('ticket-order').getElementsByClassName('pay-button')[0].disabled = false;
                });
            });
        };
    }
    StripeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.reservationApi.getStripePaymentIntent(this.bookService.bookedReservation)
            .then(function (response) {
            if (!response.success) {
                throw new Error();
            }
            console.log('responseeeee', response);
            _this._stripePaymentIntentHash = response.stripePaymentIntentHash;
            _this.initStripe(response.stripePaymentIntentClientSecret);
        })
            .catch(function (err) {
            _this.alert.set(AlertType.DANGER, _this.translate.instant('book.ERROR'));
            console.error(err);
        })
            .finally(function () {
            _this.loading = false;
        });
    };
    StripeComponent.prototype.pay = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var returnUrl, error;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('dodod');
                        this.paying = true;
                        event.preventDefault();
                        returnUrl = this.apiUrlService.siteBaseUrl(true) + 'payment/stripe-payment-intent-confirm/' + encodeURIComponent(this._stripePaymentIntentHash);
                        console.log('returnUrl', returnUrl);
                        return [4 /*yield*/, this._stripe.confirmPayment({
                                //`Elements` instance that was used to create the Payment Element
                                elements: this._stripeElements,
                                confirmParams: {
                                    return_url: returnUrl,
                                },
                            })];
                    case 1:
                        error = (_a.sent()).error;
                        this.paying = false;
                        if (error) {
                            // This point will only be reached if there is an immediate error when
                            // confirming the payment. Show error to your customer (for example, payment
                            // details incomplete)
                            this.stripeErrorMessage = error.message;
                        }
                        else {
                            // Your customer will be redirected to your `return_url`. For some payment
                            // methods like iDEAL, your customer will be redirected to an intermediate
                            // site first to authorize the payment, then redirected to the `return_url`.
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return StripeComponent;
}());
export { StripeComponent };
