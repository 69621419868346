import {
  APP_BASE_HREF,
  CommonModule,
  registerLocaleData
} from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import {
  APP_INITIALIZER,
  ComponentFactoryResolver,
  Injector,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateCompiler
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Daterangepicker } from 'ng2-daterangepicker';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { MomentModule } from 'ngx-moment';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { TranslateMessageFormatCompiler, MESSAGE_FORMAT_CONFIG } from 'ngx-translate-messageformat-compiler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthApiService } from './auth/auth-api.service';
import { ListingsApiService } from './establishments/establishments-api.service';
import { ReservationsApiService } from './reservations/reservations-api.service';
import { ServiceLocator } from './service-locator';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { ApiUrlService } from './shared/services/api-url.service';
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { ConfigService } from './shared/services/config.service';
import { LanguageService } from './shared/services/language.service';
import { SiteService } from './shared/services/site.service';
import { TemplateService } from './shared/services/template.service';
import { Template1Module } from './shared/templates/template1/template1.module';
import { ProfileService } from './user/profile/profile.service';
import { ReservationService } from './user/reservation/reservation.service';
import { ReservationsService } from './user/reservations/reservations.service';
import { PageService } from './shared/services/page.service';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

export const services: {
  [key: string]: { provide: any; deps: any[]; useClass?: any };
} = {
  ComponentFactoryResolver: {
    provide: ComponentFactoryResolver,
    deps: []
  },
  TemplateService: {
    provide: TemplateService,
    deps: [ComponentFactoryResolver]
  }
};

export function initializeApp(
  appConfig: ConfigService,
  siteService: SiteService,
  pageService: PageService,
  templateService: TemplateService
): () => Promise<any> {
  return async () => {
    await appConfig.load();
    await siteService.loadEnvironment();
    await siteService.loadSite();
    await pageService.loadPages();
    templateService.applySiteSettings(siteService.settings);
    return true;
  };
}

export function HttpLoaderFactory(http: HttpClient, apiUrl: ApiUrlService) {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, apiUrl.baseUrl() + 'lang/', '');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, ApiUrlService]
      },
    //   compiler: {
    //     provide: TranslateCompiler,
    //     useClass: TranslateMessageFormatCompiler
    //   }
    }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    RouterModule,
    AppRoutingModule,
    SharedComponentsModule,
    HttpClientModule,
    MomentModule,
    Daterangepicker,
    Template1Module,
    Nl2BrPipeModule,
    Ng2TelInputModule,
    LoadingBarRouterModule,
    CommonModule,
    TransferHttpCacheModule,
    NgtUniversalModule,
    CreditCardDirectivesModule
  ],
  providers: [
    ConfigService,
    SiteService,
    PageService,
    ApiUrlService,
    AuthApiService,
    ReservationsApiService,
    ListingsApiService,
    ProfileService,
    ReservationsService,
    ReservationService,
    LanguageService,
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService, SiteService, PageService, TemplateService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => {
        switch (translate.currentLang) {
          case 'fr':
            return 'fr-FR';
          case 'en':
            return 'en-US';
        }
      },
      deps: [TranslateService]
    },
    // { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: ['en', 'fr'] }},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    ServiceLocator.injector = Injector.create(
      Object.keys(services).map(key => ({
        provide: services[key].provide,
        useClass: services[key].provide,
        deps: services[key].deps
      }))
    );
  }
}
