
<div class="row">
    <ng-container *ngFor="let roomType of rooms; let typeIndex = index;">
        <div *ngFor="let room of roomType; let i = index;" class="col-12 col-sm-6 col-md-4">
            <div class="card p-2 mr-2 mb-2">
                <h6>
                    <i class="fa" [ngClass]="{'fa-bed': !room.isCommonSpace, 'fa-couch': room.isCommonSpace}"></i> {{ (typeIndex == 0) ? 'Chambre' : 'Espace commun' }} {{ i + 1 }}
                </h6>
                <div>
                    <app-room-bed-types [composition]="room.composition"></app-room-bed-types>
                </div>
            </div>
        </div>
    </ng-container>
</div>