import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WINDOW } from '@ng-toolkit/universal';
import { ConfigService } from './config.service';
import { ApiUrlService } from './api-url.service';
import { Site } from '../models/site.model';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  environmentCode: string = '';

  site: Site;

  constructor(@Inject(WINDOW) private window: Window, private http: HttpClient) {
  }

  get currency(): string {
    return this.site.settings.currency;
  }

  get settings(): any {
    return this.site.settings;
  }

  async loadEnvironment() {
    if (ConfigService.settings.environment) {
      this.environmentCode = ConfigService.settings.environment;
    } else {
      const { environmentCode } = await this.http.post<any>(ConfigService.settings.apiBaseUrl + 'environments/reference', {
        domain: this.window.location.hostname
      }).toPromise();
      this.environmentCode = environmentCode;
    }
  }

  loadSite() {
    return this.http.post<Site>(ConfigService.settings.apiBaseUrl + 'sites/reference', {
      domain: ConfigService.settings.domain || this.window.location.hostname,
    })
      .toPromise()
      .then(site => {
        return this.http.get<any>(ConfigService.settings.apiBaseUrl + 'sites/' + site.id + '/settings/public')
          .toPromise();
      })
      .then((site) => {
        this.site = site;
        return true;
      })
      .catch(err => console.error(err));
  }
}
