import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentService } from 'src/app/shared/services/equipment.service';
var AppRulesComponent = /** @class */ (function () {
    function AppRulesComponent(equipmentService, translate) {
        this.equipmentService = equipmentService;
        this.translate = translate;
    }
    AppRulesComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.equipmentService.initEquipmentsAndRules();
                return [2 /*return*/, this.equipmentService.loadEquipments
                        .toPromise()
                        .then(function (equipments) {
                        if (_this.type === 'establishment') {
                            _this.rules = equipments.establishmentRules;
                        }
                        else if (_this.type === 'typology') {
                            _this.rules = equipments.typologyRules;
                        }
                    })];
            });
        });
    };
    AppRulesComponent.prototype.ruleEnabled = function (slug) {
        return this.selectedRules.includes(slug);
    };
    AppRulesComponent.prototype.getClassForEquipment = function (rule) {
        var classes = [];
        classes.push(this.ruleEnabled(rule.slug) ? 'bg-success' : 'bg-danger');
        return classes.join(' ');
    };
    AppRulesComponent.prototype.getRuleText = function (rule) {
        var translateKey = "listings.rules." + rule.slug.toLocaleUpperCase();
        translateKey += this.ruleEnabled(rule.slug) ? '_ALLOWED' : '_NOT_ALLOWED';
        return this.translate.instant(translateKey);
    };
    return AppRulesComponent;
}());
export { AppRulesComponent };
