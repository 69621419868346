/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navbar/navbar.component.ngfactory";
import * as i3 from "./navbar/navbar.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../services/route.service";
import * as i6 from "../../../services/auth.service";
import * as i7 from "../../../services/site.service";
import * as i8 from "../../../services/api-url.service";
import * as i9 from "../../../services/page.service";
import * as i10 from "../../../services/navbar.service";
import * as i11 from "./main-layout.component";
var styles_MainLayout1Component = [i0.styles];
var RenderType_MainLayout1Component = i1.ɵcrt({ encapsulation: 0, styles: styles_MainLayout1Component, data: { "animation": [{ type: 7, name: "routeAnimations", definitions: [{ type: 1, expr: "* <=> *", animation: [{ type: 6, styles: { position: "relative" }, offset: null }, { type: 11, selector: ":leave", animation: { type: 6, styles: { position: "absolute", top: 0, left: 0, right: 0, opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: ":enter", animation: { type: 6, styles: { position: "absolute", top: 0, left: 0, right: 0, opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: ":leave", animation: { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: ".15s" }, options: { optional: true } }, { type: 11, selector: ":enter", animation: { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: ".15s" }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_MainLayout1Component as RenderType_MainLayout1Component };
export function View_MainLayout1Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "theme-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["id", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "template1-navbar", [], null, null, null, i2.View_Navbar1Component_0, i2.RenderType_Navbar1Component)), i1.ɵdid(3, 49152, null, 0, i3.Navbar1Component, [i4.Router, i5.RouteService, i6.AuthService, i7.SiteService, i8.ApiUrlService, i9.PageService, i10.NavbarService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "main", [], [[24, "@routeAnimations", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, [["outlet", 4]], 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.prepareRoute(i1.ɵnov(_v, 6)); _ck(_v, 4, 0, currVal_0); }); }
export function View_MainLayout1Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "main-layout-1", [], null, null, null, View_MainLayout1Component_0, RenderType_MainLayout1Component)), i1.ɵdid(1, 49152, null, 0, i11.MainLayout1Component, [], null, null)], null, null); }
var MainLayout1ComponentNgFactory = i1.ɵccf("main-layout-1", i11.MainLayout1Component, View_MainLayout1Component_Host_0, {}, {}, []);
export { MainLayout1ComponentNgFactory as MainLayout1ComponentNgFactory };
