import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Equipment } from '../models/equipment.model';
import { Rule } from '../models/rule.model';
import { ApiUrlService } from './api-url.service';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  public loadEquipments = new ReplaySubject<any>(0);

  private establishmentEquipments: Equipment[];

  private typologyEquipments: Equipment[];

  private establishmentRules: Rule[];

  private typologyRules: Rule[];

  private firstLoad = false;


  constructor(
    private http: HttpClient,
    private apiUrl: ApiUrlService
  ) {
  }

  async initEquipmentsAndRules() {
    if (this.firstLoad) return;
    this.firstLoad = true;

    const { equipments, rules } = await this.http.get<any>(this.apiUrl.baseUrl() + 'equipments-rules').toPromise();

    this.establishmentEquipments = equipments.establishments;
    this.typologyEquipments = equipments.typologies;

    this.establishmentRules = rules.establishments;
    this.typologyRules = rules.typologies;

    this.loadEquipments.next({
      establishmentEquipments: this.establishmentEquipments,
      typologyEquipments: this.typologyEquipments,
      establishmentRules: this.establishmentRules,
      typologyRules: this.typologyRules
    });
    this.loadEquipments.complete();
  }
}
