import { Component, OnInit } from '@angular/core';
import { ReservationsService } from 'src/app/user/reservations/reservations.service';
import { ListingsApiService } from 'src/app/establishments/establishments-api.service';
import { SiteService } from 'src/app/shared/services/site.service';
import * as moment from 'moment';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-reservations-1',
  templateUrl: 'reservations.component.html',
  styleUrls: ['reservations.component.scss']
})
export class Reservations1Component implements OnInit {
  public reservations: any[] = null;

  public displayedReservations: any[] = null;

  private _showPastReservations = false;

  public get showPastReservations(): boolean {
    return this._showPastReservations;
  }

  public set showPastReservations(showPastReservations: boolean) {
    this._showPastReservations = showPastReservations;

    // Filter reservations
    this.displayedReservations = this.reservations
      .filter(reservation => {
        const departureDate = moment(reservation.departureDate);
        if (moment().diff(departureDate, 'days') > 0) {
          // The reservation is in the past
          return this.showPastReservations;
        }
        return !this.showPastReservations;
      })
      .sort((a, b) => {
        if (this.showPastReservations) {
          // DESC order
          return moment(b.arrivalDate).unix() - moment(a.arrivalDate).unix();
        }

        // ASC order
        return moment(a.arrivalDate).unix() - moment(b.arrivalDate).unix();
      });
  }

  constructor(
    public reservationsService: ReservationsService,
    public listingsApiService: ListingsApiService,
    public site: SiteService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    // Reservations list is loaded in the reservations resolver
    this.reservations = this.route.snapshot.data.reservations;
    this.showPastReservations = false;
  }
}
