import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ListingsApiService } from 'src/app/establishments/establishments-api.service';
import { ApiUrlService } from './api-url.service';
import { LanguageService } from './language.service';
import { SessionStoreService } from './session-store.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./api-url.service";
import * as i3 from "./language.service";
import * as i4 from "@angular/router";
import * as i5 from "../../establishments/establishments-api.service";
import * as i6 from "./session-store.service";
var SearchService = /** @class */ (function () {
    function SearchService(http, apiUrl, translate, router, listingsApi, session) {
        this.http = http;
        this.apiUrl = apiUrl;
        this.translate = translate;
        this.router = router;
        this.listingsApi = listingsApi;
        this.session = session;
        this.typologiesWithPrices = new BehaviorSubject(null);
        this.searchLoading = false;
        this.searchResults = new BehaviorSubject(null);
        this.currentPage = 1;
        this.nbPerPage = 10;
        this.totalItems = 1;
        this.totalPages = 1;
        this.nbGuests = 0;
        this.nbNights = 0;
        this.datePickerOptions = {
            startDate: null,
            endDate: null,
            minStartDate: null,
            minEndDate: null
        };
        this.loadCriteria();
    }
    SearchService.prototype.loadCriteria = function () {
        this.criteria = this.session.getItem('search-criteria');
        if (!this.criteria) {
            this.initCriteria();
        }
        var today = moment();
        var start = moment(this.criteria.arrivalDate);
        var end = moment(this.criteria.departureDate);
        this.datePickerOptions.startDate = { year: start.year(), month: start.month() + 1, day: start.date() };
        this.datePickerOptions.endDate = { year: end.year(), month: end.month() + 1, day: end.date() };
        this.datePickerOptions.minStartDate = { year: today.year(), month: today.month() + 1, day: today.date() };
        var minEnd = moment(start).add(1, 'day');
        this.datePickerOptions.minEndDate = { year: minEnd.year(), month: minEnd.month() + 1, day: minEnd.date() };
        this.nbGuests = +this.criteria.nbAdults + +this.criteria.nbChildren;
        this.nbNights = moment(this.criteria.departureDate).diff(this.criteria.arrivalDate, 'days');
    };
    SearchService.prototype.initCriteria = function () {
        this.criteria = {
            arrivalDate: moment().startOf('day'),
            departureDate: moment().startOf('day').add(1, 'day'),
            nbAdults: 2,
            nbChildren: 0,
            nbInfants: 0,
            nbRooms: 1
        };
    };
    SearchService.prototype.selectedDate = function (value) {
        this.criteria.arrivalDate = value.start;
        this.criteria.departureDate = value.end;
    };
    SearchService.prototype.saveCriteria = function () {
        this.session.clear();
        var criteria = tslib_1.__assign({}, this.criteria);
        criteria.arrivalDate = moment(this.criteria.arrivalDate).startOf('day').format('YYYY-MM-DD');
        criteria.departureDate = moment(this.criteria.departureDate).startOf('day').format('YYYY-MM-DD');
        this.session.setItem('search-criteria', criteria);
    };
    SearchService.prototype.search = function () {
        var _this = this;
        // Load criteria from storage
        this.loadCriteria();
        var criteria = tslib_1.__assign({}, this.criteria);
        criteria.page = this.currentPage;
        criteria.nbPerPage = this.nbPerPage;
        this.searchLoading = true;
        return this.http.post(this.apiUrl.siteBaseUrl() + 'search', criteria)
            .toPromise()
            .then(function (result) {
            result.results.forEach(function (item) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var _a;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            item.photo = this.listingsApi.getLinkPhoto(item.photo);
                            _a = item;
                            return [4 /*yield*/, this.listingsApi.separateEquipmentsAndRules(item.equipments)];
                        case 1:
                            _a.equipments = (_b.sent()).equipments;
                            return [2 /*return*/];
                    }
                });
            }); });
            _this.nbGuests = result.nbGuests;
            _this.nbNights = result.nbNights;
            _this.searchResults.next(result.results);
            // Update pagination
            _this.totalPages = result.pages.totalPages;
            _this.totalItems = result.pages.totalItems;
            _this.searchLoading = false;
            return true;
        });
    };
    /**
       * Get the establishment prices for the current criteria
       * @param establishmentId
       */
    SearchService.prototype.getEstablishmentPrices = function (establishmentId) {
        var _this = this;
        return this.http.post(this.apiUrl.siteBaseUrl() + 'search/' + establishmentId, this.criteria)
            .toPromise().then(function (data) {
            _this.typologiesWithPrices.next(data);
            return true;
        });
    };
    SearchService.ngInjectableDef = i0.defineInjectable({ factory: function SearchService_Factory() { return new SearchService(i0.inject(i1.HttpClient), i0.inject(i2.ApiUrlService), i0.inject(i3.LanguageService), i0.inject(i4.Router), i0.inject(i5.ListingsApiService), i0.inject(i6.SessionStoreService)); }, token: SearchService, providedIn: "root" });
    return SearchService;
}());
export { SearchService };
