import {
  Component, ViewChild, ViewContainerRef, ComponentFactoryResolver
} from '@angular/core';
import { MainLayout1Component } from '../../templates/template1/layout/main-layout.component';
import { TemplateService } from '../../services/template.service';
import { TemplateComponent } from '../template/template.component';
import { SiteService } from '../../services/site.service';

@Component({
  selector: 'base-layout',
  template: '<ng-template #content></ng-template>'
})
export class BaseLayoutComponent extends TemplateComponent {
  constructor(componentFactory: ComponentFactoryResolver, site: SiteService) {
    super(componentFactory);
    super.setComponentName('MainLayout');
  }
}
