import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrlService } from '../shared/services/api-url.service';
import { Establishment } from '../shared/models/establishment.model';
import { Photo } from '../shared/models/photo';
import { Typology } from '../shared/models/typology.model';
import { LanguageService } from '../shared/services/language.service';
import { EquipmentService } from '../shared/services/equipment.service';

@Injectable()
export class ListingsApiService {
  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private language: LanguageService,
    private equipmentService: EquipmentService
  ) {

  }

  getEstablishment(id: number): Observable<Establishment> {
    return this.http.get<Establishment>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + id + '?lang=' + this.language.currentLang);
  }

  getTypologies(establishmentId: number): Observable<Typology[]> {
    return this.http.get<Typology[]>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies_with_photos');
  }

  getPhotos(establishmentId: number, typologyId: number): Observable<Photo[]> {
    if (typologyId !== -1) {
      return this.http.get<Photo[]>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies/' + typologyId + '/photos');
    }
    return this.http.get<Photo[]>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/photos');
  }

  getLinkPhoto(path: string): string {
    return this.apiUrlService.siteBaseUrl(true) + path;
  }

  /**
     * Separate equipments and rules from a list of equipment slugs
     * @param equipments List of equipments to separate
     */
  async separateEquipmentsAndRules(itemEquipments: string[]): Promise<{ equipments: string[], rules: string[] }> {
    const equipmentsRules = {
      equipments: [],
      rules: []
    };

    this.equipmentService.initEquipmentsAndRules();
    return this.equipmentService.loadEquipments
      .toPromise()
      .then(equipmentsAndRules => {
        const {
          establishmentEquipments,
          typologyEquipments,
          establishmentRules,
          typologyRules
        } = equipmentsAndRules;

        const allEquipments = establishmentEquipments.concat(typologyEquipments);
        const allRules = establishmentRules.concat(typologyRules);

        for (const equipment of itemEquipments) {
          if (allEquipments.find(val => val.slug === equipment)) {
            equipmentsRules.equipments.push(equipment);
          }
          if (allRules.find(val => val.slug === equipment)) {
            equipmentsRules.rules.push(equipment);
          }
        }
        return equipmentsRules;
      });
  }
}
