import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './shared/components/layout/base-layout.component';
import { AuthGuard } from './shared/services/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './home/home.module#HomeModule',
        data: { name: 'home' }
      },
      {
        path: '',
        loadChildren: './establishments/establishments.module#EstablishmentsModule'
      },
      {
        path: '',
        loadChildren: './auth/auth.module#AuthModule'
      },
      {
        path: '',
        loadChildren: './reservations/reservations.module#ReservationsModule'
      },
      {
        path: '',
        loadChildren: './pages/pages.module#PagesModule'
      },
      {
        path: '',
        loadChildren: './user/user.module#UserModule',
        canActivate: [AuthGuard],
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
