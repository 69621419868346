import { MainLayout1Component } from '../templates/template1/layout/main-layout.component';
import { Home1Component } from '../templates/template1/home/home.component';
import { EstablishmentsList1Component } from '../templates/template1/establishments/establishments-list/establishments-list.component';
import { EstablishmentDetails1Component } from '../templates/template1/establishments/establishment-details/establishment-details.component';
import { Login1Component } from '../templates/template1/auth/login/login.component';
import { Register1Component } from '../templates/template1/auth/register/register.component';
import { ReservationsBook1Component } from '../templates/template1/reservations/reservations-book/reservations-book.component';
import { Profile1Component } from '../templates/template1/user/profile/profile.component';
import { Reservations1Component } from '../templates/template1/user/reservations/reservations.component';
import { UserReservation1Component } from '../templates/template1/user/reservation/reservation.component';
import { Messages1Component } from '../templates/template1/user/messages/messages.component';
import { Password1Component } from '../templates/template1/user/password/password.component';
import { ApiUrlService } from './api-url.service';
import { ResetPassword1Component } from '../templates/template1/auth/reset-password/reset-password.component';
import { Pages1Component } from '../templates/template1/pages/pages/pages.component';
import * as i0 from "@angular/core";
import * as i1 from "./api-url.service";
var TemplateService = /** @class */ (function () {
    function TemplateService(apiUrl) {
        this.apiUrl = apiUrl;
        this.currentTemplate = 1;
        this.components = {
            MainLayout1Component: MainLayout1Component,
            Home1Component: Home1Component,
            EstablishmentsList1Component: EstablishmentsList1Component,
            EstablishmentDetails1Component: EstablishmentDetails1Component,
            Login1Component: Login1Component,
            Register1Component: Register1Component,
            ReservationsBook1Component: ReservationsBook1Component,
            Profile1Component: Profile1Component,
            Reservations1Component: Reservations1Component,
            UserReservation1Component: UserReservation1Component,
            Messages1Component: Messages1Component,
            Password1Component: Password1Component,
            Pages1Component: Pages1Component,
            ResetPassword1Component: ResetPassword1Component
        };
    }
    TemplateService.prototype.getComponent = function (componentName) {
        return this.components[componentName];
    };
    TemplateService.prototype.applySiteSettings = function (settings) {
        if (settings.template) {
            // Set the current template
            this.currentTemplate = parseInt(settings.template);
        }
        if (settings.mainColor) {
            // Set the main color
            document.documentElement.style.setProperty('--main-color', settings.mainColor);
        }
        if (settings.mainDarkColor) {
            // Set the main dark color
            document.documentElement.style.setProperty('--main-dark-color', settings.mainDarkColor);
        }
        if (settings.mainDarkerColor) {
            // Set the main darker color
            document.documentElement.style.setProperty('--main-darker-color', settings.mainDarkerColor);
        }
        // if (settings.favicon) {
        //   // Set the favicon
        //   document.getElementById('favicon').setAttribute('href', this.apiUrl.siteBaseUrl(true) + 'images/' + settings.favicon);
        // }
    };
    TemplateService.ngInjectableDef = i0.defineInjectable({ factory: function TemplateService_Factory() { return new TemplateService(i0.inject(i1.ApiUrlService)); }, token: TemplateService, providedIn: "root" });
    return TemplateService;
}());
export { TemplateService };
