import { ProfileService } from 'src/app/user/profile/profile.service';
var Password1Component = /** @class */ (function () {
    function Password1Component(profileService) {
        this.profileService = profileService;
        this.profileService.initPasswordForm();
        this.passwordForm = this.profileService.passwordForm;
    }
    Object.defineProperty(Password1Component.prototype, "submitted", {
        get: function () {
            return this.profileService.passwordFormSubmitted;
        },
        enumerable: true,
        configurable: true
    });
    Password1Component.prototype.changePassword = function () {
        this.profileService.changePassword();
    };
    return Password1Component;
}());
export { Password1Component };
