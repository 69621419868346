/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components/pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../components/pipes/translate-map.pipe";
import * as i5 from "../../../../services/language.service";
import * as i6 from "@angular/common";
import * as i7 from "../../layout/footer/footer.component.ngfactory";
import * as i8 from "../../layout/footer/footer.component";
import * as i9 from "../../../../services/site.service";
import * as i10 from "../../../../services/api-url.service";
import * as i11 from "@angular/common/http";
import * as i12 from "@angular/router";
import * as i13 from "./pages.component";
var styles_Pages1Component = [i0.styles];
var RenderType_Pages1Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Pages1Component, data: {} });
export { RenderType_Pages1Component as RenderType_Pages1Component };
function View_Pages1Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "page-content"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 1), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 1), _co.page.content)))); _ck(_v, 1, 0, currVal_0); }); }
export function View_Pages1Component_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), i1.ɵpid(0, i4.TranslateMapPipe, [i5.LanguageService]), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["id", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Pages1Component_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "template1-footer", [], null, null, null, i7.View_Footer1Component_0, i7.RenderType_Footer1Component)), i1.ɵdid(6, 49152, null, 0, i8.Footer1Component, [i9.SiteService, i10.ApiUrlService, i11.HttpClient, i5.LanguageService, i12.Router], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.page; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_Pages1Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pages-1", [], null, null, null, View_Pages1Component_0, RenderType_Pages1Component)), i1.ɵdid(1, 114688, null, 0, i13.Pages1Component, [i12.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Pages1ComponentNgFactory = i1.ɵccf("app-pages-1", i13.Pages1Component, View_Pages1Component_Host_0, {}, {}, []);
export { Pages1ComponentNgFactory as Pages1ComponentNgFactory };
