import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Establishment } from '../shared/models/establishment.model';
import { Photo } from '../shared/models/photo';
import { ReservationInformationPriceModel } from '../shared/models/reservation-information-price.model';
import { ApiUrlService } from '../shared/services/api-url.service';
import { LanguageService } from '../shared/services/language.service';

@Injectable()
export class ReservationsApiService {
  constructor(private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private language: LanguageService) {}

  getEstablishment(id: number): Observable<Establishment> {
    return this.http.get<Establishment>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + id + '?lang=' + this.language.currentLang);
  }

  getLinkPhoto(path: string): string {
    return this.apiUrlService.siteBaseUrl(true) + path;
  }

  getPhotos(establishmentId: number, typologyId: number): Observable<Photo[]> {
    if (typologyId !== -1) {
      return this.http.get<Photo[]>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/'
                + establishmentId + '/typologies/' + typologyId + '/photos');
    }
    return this.http.get<Photo[]>(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/photos');
  }

  getPrices(bookReservation: ReservationInformationPriceModel) {
    return this.http.post(this.apiUrlService.siteBaseUrl() + 'typology/prices', bookReservation);
  }

  createAndGetStripePaymentIntentClientSecret(booked: ReservationInformationPriceModel) {
    return this.http.put<any>(this.apiUrlService.siteBaseUrl() + 'payment/stripe-payment-intent', {
      booked
    })
      .toPromise();
  }

  getStripePaymentIntent(booked: ReservationInformationPriceModel) {
    return this.http.put<any>(this.apiUrlService.siteBaseUrl() + 'payment/stripe-payment-intent', {
      booked
    })
      .toPromise();
  }

  submitReservation(paymentMethod: string, paymentForm: any, booked: ReservationInformationPriceModel) {
    return this.http.post<any>(this.apiUrlService.siteBaseUrl() + 'payment/handler', {
      paymentMethod: paymentMethod,
      paymentData: paymentForm,
      booked
    })
      .toPromise();
  }
}
