import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { RouteService } from 'src/app/shared/services/route.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { Establishment } from '../../shared/models/establishment.model';
import { ListingsApiService } from '../establishments-api.service';
import { Photo } from '../../shared/models/photo';
import { Typology } from '../../shared/models/typology.model';
import { SessionStoreService } from '../../shared/services/session-store.service';
import { AuthService } from '../../shared/services/auth.service';
import { TypologyReserved } from '../../shared/models/typology-reserved';
import { ReservationInformationPriceModel } from '../../shared/models/reservation-information-price.model';
import { Criteria } from '../../shared/models/criteria.model';
import { BookedTypologyModel } from '../../shared/models/booked-typology.model';
import { RepartitionModel } from '../../shared/models/repartition.model';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentDetailsService {
  public establishment: BehaviorSubject<Establishment> = new BehaviorSubject({});

  public typologies: BehaviorSubject<Typology[]> = new BehaviorSubject([]);

  public selectedEquipments: BehaviorSubject<string[]> = new BehaviorSubject([]);

  public selectedRules: BehaviorSubject<string[]> = new BehaviorSubject([]);

  public photos: BehaviorSubject<Photo[]> = new BehaviorSubject([]);

  public typologiesWithPrice: any[] = null;

  public reservationInformation: ReservationInformationPriceModel = {};

  public loading = true;

  public reservedTypologies: TypologyReserved[];

  public criteria: Criteria;

  constructor(
    public listingsApi: ListingsApiService,
    public sessionStorage: SessionStoreService,
    private auth: AuthService,
    private routeService: RouteService,
    private router: Router,
    private language: LanguageService
  ) {
    this.initEstablishmentDetails();
  }

  initEstablishmentDetails() {
    this.reservedTypologies = this.sessionStorage.getItem('reservations') || [];
    this.criteria = this.sessionStorage.getItem('search-criteria');
  }

  getEstablishment(establishmentId) {
    return this.listingsApi.getEstablishment(establishmentId)
      .toPromise()
      .then(async (establishment: Establishment) => {
        this.establishment.next(establishment);

        // Load equipments
        const equipmentsRules = await this.listingsApi.separateEquipmentsAndRules(this.establishment.value.equipments);
        this.selectedEquipments.next(equipmentsRules.equipments);
        this.selectedRules.next(equipmentsRules.rules);

        return true;
      });
  }

  initPhotos(idEstablishment, idTypology) {
    return this.listingsApi.getPhotos(idEstablishment, idTypology)
      .toPromise()
      .then((photos: Photo[]) => {
        this.photos.next(photos);
        return true;
      });
  }

  getTypologies(idEstablishment) {
    return this.listingsApi.getTypologies(idEstablishment)
      .toPromise()
      .then((typologies: Typology[]) => {
        this.typologies.next(typologies);
        return true;
      });
  }

  getImageUrl(path: string) {
    return this.listingsApi.getLinkPhoto(path);
  }

  isReserved(typo: Typology) {
    this.reservedTypologies.findIndex(item => item.id === typo.id) !== -1;
  }

  toggleTypology(typo: Typology, quantity) {
    const index = this.reservedTypologies.findIndex(item => item.id === typo.id);
    if (index !== -1) {
      this.reservedTypologies.splice(index, 1);
    } else {
      this.reservedTypologies.push({
        id: typo.id, typology: typo, quantity, typologyWithPrice: this.typologiesWithPrice[typo.id]
      });
    }
    this.saveReservations();
  }

  getTotalPrices(priceList, quantity) {
    if (!priceList) return;
    let totalPrice = 0;
    for (const typo of this.reservedTypologies) {
      totalPrice += priceList[typo.id].minPrice * quantity[typo.id];
    }

    return totalPrice;
  }

  getNumberOfRooms(typo: Typology) {
    return typo.rooms.filter(item => !item.isCommonSpace).length;
  }

  getNumberOfCommunSpace(typo: Typology) {
    return typo.rooms.filter(item => item.isCommonSpace).length;
  }

  saveReservations() {
    sessionStorage.setItem('reservations', JSON.stringify(this.reservedTypologies));
  }

  transformReservations() {
    this.reservationInformation.establishment = this.reservedTypologies[0].typology.EstablishmentId;
    this.reservationInformation.criteria = this.criteria;
    const bookReservation: BookedTypologyModel[] = [];
    for (const reservations of this.reservedTypologies) {
      for (let i = 0; i < reservations.quantity; i++) {
        const book: BookedTypologyModel = {};
        const repartition: RepartitionModel = {};
        book.id = reservations.typology.id;
        book.name = reservations.typology.name;
        book.rateId = 1; // TODO: change the rate
        repartition.nbAdults = 0;
        repartition.nbChildren = 0;
        repartition.nbInfants = 0;
        book.repartition = repartition;
        book.capacity = reservations.typology.capacity;
        bookReservation.push(book);
      }
    }
    this.reservationInformation.bookedReservation = bookReservation;
    sessionStorage.setItem('reservationInformation', JSON.stringify(this.reservationInformation));
  }

  book() {
    this.transformReservations();

    if (this.auth.user) {
      this.router.navigateByUrl('/reservations/book');
    } else {
      this.auth.redirectAfterLogin = '/reservations/book';
      this.router.navigateByUrl('/sessions/signin');
    }
  }
}
