import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from 'src/app/shared/services/language.service';
import { Router } from '@angular/router';
import { SiteService } from '../../../../services/site.service';
import { ApiUrlService } from '../../../../services/api-url.service';
import { ImageSnippet } from '../../../../models/image-snippet.model';


@Component({
  selector: 'template1-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']
})
export class Footer1Component {
  public currentYear = new Date().getFullYear();
  constructor(
    public siteService: SiteService,
    private apiUrlService: ApiUrlService,
    private http: HttpClient,
    public langService: LanguageService,
    private router: Router
  ) {
  }

  getLogo() {
    return this.apiUrlService.siteBaseUrl(true) + 'images/' + this.siteService.settings.logo;
  }

  setLang(lang: string) {
    this.langService.currentLang = lang;
    window.location.reload();
  }
}
