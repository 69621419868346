import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import {
  NgModule, ComponentFactoryResolver, Injector, APP_INITIALIZER, LOCALE_ID
} from '@angular/core';

import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {
  TranslateModule, TranslateLoader, TranslatePipe, TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Daterangepicker } from 'ng2-daterangepicker';
import { MomentModule } from 'ngx-moment';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { Template1Module } from './shared/templates/template1/template1.module';
import { TemplateService } from './shared/services/template.service';
import { ServiceLocator } from './service-locator';
import { SiteService } from './shared/services/site.service';
import { ConfigService } from './shared/services/config.service';
import { ApiUrlService } from './shared/services/api-url.service';
import { ListingsApiService } from './establishments/establishments-api.service';
import { LanguageService } from './shared/services/language.service';
import { AuthApiService } from './auth/auth-api.service';
import { ReservationsApiService } from './reservations/reservations-api.service';
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { ProfileService } from './user/profile/profile.service';
import { ReservationsService } from './user/reservations/reservations.service';
import { ReservationService } from './user/reservation/reservation.service';

import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { AppModule } from './app.module';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

export const services: {[key: string]: {provide: any, deps: any[], useClass?: any}} = {
  ComponentFactoryResolver: {
    provide: ComponentFactoryResolver,
    deps: []
  },
  TemplateService: {
    provide: TemplateService,
    deps: [ComponentFactoryResolver]
  }
};

export function initializeApp(appConfig: ConfigService, siteService: SiteService, templateService: TemplateService): () => Promise<any> {
  return async () => {
    await appConfig.load();
    await siteService.loadEnvironment();
    await siteService.loadSite();
    templateService.applySiteSettings(siteService.settings);
    return true;
  };
}

export function HttpLoaderFactory(http: HttpClient, apiUrl: ApiUrlService) {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, apiUrl.baseUrl() + 'lang/', '');
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, ApiUrlService]
      },
    }),

    RouterModule,
    AppRoutingModule,
    SharedComponentsModule,
    HttpClientModule,
    MomentModule,
    Daterangepicker,
    Template1Module,
    Nl2BrPipeModule,
    Ng2TelInputModule,
    LoadingBarRouterModule,
    AppModule,
    BrowserTransferStateModule
  ],
  providers: [
    ConfigService,
    SiteService,
    ApiUrlService,
    AuthApiService,
    ReservationsApiService,
    ListingsApiService,
    ProfileService,
    ReservationsService,
    ReservationService,
    LanguageService,
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService, SiteService, TemplateService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => {
        switch (translate.currentLang) {
          case 'fr':
            return 'fr-FR';
          case 'en':
            return 'en-US';
        }
      },
      deps: [TranslateService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppBrowserModule {
  constructor() {
    ServiceLocator.injector = Injector.create(
      Object.keys(services).map(key => ({
        provide: services[key].provide,
        useClass: services[key].provide,
        deps: services[key].deps
      }))
    );
  }
}
