import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { AlertType } from 'src/app/shared/models/alert-type.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ReservationService } from 'src/app/user/reservation/reservation.service';
import { ListingsApiService } from '../../establishments/establishments-api.service';
import { BookedTypologyModel } from '../../shared/models/booked-typology.model';
import { Establishment } from '../../shared/models/establishment.model';
import { Photo } from '../../shared/models/photo';
import { ReservationInformationPriceModel } from '../../shared/models/reservation-information-price.model';
import { TypologyReserved } from '../../shared/models/typology-reserved';
import { AuthService } from '../../shared/services/auth.service';
import { SearchService } from '../../shared/services/search.service';
import { SessionStoreService } from '../../shared/services/session-store.service';
import { ReservationsApiService } from '../reservations-api.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationsBookService {
  public establishment: BehaviorSubject<Establishment> = new BehaviorSubject({});

  public selectedEquipments: BehaviorSubject<string[]> = new BehaviorSubject([]);

  public selectedRules: BehaviorSubject<string[]> = new BehaviorSubject([]);

  public photos: BehaviorSubject<Photo[]> = new BehaviorSubject([]);

  public reservedTypologies: TypologyReserved[];

  public bookedReservation: ReservationInformationPriceModel;

  search: any;

  arrivalDate: any = {};

  departureDate: any = {};

  dayNight: number;

  detailsPrices: any = {};

  stateList = ['summary', 'information_modification', 'payment'];

  private _indexState: number = 0;

  public animatedIndexState: BehaviorSubject<number> = new BehaviorSubject(0);

  set indexState(indexState: number) {
    this._indexState = indexState;
    this.animatedIndexState.next(null);
    setTimeout(() => {
      this.animatedIndexState.next(indexState);
    }, 200);
  }

  get indexState(): number {
    return this._indexState;
  }


  submitted = false;

  loading = false;

  public paymentLoading: boolean = false;

  constructor(
    public listingsApi: ListingsApiService,
    public sessionStorage: SessionStoreService,
    private auth: AuthService,
    private router: Router,
    private reservationApi: ReservationsApiService,
    private fb: FormBuilder,
    private alert: AlertService,
    private translate: TranslateService
  ) {
    this.initReservationBooking();
  }

  initReservationBooking() {
    this.reservedTypologies = this.sessionStorage.getItem('reservations');
    this.bookedReservation = this.sessionStorage.getItem('reservationInformation');
    if (!this.bookedReservation) {
      this.router.navigateByUrl('/');
      return;
    }
    this.setInitialRepartition();
    this.updatePriceTravelersChangement();
    this.search = this.bookedReservation.criteria;

    this.dayNight = moment(this.search.departureDate).diff(this.search.arrivalDate, 'days');
    this.arrivalDate.month = moment(this.search.arrivalDate).format('MMM');
    this.arrivalDate.day = moment(this.search.arrivalDate).format('D');
    this.arrivalDate.dayText = moment(this.search.arrivalDate).format('dddd');
    this.arrivalDate.completeDateText = moment(this.search.arrivalDate).format('D MMM YYYY');

    this.departureDate.month = moment(this.search.departureDate).format('MMM');
    this.departureDate.day = moment(this.search.departureDate).format('D');
    this.departureDate.dayText = moment(this.search.departureDate).format('dddd');
    this.departureDate.completeDateText = moment(this.search.departureDate).format('D MMM YYYY');
  }

  getEstablishment(establishmentId) {
    this.reservationApi.getEstablishment(establishmentId)
      .subscribe(async (establishment: Establishment) => {
        this.establishment.next(establishment);

        // Load equipments
        const equipmentsRules = await this.listingsApi.separateEquipmentsAndRules(this.establishment.value.equipments);
        this.selectedEquipments.next(equipmentsRules.equipments);
        this.selectedRules.next(equipmentsRules.rules);
      });

    this.reservationApi.getPhotos(establishmentId, -1).subscribe(photos => {
      this.photos.next(photos);
    });
  }

  getImageUrl(path: string) {
    return this.reservationApi.getLinkPhoto(path);
  }

  calculTaxesEtGlobale() {
    this.detailsPrices = {};
    this.bookedReservation.bookedReservation.forEach(item => {
      const prices = item.price.priceDetails;
      prices.forEach(price => {
        if (!this.detailsPrices[price.name]) {
          this.detailsPrices[price.name] = 0;
        }
        this.detailsPrices[price.name] += price.amount;
      });
    });
  }

  changeStep(index: number) {
    this.indexState = index;
  }

  arrayOne(n: number) {
    const strValue = n + '';
    return Array(parseInt(strValue, 10));
  }

  getTotalRoom() {
    let nb = 0;
    for (const typo of this.reservedTypologies) {
      nb += typo.quantity;
    }
    return nb;
  }

  updatePriceTravelersChangement() {
    this.reservationApi.getPrices(this.bookedReservation).toPromise().then(res => {
      for (let i = 0; i < this.bookedReservation.bookedReservation.length; i++) {
        this.bookedReservation.bookedReservation[i].price = res[i];
      }
      this.calculTaxesEtGlobale();
    });
  }

  getCurrentCapacity(typo: BookedTypologyModel, category) {
    const numberToRetired = typo.repartition[(category === 1) ? 'nbAdults' : 'nbChildren'];
    return typo.capacity - numberToRetired;
  }

  getTravelersTotal() {
    let total = 0;
    for (const rep of this.bookedReservation.bookedReservation) {
      total += (typeof rep.repartition.nbAdults === 'string') ? parseInt(rep.repartition.nbAdults, 10) : rep.repartition.nbAdults;
      total += (typeof rep.repartition.nbChildren === 'string') ? parseInt(rep.repartition.nbChildren, 10) : rep.repartition.nbChildren;
    }
    return total;
  }

  controlNbTravelers() {
    this.updatePriceTravelersChangement();
  }

  setInitialRepartition() {
    let { nbAdults } = this.bookedReservation.criteria;
    let { nbChildren } = this.bookedReservation.criteria;
    let { nbInfants } = this.bookedReservation.criteria;

    if (nbAdults < this.bookedReservation.bookedReservation.length) {
      nbAdults = this.bookedReservation.bookedReservation.length;
    }

    // Assign guest to rooms
    let peopleToAssign = nbAdults + nbChildren + nbInfants;

    while (peopleToAssign > 0) {
      for (const room of this.bookedReservation.bookedReservation) {
        if (nbAdults > 0 && (room.repartition.nbAdults + room.repartition.nbChildren < room.capacity)) {
          room.repartition.nbAdults++;
          nbAdults--;
        }

        if (nbChildren > 0 && (room.repartition.nbAdults + room.repartition.nbChildren < room.capacity)) {
          room.repartition.nbChildren++;
          nbChildren--;
        }

        if (nbInfants > 0) {
          room.repartition.nbInfants++;
          nbInfants--;
        }

        const newPeopleToAssign = nbAdults + nbChildren + nbInfants;
        if (newPeopleToAssign === peopleToAssign) {
          // There is no more space
          peopleToAssign = 0;
          break;
        } else {
          peopleToAssign = newPeopleToAssign;
        }
      }
    }
  }

  submitPayment(paymentForm: any) {
    this.paymentLoading = true;
    this.reservationApi.submitReservation('paymentCardData', paymentForm, this.bookedReservation)
      .then(rep => {
        if (!rep.success) {
          throw new Error();
        }
        this.router.navigateByUrl('/user/reservations/' + rep.reservationCode);
      })
      .catch(err => {
        this.alert.set(AlertType.DANGER, this.translate.instant('book.ERROR'));
        console.error(err);
      })
      .finally(() => {
        this.paymentLoading = false;
      });
  }
}
