import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from 'src/app/shared/services/api-url.service';
import { SiteService } from '../../shared/services/site.service';

@Injectable()
export class HomeService {
  settings: any;

  constructor(private apiUrl: ApiUrlService, private http: HttpClient, public siteService: SiteService) {

  }
}
