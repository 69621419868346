<ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>
<div class="top-wrapper" [ngClass]="{'top-wrapper-dark': routeService.currentRoute?.name === 'home'}">
    <div class="top-inner" id="top-inner">
        <div class="top2-wrapper">
            <div class="container">
                <div class="top2 clearfix">
                    <nav class="navbar_ navbar navbar-expand-md">
                        <div class="logo_wrapper">
                            <h1 class="logo2" *ngIf="siteService.settings && !siteService.settings.logo">
                                {{siteService.site.name}}</h1>
                            <a routerLink="/" class="logo">
                                <img [src]="getLogo()" alt="" class="img-fluid" style="max-height: 80px;"
                                    *ngIf="siteService.settings && siteService.settings.logo">
                            </a>
                        </div>
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent2"
                            aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbar()">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" [class.show]="navbarService.navbarOpen" id="navbarSupportedContent2">
                            <ul class="nav navbar-nav sf-menu clearfix">
                                <li class="nav-item">
                                    <a routerLink="/" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        {{ 'titles.HOME' | translate }}
                                    </a>
                                </li>

                                <li *ngFor="let page of pageService.pages" class="nav-item">
                                    <ng-container *ngIf="page.type === 'content'">
                                        <a [routerLink]="'/pages/' + page.slug" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                            {{ page.name | translateMap }}
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="page.type === 'redirect'">
                                        <a [href]="page.redirectUrl" class="nav-link">
                                            {{ page.name | translateMap }}
                                        </a>
                                    </ng-container>
                                </li>

                                <ng-container *ngIf="auth.authenticated && auth.user">
                                <li class="nav-item d-block d-md-none">
                                    <a routerLink="/user/reservations" class="nav-link" routerLinkActive="active">
                                        {{ 'reservations.RESERVATIONS' | translate }}
                                    </a>
                                </li>

                                <li class="nav-item d-block d-md-none">
                                    <a routerLink="/user/profile" class="nav-link" routerLinkActive="active">
                                        {{ 'user.PROFILE' | translate }}
                                    </a>
                                </li>

                                <li class="nav-item d-block d-md-none">
                                    <a routerLink="/user/logout" class="nav-link" routerLinkActive="active">
                                        {{ 'user.LOG_OUT' | translate }}
                                    </a>
                                </li>
                                </ng-container>

                                <ng-container *ngIf="auth.authenticated && auth.user">
                                    <li class="nav-item d-none d-md-block" ngbDropdown placement="bottom-right">
                                        <a class="nav-link navbar-avatar cursor-pointer" id="profileDropdown"
                                            ngbDropdownToggle>
                                            <img [src]="auth.pictureProfileUrl" [alt]="'user.PROFILE' | translate">
                                        </a>
                                        <div class="sf-mega" ngbDropdownMenu aria-labelledby="profileDropdown">
                                            <ul>
                                                <li><a
                                                        routerLink="/user/reservations">{{ 'reservations.RESERVATIONS' | translate }}</a>
                                                </li>
                                                <li><a routerLink="/user/profile">{{ 'user.PROFILE' | translate }}</a>
                                                </li>
                                                <li><a routerLink="/user/logout">{{ 'user.LOG_OUT' | translate }}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="!auth.authenticated">
                                    <li class="nav-item"><a class="nav-link" routerLink="/sessions/signin"
                                            routerLinkActive="active">{{ 'user.CONNECT' | translate }}</a></li>
                                </ng-container>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>