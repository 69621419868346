import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MomentDateFormatter extends NgbDateParserFormatter {
  DT_FORMAT = 'DD/MM/YYYY';

  constructor(private translate: TranslateService) {
    super();
    this.translate.get('common.MOMENT_DATE').subscribe(format => {
      this.DT_FORMAT = format;
    });
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      value = value.trim();
      const mdt = moment(value, this.DT_FORMAT);
      return { year: mdt.year(), month: mdt.month() + 1, day: mdt.date() };
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) return '';
    const mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) return '';
    return mdt.format(this.DT_FORMAT);
  }
}
