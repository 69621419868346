import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NavbarService {
  public navbarOpen: boolean = false;

  constructor() {

  }
}
