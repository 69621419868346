import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from 'src/app/shared/services/api-url.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable()
export class ReservationService {
  public reservation: any = null;

  public reservationRooms: any = null;

  public messages: any[] = null;

  public messagesLoading = false;

  constructor(
    private http: HttpClient,
    private apiUrl: ApiUrlService,
    private translate: TranslateService
  ) {

  }

  getReservation(code: string) {
    return this.http.get<any>(this.apiUrl.siteBaseUrl() + 'guest/reservations/' + code)
      .toPromise()
      .then(reservation => {
        this.reservation = reservation;

        return this.getReservationRooms(code);
      });
  }

  getRoomFromTypology(typologyId: number) {
    return this.reservationRooms.find(typo => typo.typologyId === typologyId).rooms;
  }

  getReservationRooms(code: string) {
    return this.http.get<any>(this.apiUrl.siteBaseUrl() + 'guest/reservations/' + code + '/rooms')
      .toPromise()
      .then(reservationRooms => {
        this.reservationRooms = reservationRooms;
      });
  }

  getReservationPolicy(code: string) {
    return this.http.get<any>(this.apiUrl.siteBaseUrl() + 'guest/reservations/' + code + '/policy')
      .toPromise();
  }

  canCancel() {
    return moment().isBefore(moment(this.reservation.arrivalDate).add(1, 'day'));
  }

  cancelReservation(code: string) {
    return this.http.get<any>(this.apiUrl.siteBaseUrl() + 'guest/reservations/' + code + '/cancel')
      .toPromise()
      .then(() => {
        // Reload reservation
        return this.getReservation(code);
      });
  }

  getMessages(code: string) {
    this.messagesLoading = true;
    return this.http.get<any[]>(this.apiUrl.siteBaseUrl() + 'guest/reservations/' + code + '/messages')
      .toPromise()
      .then(messages => {
        this.messages = messages;
        this.messagesLoading = false;
      });
  }

  sendMessage(code: string, message: string) {
    this.messagesLoading = true;
    return this.http.post(this.apiUrl.siteBaseUrl() + 'guest/reservations/' + code + '/messages', {
      message
    })
      .toPromise()
      .then(() => {
        return this.getMessages(code);
      });
  }

  getTimePeriodPhrase(min: string, max: string): string {
    let minFormatted; let
      maxFormatted;
    if (min != null) {
      minFormatted = moment(min, 'HH:mm:ss').format(this.translate.instant('common.MOMENT_SHORT_TIME')).replace(/h00$/, 'h');
    }
    if (min != null) {
      maxFormatted = moment(max, 'HH:mm:ss').format(this.translate.instant('common.MOMENT_SHORT_TIME')).replace(/h00$/, 'h');
    }
    if (min != null && min != '00:00:00' && max != null && max != '00:00:00') {
      return this.translate.instant('reservations.TIME_BETWEEN', {
        min: minFormatted,
        max: maxFormatted
      });
    }
    if (min != null && min != '00:00:00') {
      return this.translate.instant('reservations.AFTER_MIN', {
        min: minFormatted
      });
    }
    if (max != null && max != '00:00:00') {
      return this.translate.instant('reservations.BEFORE_MAX', {
        max: maxFormatted
      });
    }

    return '';
  }
}
