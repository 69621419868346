/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../room-bed-types/room-bed-types.component.ngfactory";
import * as i3 from "../room-bed-types/room-bed-types.component";
import * as i4 from "./room-composition.component";
var styles_RoomCompositionComponent = [];
var RenderType_RoomCompositionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RoomCompositionComponent, data: {} });
export { RenderType_RoomCompositionComponent as RenderType_RoomCompositionComponent };
function View_RoomCompositionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "col-12 col-sm-6 col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "card p-2 mr-2 mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "h6", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "i", [["class", "fa"]], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(5, { "fa-bed": 0, "fa-couch": 1 }), (_l()(), i0.ɵted(6, null, [" ", " ", " "])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "app-room-bed-types", [], null, null, null, i2.View_RoomBedTypesComponent_0, i2.RenderType_RoomBedTypesComponent)), i0.ɵdid(9, 49152, null, 0, i3.RoomBedTypesComponent, [], { composition: [0, "composition"] }, null)], function (_ck, _v) { var currVal_0 = "fa"; var currVal_1 = _ck(_v, 5, 0, !_v.context.$implicit.isCommonSpace, _v.context.$implicit.isCommonSpace); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.composition; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = ((_v.parent.context.index == 0) ? "Chambre" : "Espace commun"); var currVal_3 = (_v.context.index + 1); _ck(_v, 6, 0, currVal_2, currVal_3); }); }
function View_RoomCompositionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RoomCompositionComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RoomCompositionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RoomCompositionComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rooms; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RoomCompositionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-room-composition", [], null, null, null, View_RoomCompositionComponent_0, RenderType_RoomCompositionComponent)), i0.ɵdid(1, 49152, null, 0, i4.RoomCompositionComponent, [], null, null)], null, null); }
var RoomCompositionComponentNgFactory = i0.ɵccf("app-room-composition", i4.RoomCompositionComponent, View_RoomCompositionComponent_Host_0, { composition: "composition" }, {}, []);
export { RoomCompositionComponentNgFactory as RoomCompositionComponentNgFactory };
