import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Page } from 'src/app/shared/models/page.model';

@Component({
  selector: 'app-pages-1',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class Pages1Component implements OnInit {
  page: Page;

  constructor(
    private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.route.data
      .subscribe(data => {
        this.page = data.page;
      });
  }
}
