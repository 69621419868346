import * as tslib_1 from "tslib";
import { ComponentFactoryResolver } from '@angular/core';
import { TemplateComponent } from '../template/template.component';
import { SiteService } from '../../services/site.service';
var BaseLayoutComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BaseLayoutComponent, _super);
    function BaseLayoutComponent(componentFactory, site) {
        var _this = _super.call(this, componentFactory) || this;
        _super.prototype.setComponentName.call(_this, 'MainLayout');
        return _this;
    }
    return BaseLayoutComponent;
}(TemplateComponent));
export { BaseLayoutComponent };
