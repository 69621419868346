import { Injectable } from '@angular/core';
import { AuthApiService } from '../auth-api.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    private authApiService: AuthApiService
  ) {}

  signin(credentials) {
    return this.authApiService.signin(credentials);
  }

  resetPassword(email: string) {
    return this.authApiService.resetPassword(email);
  }

  checkResetToken(token: string) {
    return this.authApiService.checkResetToken(token);
  }

  changePassword(token: string, password: string) {
    return this.authApiService.changePassword(token, password);
  }
}
