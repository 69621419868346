/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./room-bed-types.component";
var styles_RoomBedTypesComponent = [];
var RenderType_RoomBedTypesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RoomBedTypesComponent, data: {} });
export { RenderType_RoomBedTypesComponent as RenderType_RoomBedTypesComponent };
function View_RoomBedTypesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " ", "", "\n"])), i0.ɵppd(2, 1), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), i0.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.value; var currVal_1 = i0.ɵunv(_v, 1, 1, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform((("listings.typologies.bedTypes." + i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 1), _v.context.$implicit.key))) + ((_v.context.$implicit.value > 1) ? "_PLURAL" : "")))))); var currVal_2 = (_v.context.last ? "" : ", "); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_RoomBedTypesComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.LowerCasePipe, []), i0.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_RoomBedTypesComponent_1)), i0.ɵdid(3, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i2.KeyValuePipe, [i0.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.composition)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_RoomBedTypesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-room-bed-types", [], null, null, null, View_RoomBedTypesComponent_0, RenderType_RoomBedTypesComponent)), i0.ɵdid(1, 49152, null, 0, i3.RoomBedTypesComponent, [], null, null)], null, null); }
var RoomBedTypesComponentNgFactory = i0.ɵccf("app-room-bed-types", i3.RoomBedTypesComponent, View_RoomBedTypesComponent_Host_0, { composition: "composition" }, {}, []);
export { RoomBedTypesComponentNgFactory as RoomBedTypesComponentNgFactory };
