import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from '../models/page.model';
import { ApiUrlService } from './api-url.service';

@Injectable({ providedIn: 'root' })
export class PageService {
  pages: Page[] = [];

  constructor(
    private http: HttpClient,
    private apiUrl: ApiUrlService
  ) { }

  async loadPages() {
    this.pages = await this.http.get<Page[]>(this.apiUrl.siteBaseUrl() + 'pages')
      .toPromise();
  }

  async getPageFromSlug(slug: string): Promise<Page> {
    return this.http.get<Page>(this.apiUrl.siteBaseUrl() + 'pages/slug/' + slug)
      .toPromise();
  }
}
