import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { StripeElementLocale, loadStripe } from '@stripe/stripe-js';
import { ReservationsApiService } from 'src/app/reservations/reservations-api.service';
import { ReservationsBookService } from 'src/app/reservations/reservations-book/reservations-book.service';
import { AlertType } from 'src/app/shared/models/alert-type.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ApiUrlService } from 'src/app/shared/services/api-url.service';

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent implements OnInit {
  @Input()
  amount: number = 0;

  @Input()
  publicKey: string;

  loading = true;
  paying = false;

  private _stripe = null;
  private _stripeElements = null;
  private _stripePaymentIntentHash = '';
  stripeErrorMessage = '';

  constructor(
    private apiUrlService: ApiUrlService,
    public bookService: ReservationsBookService,
    private alert: AlertService,
    private reservationApi: ReservationsApiService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.reservationApi.getStripePaymentIntent(this.bookService.bookedReservation)
      .then(response => {
        if (!response.success) {
          throw new Error();
        }
        console.log('responseeeee', response);

        this._stripePaymentIntentHash = response.stripePaymentIntentHash;
        this.initStripe(response.stripePaymentIntentClientSecret);
      })
      .catch(err => {
        this.alert.set(AlertType.DANGER, this.translate.instant('book.ERROR'));
        console.error(err);
      })
      .finally(() => {
        this.loading = false;
      });

    
  }



  initStripe = (stripePaymentIntentClientSecret: string) => {
    console.log(this.publicKey)
    loadStripe(this.publicKey).then(stripe => {
      this._stripe = stripe;
      let stripeAppearance: {[theme: string]: "stripe" | "night" | "flat" | "none"} = { 
        theme: 'stripe'
      };
      let stripeOptions = { /* options */ };
      this._stripeElements = stripe.elements({ 
          locale: (this.translate.currentLang as StripeElementLocale),
          clientSecret: stripePaymentIntentClientSecret,
          appearance: stripeAppearance
      });
      const paymentElement = this._stripeElements.create('payment', stripeOptions);
      paymentElement.mount('#stripe-payment-element');

      paymentElement.on('ready', () => {
          // document.getElementById('ticket-order').getElementsByClassName('pay-button')[0].disabled = false;
      });
    });
  }

  async pay(event) {
    console.log('dodod');
    this.paying = true;
    event.preventDefault();

    let returnUrl = this.apiUrlService.siteBaseUrl(true) + 'payment/stripe-payment-intent-confirm/' + encodeURIComponent(this._stripePaymentIntentHash);
    console.log('returnUrl', returnUrl);

    const {error} = await this._stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements: this._stripeElements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    this.paying = false;
  
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      this.stripeErrorMessage = error.message;
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

}
