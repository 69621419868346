import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';
import { LocalStoreService } from './shared/services/local-store.service';
import { AuthService } from './shared/services/auth.service';
import { RouteService } from './shared/services/route.service';
import { SiteService } from './shared/services/site.service';
import { AlertService } from './shared/services/alert.service';
import { LanguageService } from './shared/services/language.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'resadirect-front';

  constructor(public routeService: RouteService,
    private translate: TranslateService,
    private store: LocalStoreService,
    private auth: AuthService,
    private siteService: SiteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private alert: AlertService,
    private lang: LanguageService) {
    this.auth.loadProfile();

    lang.initLanguages();
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
      // Set title
        let title = this.siteService.site.name;
        if (event.title) {
          this.translate.get(event.title)
            .subscribe(() => { title = this.translate.instant(event.title) + ' - ' + title; });
        }
        this.titleService.setTitle(title);

        // Remove alerts
        this.alert.clearTemp();
      });
  }
}
