/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./profile-navigation.component";
var styles_ProfileNavigation1Component = [];
var RenderType_ProfileNavigation1Component = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfileNavigation1Component, data: {} });
export { RenderType_ProfileNavigation1Component as RenderType_ProfileNavigation1Component };
export function View_ProfileNavigation1Component_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "card mb-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "div", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "a", [["class", "nocolor list-group-item list-group-item-action"], ["routerLink", "/user/profile"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, [[2, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(4, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 6, "a", [["class", "nocolor list-group-item list-group-item-action"], ["routerLink", "/user/password"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 671744, [[4, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(11, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 3, { links: 1 }), i0.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(14, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "/user/profile"; _ck(_v, 3, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 4, 0, currVal_3); var currVal_7 = "/user/password"; _ck(_v, 10, 0, currVal_7); var currVal_8 = "active"; _ck(_v, 11, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("user.YOUR_PROFILE")); _ck(_v, 7, 0, currVal_4); var currVal_5 = i0.ɵnov(_v, 10).target; var currVal_6 = i0.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_9 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform("user.PASSWORD")); _ck(_v, 14, 0, currVal_9); }); }
export function View_ProfileNavigation1Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-profile-navigation-1", [], null, null, null, View_ProfileNavigation1Component_0, RenderType_ProfileNavigation1Component)), i0.ɵdid(1, 49152, null, 0, i4.ProfileNavigation1Component, [], null, null)], null, null); }
var ProfileNavigation1ComponentNgFactory = i0.ɵccf("app-profile-navigation-1", i4.ProfileNavigation1Component, View_ProfileNavigation1Component_Host_0, {}, {}, []);
export { ProfileNavigation1ComponentNgFactory as ProfileNavigation1ComponentNgFactory };
