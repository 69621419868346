import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  HttpClient, HttpRequest, HttpEventType, HttpEvent
} from '@angular/common/http';
import { ApiUrlService } from 'src/app/shared/services/api-url.service';
import { AppSettings } from 'src/app/shared/services/app.settings';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AlertType } from 'src/app/shared/models/alert-type.model';
import { ImageSnippet } from 'src/app/shared/models/image-snippet.model';
import { map, last } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ProfileService {
  public profileForm: FormGroup;

  public passwordForm: FormGroup;

  public loading: boolean = false;

  public currentPictureUploading: ImageSnippet = null;

  public emailAlreadyUsedError: boolean = false;

  public hasPhoneError: boolean = false;

  public passwordError: boolean = false;

  public passwordFormSubmitted: boolean = false;

  public phoneNumber: string;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private apiUrl: ApiUrlService,
    private http: HttpClient,
    private alert: AlertService,
    private translate: TranslateService,
  ) {
  }

  onPhoneChanged(phoneError) {
    this.hasPhoneError = !phoneError;
  }

  getNumber(ev) {
    this.phoneNumber = ev;
  }

  initProfileForm() {
    this.profileForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]]
    });

    this.patchForm();
  }

  initPasswordForm() {
    this.passwordForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      newPassword2: ['', [Validators.required]],
    });
  }

  get pictureProfile(): string | ArrayBuffer {
    if (this.currentPictureUploading) return this.currentPictureUploading.src;
    if (!this.auth.user.photoPath) return 'assets/images/no-avatar.png';
    return this.apiUrl.siteBaseUrl(true) + 'guest/picture/' + this.auth.user.photoPath;
  }

  processFile(imageInput) {
    if (this.currentPictureUploading && this.currentPictureUploading.pending) return;

    this.alert.clear();

    const file: File = imageInput.files[0];
    const reader = new FileReader();

    if (!file) return;

    if (file.size > AppSettings.MAX_UPLOAD_FILE_SIZE) {
      this.alert.set(AlertType.DANGER, 'Votre fichier doit faire moins de ' + (AppSettings.MAX_UPLOAD_FILE_SIZE / (1024 * 1024)) + ' Mo.');
      return;
    }

    reader.onload = e => {
      this.currentPictureUploading = new ImageSnippet(reader.result);
      this.currentPictureUploading.pending = true;
      this.currentPictureUploading.progress = 0;

      this.uploadPictureProfile(file)
        .pipe(
          map(event => this.getEventMessage(event, file)),
          last()
        )
        .subscribe((data) => {
          this.currentPictureUploading.pending = false;

          return this.auth.loadProfile()
            .then(() => {
              this.patchForm();
              this.alert.set(AlertType.SUCCESS, 'Votre photo de profil a bien été enregistrée.');
              this.currentPictureUploading = null;
            });
        }, (err) => {
          this.currentPictureUploading = null;
          this.alert.set(AlertType.DANGER, 'Une erreur est survenue lors de la mise à jour de votre photo de profil.');
        });
    };

    reader.readAsDataURL(file);
  }


  private getEventMessage(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        const percentDone = Math.round(100 * event.loaded / event.total);
        this.currentPictureUploading.progress = percentDone;
        return event;
    }
  }

  uploadPictureProfile(file) {
    const url = this.apiUrl.siteBaseUrl() + 'guest/picture';
    const formData = new FormData();
    formData.append('image', file);

    const req = new HttpRequest('PUT', url, formData, {
      reportProgress: true
    });

    return this.http.request(req);
  }


  patchForm() {
    this.profileForm.patchValue(this.auth.user);
  }

  saveProfile() {
    if (!this.profileForm.valid) return;

    this.alert.clear();

    this.emailAlreadyUsedError = false;

    this.loading = true;

    return this.http.put(this.apiUrl.siteBaseUrl() + 'auth/guest/profile', {
      firstName: this.profileForm.controls.firstName.value,
      lastName: this.profileForm.controls.lastName.value,
      email: this.profileForm.controls.email.value,
      phone: this.phoneNumber || this.profileForm.controls.phone.value
    })
      .toPromise()
      .then(() => {
        return this.auth.loadProfile();
      })
      .then(() => {
        this.alert.set(AlertType.SUCCESS, this.translate.instant('user.PROFILE_SAVED'));
        this.phoneNumber = null;

        return this.patchForm();
      })
      .catch(err => {
        if (err.error && err.error.message) {
          if (err.error.message === 'The email address is already used.') {
            this.emailAlreadyUsedError = true;
            return;
          }
        }
        throw err;
      })
      .catch(err => {
        this.alert.set(AlertType.DANGER, this.translate.instant('user.ERROR_PROFILE'));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  changePassword() {
    this.passwordFormSubmitted = true;

    if ((!this.passwordForm.valid) || this.passwordForm.controls.newPassword.value != this.passwordForm.controls.newPassword2.value) return;

    this.loading = true;
    this.passwordError = false;

    return this.http.post(this.apiUrl.siteBaseUrl() + 'auth/guest/password', {
      oldPassword: this.passwordForm.controls.oldPassword.value,
      newPassword: this.passwordForm.controls.newPassword.value
    })
      .toPromise()
      .then(() => {
        this.alert.set(AlertType.SUCCESS, this.translate.instant('user.PASSWORD_CHANGED_ALERT'));
        this.passwordForm.reset();
        this.passwordFormSubmitted = false;
      })
      .catch(() => {
        this.passwordError = true;
      })
      .finally(() => {
        this.loading = false;
        this.passwordForm.markAsPristine();
      });
  }
}
