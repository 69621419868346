import { OnInit } from '@angular/core';
import { ReservationsService } from 'src/app/user/reservations/reservations.service';
import { ListingsApiService } from 'src/app/establishments/establishments-api.service';
import { SiteService } from 'src/app/shared/services/site.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
var Reservations1Component = /** @class */ (function () {
    function Reservations1Component(reservationsService, listingsApiService, site, route) {
        this.reservationsService = reservationsService;
        this.listingsApiService = listingsApiService;
        this.site = site;
        this.route = route;
        this.reservations = null;
        this.displayedReservations = null;
        this._showPastReservations = false;
    }
    Object.defineProperty(Reservations1Component.prototype, "showPastReservations", {
        get: function () {
            return this._showPastReservations;
        },
        set: function (showPastReservations) {
            var _this = this;
            this._showPastReservations = showPastReservations;
            // Filter reservations
            this.displayedReservations = this.reservations
                .filter(function (reservation) {
                var departureDate = moment(reservation.departureDate);
                if (moment().diff(departureDate, 'days') > 0) {
                    // The reservation is in the past
                    return _this.showPastReservations;
                }
                return !_this.showPastReservations;
            })
                .sort(function (a, b) {
                if (_this.showPastReservations) {
                    // DESC order
                    return moment(b.arrivalDate).unix() - moment(a.arrivalDate).unix();
                }
                // ASC order
                return moment(a.arrivalDate).unix() - moment(b.arrivalDate).unix();
            });
        },
        enumerable: true,
        configurable: true
    });
    Reservations1Component.prototype.ngOnInit = function () {
        // Reservations list is loaded in the reservations resolver
        this.reservations = this.route.snapshot.data.reservations;
        this.showPastReservations = false;
    };
    return Reservations1Component;
}());
export { Reservations1Component };
