import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from 'src/app/shared/services/api-url.service';

@Injectable()
export class ReservationsService {
  constructor(
    private http: HttpClient,
    private apiUrl: ApiUrlService
  ) {

  }

  loadReservations() {
    return this.http.get<any[]>(this.apiUrl.siteBaseUrl() + 'guest/reservations')
      .toPromise();
  }
}
