import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from '../shared/services/api-url.service';
import { LanguageService } from '../shared/services/language.service';
import { EquipmentService } from '../shared/services/equipment.service';
var ListingsApiService = /** @class */ (function () {
    function ListingsApiService(http, apiUrlService, language, equipmentService) {
        this.http = http;
        this.apiUrlService = apiUrlService;
        this.language = language;
        this.equipmentService = equipmentService;
    }
    ListingsApiService.prototype.getEstablishment = function (id) {
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + id + '?lang=' + this.language.currentLang);
    };
    ListingsApiService.prototype.getTypologies = function (establishmentId) {
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies_with_photos');
    };
    ListingsApiService.prototype.getPhotos = function (establishmentId, typologyId) {
        if (typologyId !== -1) {
            return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/typologies/' + typologyId + '/photos');
        }
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'listings/establishments/' + establishmentId + '/photos');
    };
    ListingsApiService.prototype.getLinkPhoto = function (path) {
        return this.apiUrlService.siteBaseUrl(true) + path;
    };
    /**
       * Separate equipments and rules from a list of equipment slugs
       * @param equipments List of equipments to separate
       */
    ListingsApiService.prototype.separateEquipmentsAndRules = function (itemEquipments) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var equipmentsRules;
            return tslib_1.__generator(this, function (_a) {
                equipmentsRules = {
                    equipments: [],
                    rules: []
                };
                this.equipmentService.initEquipmentsAndRules();
                return [2 /*return*/, this.equipmentService.loadEquipments
                        .toPromise()
                        .then(function (equipmentsAndRules) {
                        var establishmentEquipments = equipmentsAndRules.establishmentEquipments, typologyEquipments = equipmentsAndRules.typologyEquipments, establishmentRules = equipmentsAndRules.establishmentRules, typologyRules = equipmentsAndRules.typologyRules;
                        var allEquipments = establishmentEquipments.concat(typologyEquipments);
                        var allRules = establishmentRules.concat(typologyRules);
                        var _loop_1 = function (equipment) {
                            if (allEquipments.find(function (val) { return val.slug === equipment; })) {
                                equipmentsRules.equipments.push(equipment);
                            }
                            if (allRules.find(function (val) { return val.slug === equipment; })) {
                                equipmentsRules.rules.push(equipment);
                            }
                        };
                        for (var _i = 0, itemEquipments_1 = itemEquipments; _i < itemEquipments_1.length; _i++) {
                            var equipment = itemEquipments_1[_i];
                            _loop_1(equipment);
                        }
                        return equipmentsRules;
                    })];
            });
        });
    };
    return ListingsApiService;
}());
export { ListingsApiService };
