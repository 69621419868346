import { Injectable } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { SiteService } from './site.service';
@Injectable()
export class ApiUrlService {
  constructor(private siteService: SiteService) {
  }

  baseUrl() {
    return ConfigService.settings.apiBaseUrl;
  }

  siteBaseUrl(includeEnv: boolean = false) {
    if (this.siteService.site == null) {
      throw new Error('No site selected');
    }
    return this.baseUrl() + (includeEnv ? 'env/' + this.siteService.environmentCode + '/' : '') + 'sites/' + this.siteService.site.id + '/';
  }

  getSettingsImageLink(file: string) {
    return this.siteBaseUrl(true) + 'settings/resources/file/' + file;
  }
}
