<div class="row pb-3" *ngIf="details">
    <div class="col-12 col-sm-6 col-md-3" *ngFor="let equipm of displayedEquipments">
        <div class="our-rooms-icons">
            <div class="our-rooms-icon">
                <div class="equipments-item">
                    <app-equipment-icon [icon]="getClassForEquipment(equipm)"></app-equipment-icon>
                </div>
                <div class="equipments-title">
                    <div class="our-rooms-icon-txt1">{{ 'listings.equipments.' + (equipm |uppercase) | translate }}
                    </div>
                    <div class="our-rooms-icon-txt2">{{ 'listings.INCLUDED' | translate | uppercase }}</div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="!details" class="equipments-list-wrapper">
    <div class="equipments-list-arrow left" (click)="scrollTo('left')" [ngClass]="{'d-none': !displayLeftArrow}" title="Prev">
        <i class="fas fa-chevron-left"></i>
    </div>
    <div #equipmentsList class="equipments-list" [ngClass]="{'equipments-list-nowrap': nowrap}" (scroll)="onListScroll()">
        <div *ngFor="let equipm of displayedEquipments" class="equipments-list-item">
            <div class="equipments-item" [ngbTooltip]="'listings.equipments.' + (equipm | uppercase) | translate">
                <div>
                    <app-equipment-icon [icon]="getClassForEquipment(equipm)"></app-equipment-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="equipments-list-arrow right" (click)="scrollTo('right')" [ngClass]="{'d-none': !displayRightArrow}" title="Next">
        <i class="fas fa-chevron-right"></i>
    </div>
</div>