import { OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SiteService } from 'src/app/shared/services/site.service';
import { EstablishmentDetailsService } from '../../../../../establishments/establishment-details/establishment-details.service';
import { SearchService } from '../../../../services/search.service';
import { TypologyDetailsComponent } from '../typology-details/typology-details.component';
import { AuthService } from '../../../../services/auth.service';
var EstablishmentDetails1Component = /** @class */ (function () {
    function EstablishmentDetails1Component(establishmentDetailsService, searchService, modalService, router, auth, site) {
        this.establishmentDetailsService = establishmentDetailsService;
        this.searchService = searchService;
        this.modalService = modalService;
        this.router = router;
        this.auth = auth;
        this.site = site;
        this.typologiesWithPrice = null;
        this.quantityTypo = {};
    }
    EstablishmentDetails1Component.prototype.ngOnInit = function () {
        var _this = this;
        this.searchService.loadCriteria();
        this.establishmentDetailsService.initEstablishmentDetails();
        this.establishmentDetailsService.establishment.subscribe(function (establishment) {
            _this.establishment = establishment;
        });
        this.establishmentDetailsService.selectedEquipments.subscribe(function (equipments) {
            _this.selectedEquipments = equipments;
        });
        this.establishmentDetailsService.selectedRules.subscribe(function (rules) {
            _this.selectedRules = rules;
        });
        this.establishmentDetailsService.photos.subscribe(function (photos) {
            _this.photos = photos;
        });
        this.establishmentDetailsService.typologies.subscribe(function (typologies) {
            _this.typologies = typologies;
            var _loop_1 = function (typo) {
                var index_1 = _this.establishmentDetailsService.reservedTypologies.findIndex(function (item) { return item.id === typo.id; });
                if (index_1 !== -1) {
                    _this.quantityTypo[typo.id] = _this.establishmentDetailsService.reservedTypologies[index_1].quantity;
                }
                else {
                    _this.quantityTypo[typo.id] = 1;
                }
            };
            for (var _i = 0, _a = _this.typologies; _i < _a.length; _i++) {
                var typo = _a[_i];
                _loop_1(typo);
            }
        });
        this.searchService.typologiesWithPrices.subscribe(function (typos) {
            _this.typologiesWithPrice = typos;
            _this.establishmentDetailsService.typologiesWithPrice = typos;
        });
        this.searchService.loadCriteria();
        this.establishmentDetailsService.initEstablishmentDetails();
        if (this.photos.length > 1) {
            this.startPhotoTimer();
        }
    };
    EstablishmentDetails1Component.prototype.startPhotoTimer = function () {
        var _this = this;
        // Scroll photos
        this.timer = interval(5000);
        this.subscribeTimer = this.timer.subscribe(function (val) {
            var photo = _this.photos[0];
            _this.photos.splice(0, 1);
            setTimeout(function () {
                _this.photos.push(photo);
            }, 500);
        });
    };
    EstablishmentDetails1Component.prototype.clickOnImage = function (index) {
        var _this = this;
        if (this.photos.length <= 1)
            return;
        this.subscribeTimer.unsubscribe();
        var photos2 = this.photos.splice(0, index);
        setTimeout(function () {
            _this.photos = _this.photos.concat(photos2);
        }, 500);
        this.startPhotoTimer();
    };
    EstablishmentDetails1Component.prototype.getImageUrl = function (path) {
        return this.establishmentDetailsService.getImageUrl(path);
    };
    EstablishmentDetails1Component.prototype.scrollTo = function (idElem) {
        $('html, body').animate({ scrollTop: $(idElem).offset().top }, 500); // Go
    };
    EstablishmentDetails1Component.prototype.arrayOne = function (n) {
        return Array(n);
    };
    EstablishmentDetails1Component.prototype.saveReservations = function () {
        this.establishmentDetailsService.saveReservations();
    };
    EstablishmentDetails1Component.prototype.changeQuantity = function (idChange) {
        var index = this.establishmentDetailsService.reservedTypologies.findIndex(function (item) { return item.id === idChange; });
        if (index !== -1) {
            this.establishmentDetailsService.reservedTypologies[index].quantity = this.quantityTypo[idChange];
            this.saveReservations();
        }
    };
    EstablishmentDetails1Component.prototype.openVerticallyCentered = function (typo) {
        var modal = this.modalService.open(TypologyDetailsComponent, { centered: true, size: 'lg' });
        modal.componentInstance.typo = typo;
        modal.componentInstance.modal = modal;
    };
    return EstablishmentDetails1Component;
}());
export { EstablishmentDetails1Component };
