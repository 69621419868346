import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-room-composition',
  templateUrl: 'room-composition.component.html',
})
export class RoomCompositionComponent {
  @Input() composition?: any;

  constructor() {

  }

  get rooms() {
    return [this.composition.filter(room => !room.isCommonSpace), this.composition.filter(room => room.isCommonSpace)];
  }
}
