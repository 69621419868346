import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentService } from 'src/app/shared/services/equipment.service';
import { Rule } from '../../../../models/rule.model';

@Component({
  selector: 'app-rules',
  templateUrl: 'app-rules.component.html',
  styleUrls: ['../establishments.scss']
})
export class AppRulesComponent implements OnInit {
  rules: Rule[];

  @Input() selectedRules: string[];

  @Input() type: string;

  @Input() details: boolean;

  @Input() recap: boolean;

  constructor(
    private equipmentService: EquipmentService,
    private translate: TranslateService,
  ) {
  }

  async ngOnInit() {
    this.equipmentService.initEquipmentsAndRules();
    return this.equipmentService.loadEquipments
      .toPromise()
      .then(equipments => {
        if (this.type === 'establishment') {
          this.rules = equipments.establishmentRules;
        } else if (this.type === 'typology') {
          this.rules = equipments.typologyRules;
        }
      });
  }

  ruleEnabled(slug: string) {
    return this.selectedRules.includes(slug);
  }

  getClassForEquipment(rule: Rule) {
    const classes: string[] = [];

    classes.push(this.ruleEnabled(rule.slug) ? 'bg-success' : 'bg-danger');

    return classes.join(' ');
  }

  getRuleText(rule: Rule) {
    let translateKey = `listings.rules.${rule.slug.toLocaleUpperCase()}`;
    translateKey += this.ruleEnabled(rule.slug) ? '_ALLOWED' : '_NOT_ALLOWED';
    return this.translate.instant(translateKey);
  }
}
