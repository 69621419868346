import { Component } from '@angular/core';
import { SearchService } from 'src/app/shared/services/search.service';

@Component({
  selector: 'app-establishments-list-1',
  templateUrl: 'establishments-list.component.html',
  styleUrls: ['./establishments-list.component.scss']
})
export class EstablishmentsList1Component {
  results: any[];

  constructor(public searchService: SearchService) {
    searchService.searchResults.subscribe(results => {
      this.results = results;
    });
  }
}
