import { OnInit } from '@angular/core';
import { ReservationService } from 'src/app/user/reservation/reservation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ListingsApiService } from 'src/app/establishments/establishments-api.service';
import { SiteService } from 'src/app/shared/services/site.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CancellationModalComponent } from 'src/app/shared/components/cancellation-modal/cancellation-modal.component';
var UserReservation1Component = /** @class */ (function () {
    function UserReservation1Component(reservationService, listingsApiService, route, router, site, modalService) {
        this.reservationService = reservationService;
        this.listingsApiService = listingsApiService;
        this.route = route;
        this.router = router;
        this.site = site;
        this.modalService = modalService;
    }
    UserReservation1Component.prototype.ngOnInit = function () {
    };
    Object.defineProperty(UserReservation1Component.prototype, "reservation", {
        get: function () {
            return this.reservationService.reservation;
        },
        enumerable: true,
        configurable: true
    });
    UserReservation1Component.prototype.openCancellationModal = function () {
        var modalRef = this.modalService.open(CancellationModalComponent, { size: 'lg' });
        modalRef.componentInstance.reservationCode = this.reservationService.reservation.code;
    };
    return UserReservation1Component;
}());
export { UserReservation1Component };
