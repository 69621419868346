import * as i0 from "@angular/core";
import * as i1 from "@ng-toolkit/universal";
var SessionStoreService = /** @class */ (function () {
    function SessionStoreService(window) {
        this.window = window;
        this.ls = window.sessionStorage;
    }
    SessionStoreService.prototype.setItem = function (key, value) {
        value = JSON.stringify(value);
        this.ls.setItem(key, value);
        return true;
    };
    SessionStoreService.prototype.getItem = function (key) {
        var value = this.ls.getItem(key);
        try {
            return JSON.parse(value);
        }
        catch (e) {
            // console.log(e)
            return null;
        }
    };
    SessionStoreService.prototype.clear = function () {
        this.ls.clear();
    };
    SessionStoreService.ngInjectableDef = i0.defineInjectable({ factory: function SessionStoreService_Factory() { return new SessionStoreService(i0.inject(i1.WINDOW)); }, token: SessionStoreService, providedIn: "root" });
    return SessionStoreService;
}());
export { SessionStoreService };
