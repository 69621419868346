import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LocalStoreService } from './local-store.service';
import { ApiUrlService } from './api-url.service';
import * as i0 from "@angular/core";
import * as i1 from "./local-store.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common/http";
import * as i4 from "./api-url.service";
var AuthService = /** @class */ (function () {
    function AuthService(store, router, http, apiUrlService) {
        this.store = store;
        this.router = router;
        this.http = http;
        this.apiUrlService = apiUrlService;
        this.authenticated = false;
        this.token = null;
        this.user = null;
        this.redirectAfterLogin = null;
        this.loadFromStorage();
        this.authenticated = this.checkAuth();
    }
    AuthService.prototype.loadFromStorage = function () {
        this.token = this.store.getItem('token');
        this.user = this.store.getItem('user');
    };
    Object.defineProperty(AuthService.prototype, "pictureProfileUrl", {
        get: function () {
            return this.apiUrlService.siteBaseUrl(true) + 'guest/picture/' + this.user.photoPath;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.saveProfile = function () {
        this.store.setItem('user', this.user);
    };
    AuthService.prototype.loadProfile = function () {
        var _this = this;
        if (!this.authenticated)
            return;
        return this.http.get(this.apiUrlService.siteBaseUrl() + 'auth/guest/profile').toPromise()
            .then(function (data) {
            _this.user.id = data.id;
            _this.user.firstName = data.firstName;
            _this.user.lastName = data.lastName;
            _this.user.phone = data.phone;
            _this.user.email = data.email;
            _this.user.photoPath = data.photoPath;
            _this.user.site = data.site;
            _this.saveProfile();
            return true;
        });
    };
    AuthService.prototype.checkAuth = function () {
        return this.token !== null && this.user !== null;
    };
    AuthService.prototype.signin = function (credentials) {
        var _this = this;
        return this.http.post(this.apiUrlService.siteBaseUrl() + 'auth/guest', credentials).toPromise()
            .then(function (data) {
            _this.setCredentials(data);
            _this.user = data.user;
            _this.authenticated = true;
            return _this.loadProfile();
        });
    };
    AuthService.prototype.setCredentials = function (credentials) {
        this.token = credentials.token;
        this.user = credentials.user;
        this.store.setItem('token', this.token);
        this.store.setItem('user', this.user);
    };
    AuthService.prototype.refreshToken = function () {
        var _this = this;
        return this.http.post(this.apiUrlService.siteBaseUrl() + 'auth/guest/refresh', {})
            .pipe(map(function (data) {
            _this.setCredentials(data);
            return data;
        }));
    };
    AuthService.prototype.signout = function () {
        this.authenticated = false;
        this.store.setItem('token', null);
        this.store.setItem('user', null);
        this.loadFromStorage();
        this.router.navigateByUrl('/');
    };
    AuthService.prototype.setUserLang = function (lang) {
        var _this = this;
        if (!this.authenticated)
            return;
        if (this.user.lang === lang)
            return;
        return this.http.post(this.apiUrlService.siteBaseUrl() + 'guest/lang', { lang: lang })
            .toPromise()
            .then(function () { return _this.user.lang = lang; });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.LocalStoreService), i0.inject(i2.Router), i0.inject(i3.HttpClient), i0.inject(i4.ApiUrlService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
