import { AuthService } from 'src/app/shared/services/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { ApiUrlService } from 'src/app/shared/services/api-url.service';
import { AppSettings } from 'src/app/shared/services/app.settings';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AlertType } from 'src/app/shared/models/alert-type.model';
import { ImageSnippet } from 'src/app/shared/models/image-snippet.model';
import { map, last } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
var ProfileService = /** @class */ (function () {
    function ProfileService(auth, fb, apiUrl, http, alert, translate) {
        this.auth = auth;
        this.fb = fb;
        this.apiUrl = apiUrl;
        this.http = http;
        this.alert = alert;
        this.translate = translate;
        this.loading = false;
        this.currentPictureUploading = null;
        this.emailAlreadyUsedError = false;
        this.hasPhoneError = false;
        this.passwordError = false;
        this.passwordFormSubmitted = false;
    }
    ProfileService.prototype.onPhoneChanged = function (phoneError) {
        this.hasPhoneError = !phoneError;
    };
    ProfileService.prototype.getNumber = function (ev) {
        this.phoneNumber = ev;
    };
    ProfileService.prototype.initProfileForm = function () {
        this.profileForm = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required]]
        });
        this.patchForm();
    };
    ProfileService.prototype.initPasswordForm = function () {
        this.passwordForm = this.fb.group({
            oldPassword: ['', [Validators.required]],
            newPassword: ['', [Validators.required]],
            newPassword2: ['', [Validators.required]],
        });
    };
    Object.defineProperty(ProfileService.prototype, "pictureProfile", {
        get: function () {
            if (this.currentPictureUploading)
                return this.currentPictureUploading.src;
            if (!this.auth.user.photoPath)
                return 'assets/images/no-avatar.png';
            return this.apiUrl.siteBaseUrl(true) + 'guest/picture/' + this.auth.user.photoPath;
        },
        enumerable: true,
        configurable: true
    });
    ProfileService.prototype.processFile = function (imageInput) {
        var _this = this;
        if (this.currentPictureUploading && this.currentPictureUploading.pending)
            return;
        this.alert.clear();
        var file = imageInput.files[0];
        var reader = new FileReader();
        if (!file)
            return;
        if (file.size > AppSettings.MAX_UPLOAD_FILE_SIZE) {
            this.alert.set(AlertType.DANGER, 'Votre fichier doit faire moins de ' + (AppSettings.MAX_UPLOAD_FILE_SIZE / (1024 * 1024)) + ' Mo.');
            return;
        }
        reader.onload = function (e) {
            _this.currentPictureUploading = new ImageSnippet(reader.result);
            _this.currentPictureUploading.pending = true;
            _this.currentPictureUploading.progress = 0;
            _this.uploadPictureProfile(file)
                .pipe(map(function (event) { return _this.getEventMessage(event, file); }), last())
                .subscribe(function (data) {
                _this.currentPictureUploading.pending = false;
                return _this.auth.loadProfile()
                    .then(function () {
                    _this.patchForm();
                    _this.alert.set(AlertType.SUCCESS, 'Votre photo de profil a bien été enregistrée.');
                    _this.currentPictureUploading = null;
                });
            }, function (err) {
                _this.currentPictureUploading = null;
                _this.alert.set(AlertType.DANGER, 'Une erreur est survenue lors de la mise à jour de votre photo de profil.');
            });
        };
        reader.readAsDataURL(file);
    };
    ProfileService.prototype.getEventMessage = function (event, file) {
        switch (event.type) {
            case HttpEventType.UploadProgress:
                var percentDone = Math.round(100 * event.loaded / event.total);
                this.currentPictureUploading.progress = percentDone;
                return event;
        }
    };
    ProfileService.prototype.uploadPictureProfile = function (file) {
        var url = this.apiUrl.siteBaseUrl() + 'guest/picture';
        var formData = new FormData();
        formData.append('image', file);
        var req = new HttpRequest('PUT', url, formData, {
            reportProgress: true
        });
        return this.http.request(req);
    };
    ProfileService.prototype.patchForm = function () {
        this.profileForm.patchValue(this.auth.user);
    };
    ProfileService.prototype.saveProfile = function () {
        var _this = this;
        if (!this.profileForm.valid)
            return;
        this.alert.clear();
        this.emailAlreadyUsedError = false;
        this.loading = true;
        return this.http.put(this.apiUrl.siteBaseUrl() + 'auth/guest/profile', {
            firstName: this.profileForm.controls.firstName.value,
            lastName: this.profileForm.controls.lastName.value,
            email: this.profileForm.controls.email.value,
            phone: this.phoneNumber || this.profileForm.controls.phone.value
        })
            .toPromise()
            .then(function () {
            return _this.auth.loadProfile();
        })
            .then(function () {
            _this.alert.set(AlertType.SUCCESS, _this.translate.instant('user.PROFILE_SAVED'));
            _this.phoneNumber = null;
            return _this.patchForm();
        })
            .catch(function (err) {
            if (err.error && err.error.message) {
                if (err.error.message === 'The email address is already used.') {
                    _this.emailAlreadyUsedError = true;
                    return;
                }
            }
            throw err;
        })
            .catch(function (err) {
            _this.alert.set(AlertType.DANGER, _this.translate.instant('user.ERROR_PROFILE'));
        })
            .finally(function () {
            _this.loading = false;
        });
    };
    ProfileService.prototype.changePassword = function () {
        var _this = this;
        this.passwordFormSubmitted = true;
        if ((!this.passwordForm.valid) || this.passwordForm.controls.newPassword.value != this.passwordForm.controls.newPassword2.value)
            return;
        this.loading = true;
        this.passwordError = false;
        return this.http.post(this.apiUrl.siteBaseUrl() + 'auth/guest/password', {
            oldPassword: this.passwordForm.controls.oldPassword.value,
            newPassword: this.passwordForm.controls.newPassword.value
        })
            .toPromise()
            .then(function () {
            _this.alert.set(AlertType.SUCCESS, _this.translate.instant('user.PASSWORD_CHANGED_ALERT'));
            _this.passwordForm.reset();
            _this.passwordFormSubmitted = false;
        })
            .catch(function () {
            _this.passwordError = true;
        })
            .finally(function () {
            _this.loading = false;
            _this.passwordForm.markAsPristine();
        });
    };
    return ProfileService;
}());
export { ProfileService };
