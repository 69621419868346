<div class="payment-card">
    <form [formGroup]="paymentForm" (ngSubmit)="submitPaymentForm()">
        <div class="row">
            <div class="col-12 mb-3">
                <label for="cc-name" style="margin-bottom: 0;">{{ 'book.card.AMOUNT' | translate }}</label>
                <div class="amount">{{ amount | customCurrency }}</div>
            </div>
            <div class="col-12 mb-3">
                <label for="cc-name">{{ 'book.card.NAME_ON_CARD' | translate }}</label>
                <input type="text"
                [class.is-invalid]="!(paymentForm.controls.name.valid || (paymentForm.controls.name.pristine && !submitted))"
                formControlName="name"
                [placeholder]="'book.card.NAME_ON_CARD' | translate"
                class="form-control"
                id="cc-name"
                autocomplete="cc-name"
                required>
                <small class="text-danger" [hidden]="paymentForm.controls.name.valid || (paymentForm.controls.name.pristine && !submitted)">
                    {{ 'book.card.ENTER_NAME_ON_CARD' | translate }}
                </small>
            </div>
            <div class="col-12 mb-3">
                <label for="cc-number">{{ 'book.card.CARD_NUMBER' | translate }}</label>
                <input type="tel"
                [class.is-invalid]="!(paymentForm.controls.number.valid || (paymentForm.controls.number.pristine && !submitted))"
                formControlName="number"
                class="form-control"
                id="cc-number"
                autocomplete="cc-number"
                [placeholder]="'book.card.CARD_NUMBER' | translate"
                ccNumber
                required>
                <small class="text-danger" [hidden]="paymentForm.controls.number.valid || (paymentForm.controls.number.pristine && !submitted)">
                    {{ 'book.card.ENTER_CARD_NUMBER' | translate }}
                </small>
            </div>
        </div>
        
        <div class="row">
            <div class="col-6 mb-3">
                <label for="cc-expiration">{{ 'book.card.EXPIRATION_DATE' | translate }}</label>
                <input type="tel"
                [class.is-invalid]="!(paymentForm.controls.expiration_date.valid || (paymentForm.controls.expiration_date.pristine && !submitted))"
                formControlName="expiration_date"
                class="form-control"
                id="cc-expiration"
                placeholder="MM / YY"
                autocomplete="cc-exp"
                (keyup)="formatExpirationDate($event)"
                ccExp
                required>
                <small class="text-danger" [hidden]="paymentForm.controls.expiration_date.valid || (paymentForm.controls.expiration_date.pristine && !submitted)">
                    {{ 'book.card.ENTER_EXPIRATION_DATE' | translate }}
                </small>
            </div>
            <div class="col-6 mb-3">
                <label for="cc-expiration">{{ 'book.card.SECURITY_CODE' | translate }}</label>
                <input type="tel"
                [class.is-invalid]="!(paymentForm.controls.cvc.valid || (paymentForm.controls.cvc.pristine && !submitted))"
                formControlName="cvc"
                class="form-control"
                id="cc-cvv"
                placeholder="Code"
                autocomplete="cc-csc"
                ccCVC
                required>
                <small class="text-danger" [hidden]="paymentForm.controls.cvc.valid || (paymentForm.controls.cvc.pristine && !submitted)">
                    {{ 'book.card.ENTER_SECURITY_CODE' | translate }}
                </small>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <button type="submit" class="btn btn-primary btn-block" [disabled]="loading"><span class="mr-2"><i [ngClass]="{'fas fa-lock': !loading, 'far fa-spin fa-circle-notch': loading}"></i></span> {{ 'book.CONFIRM_BOOKING' | translate }} </button>
            </div>
        </div>
    </form>
</div>