import { Injectable } from '@angular/core';
import { AuthApiService } from '../auth-api.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private authApiService: AuthApiService) {}

  register(newUser) {
    return this.authApiService.register(newUser);
  }
}
