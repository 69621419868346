import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationService } from 'src/app/user/reservation/reservation.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../services/alert.service';
import { AlertType } from '../../models/alert-type.model';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-cancellation-modal',
  templateUrl: './cancellation-modal.component.html'
})
export class CancellationModalComponent implements OnInit {
  public reservationCode: string;

  public policies: any = {};

  public loading: boolean = false;

  public cancellationLoading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public reservationService: ReservationService,
    private alert: AlertService,
    private translate: TranslateService,
    private language: LanguageService,
  ) {
  }

  async ngOnInit() {
    this.loading = true;
    this.reservationService.getReservationPolicy(this.reservationCode)
      .then(policies => {
        this.policies = policies;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async cancelReservation() {
    this.cancellationLoading = true;
    this.reservationService.cancelReservation(this.reservationCode)
      .then(() => {
        const establishmentName = this.language.getTranslationFromMap(this.reservationService.reservation.establishment.name);
        this.alert.set(AlertType.SUCCESS, this.translate.instant('book.RESERVATION_CANCELLED_GUEST', {
          establishmentName
        }));
        this.activeModal.close();
      })
      .catch(() => this.alert.set(AlertType.DANGER, this.translate.instant('book.ERROR_CANCELLATION')))
      .finally(() => {
        this.cancellationLoading = false;
      });
  }
}
