<div id="content" *ngIf="reservation">
    <div class="container">
        <div class="title1">
            {{ (reservation.cancelled ? 'book.RESERVATION_CANCELLED' : 'book.RESERVATION_CONFIRMED') | translate }} -
            {{ reservation.establishment.name | translateMap }}</div>
        <div class="title2">
            {{ (reservation.cancelled ? 'book.RESERVATION_CANCELLED_GUEST' : 'book.RESERVATION_CONFIRMED_GUEST') | translate: {establishmentName: (reservation.establishment.name | translateMap)} }}
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-6 text-center">
                <div style="background-color: rgb(222, 235, 243); height: 300px;" id="google_map" *ngIf="reservation">
                    <agm-map [latitude]="reservation.establishment.latitude"
                        [longitude]="reservation.establishment.longitude" [zoom]="12"
                        style="height: 100%; width: 100%;">
                        <agm-marker [latitude]="reservation.establishment.latitude"
                            [longitude]="reservation.establishment.longitude" [title]="reservation.establishment.name | translateMap">
                        </agm-marker>
                    </agm-map>
                </div>
            </div>
            <div class="col-md-6">
                <app-alert></app-alert>
                <h2><span *ngIf="reservation.cancelled" class="badge badge-danger mr-4"
                        style="font-size: 1.5rem;">{{ 'book.CANCELLED' | translate }}</span>{{ reservation.establishment.name | translateMap }}
                </h2>
                <div class="mt-3 mb-3">
                    <i class="fas fa-map-marker-alt"></i> {{ reservation.establishment.address }}
                </div>
                <div style="font-size: 1.2em;">
                    <strong>{{ 'reservations.RESERVATION_CODE' | translate }} : </strong> {{ reservation.code }}
                </div>
                <div class="row mt-4" app-reservation-arrival-departure [reservation]="reservation">
                </div>
                <div style="font-size: 1.2em;" class="mt-2">
                    <strong>{{ 'listings.fees.TOTAL_PRICE' | translate }}</strong>&nbsp;&nbsp;{{ reservation.nbNights }}
                    {{ ((reservation.nbNights > 1) ? 'reservations.NIGHTS' : 'reservations.NIGHT') | translate }},
                    {{ reservation.subreservations.length }}
                    {{ ((reservation.subreservations.length > 1) ? 'reservations.ACCOMODATIONS' : 'reservations.ACCOMODATION') | translate }}<br>
                    <span style="font-size: 1.5em;">{{ reservation.totalPrice | customCurrency }}</span>
                </div>
                <div class="mt-2">
                    <a class="nocolor btn btn-primary mr-2"
                        [routerLink]="'/user/reservations/' + reservation.code + '/messages'">{{ 'messages.CONTACT_ESTABLISHMENT' | translate }}</a>
                    <button class="btn btn-primary" (click)="openCancellationModal()"
                        *ngIf="!reservation.cancelled && reservationService.canCancel()">{{ 'reservations.CANCEL_RESERVATION' | translate }}...</button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>{{ 'listings.EQUIPMENTS_AND_RULES' | translate }}</h2>
                <div class="pt-3 text-uppercase pb-2">
                    {{ 'listings.EQUIPMENTS' | translate }}
                </div>
                <app-equipments [selectedEquipments]="reservation.establishment.equipments" type="establishment"
                    details="true"></app-equipments>
                <div class="pt-3 text-uppercase pb-2">
                    {{ 'listings.RULES' | translate }}
                </div>
                <app-rules [selectedRules]="reservation.establishment.equipments" type="establishment" details="true">
                </app-rules>
            </div>

        </div>
        <div class="row">
            <div class="col-12">
                <h2>{{ 'reservations.RESERVATION_DETAIL' | translate }}<span *ngIf="reservation.cancelled"
                        class="badge badge-danger ml-4"
                        style="font-size: 1.5rem;">{{ 'book.CANCELLED' | translate }}</span></h2>
            </div>
            <div class="col-12 subreservation" *ngFor="let subreservation of reservation.subreservations">
                <div class="row">
                    <div class="col-md-3">
                        <img [src]="listingsApiService.getLinkPhoto(subreservation.typology.photo) + '?thumbnail=true'"
                            class="img-fluid">
                        <div class="mt-4">
                            <app-equipments [selectedEquipments]="subreservation.typology.equipments" type="typology"
                                [details]="false"></app-equipments>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <h3>{{ subreservation.typology.name | translateMap }}</h3>
                        <div *ngIf="reservation.globalTypologyTime === null">
                            <strong>{{ 'reservations.ARRIVAL_DATE' | translate }}</strong> :
                            {{ reservationService.getTimePeriodPhrase(subreservation.typology.minArrivalTime, subreservation.typology.maxArrivalTime) | lowercase }}
                            &ndash;
                            <strong>{{ 'reservations.DEPARTURE_DATE' | translate }}</strong> :
                            {{ reservationService.getTimePeriodPhrase(subreservation.typology.minDepartureTime, subreservation.typology.maxDepartureTime) | lowercase }}
                        </div>
                        <div
                            *ngIf="subreservation.customFields.nb_adults != null && subreservation.customFields.nb_children != null && subreservation.customFields.nb_infants != null">
                            <span>{{ subreservation.customFields.nb_adults }}
                                {{ (subreservation.customFields.nb_adults > 1 ? 'reservations.ADULTS' : 'reservations.ADULT') | translate }}</span><span
                                *ngIf="subreservation.customFields.nb_children > 0">,
                                {{ subreservation.customFields.nb_children }}
                                {{ (subreservation.customFields.nb_children > 1 ? 'reservations.CHILDREN' : 'reservations.CHILD') | translate }}</span><span
                                *ngIf="subreservation.customFields.nb_infants > 0">,
                                {{ subreservation.customFields.nb_infants }}
                                {{ (subreservation.customFields.nb_infants > 1 ? 'reservations.INFANTS' : 'reservations.INFANT') | translate }}</span>
                        </div>
                        <div
                            *ngIf="subreservation.customFields.nb_guests != null && (subreservation.customFields.nb_adults == null && subreservation.customFields.nb_children == null && subreservation.customFields.nb_infants == null)">
                            {{ subreservation.customFields.nb_guests }}
                            personne{{ subreservation.customFields.nb_guests > 1 ? 's' : '' }}
                        </div>
                        <div class="mt-2">
                            <app-room-composition
                                [composition]="reservationService.getRoomFromTypology(subreservation.typology.id)">
                            </app-room-composition>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>{{ 'book.price.PRICE_DETAILS' | translate }}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12 subreservation">
                <div *ngFor="let line of reservation.priceDetails" class="row justify-content-center"
                    [class.total-price]="line.name === 'total_price'">
                    <div class="col-8 col-sm-6 col-md-4">
                        {{ 'book.price.' + (line.name | uppercase) | translate }}
                    </div>
                    <div class="col-4 text-right">
                        <span
                            *ngIf="line.name === 'base_price'">{{ line.value / reservation.nbNights | customCurrency }}
                            x {{ reservation.nbNights }}
                            {{ ((reservation.nbNights > 1) ? 'reservations.NIGHTS' : 'reservations.NIGHT') | translate }}
                            = </span>
                        {{ line.value | customCurrency }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<template1-footer></template1-footer>